<template>
    <div>
        <audio v-if="source" hidden :src="source" ref="audioRef" />
        <el-button type="primary" :loading="true" circle v-if="loading"></el-button>
        <template v-else>
            <el-button type="primary" icon="VideoPlay" circle v-if="!isPlaying" @click="handlePlay"></el-button>
            <el-button type="primary" icon="VideoPause" circle v-else @click="handlePause"></el-button>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        source: String,
    },

    data() {
        return {
            isPlaying: false,
            loading: true,
        }
    },

    mounted() {
        const audio = this.$refs.audioRef;
        audio.addEventListener('end', this.handleAudioEnd);
        audio.addEventListener('play', this.handleAudioPlay);
        audio.addEventListener('pause', this.handleAudioPause);
        audio.addEventListener('canplay', this.handleAudioCanplay);
        audio.addEventListener('loadstart', this.handleAudioLoadStart);
    },

    beforeUnmount() {
        const audio = this.$refs.audioRef;
        audio.pause();
        audio.removeEventListener('end', this.handleAudioEnd);
        audio.removeEventListener('play', this.handleAudioPlay);
        audio.removeEventListener('pause', this.handleAudioPause);
        audio.removeEventListener('canplay', this.handleAudioCanplay);
        audio.removeEventListener('loadstart', this.handleAudioLoadStart);
    },

    methods: {
        handlePlay() {
            this.$refs.audioRef.play();
        },

        handlePause() {
            this.$refs.audioRef.pause();
        },

        handleAudioEnd() {
            this.isPlaying = false;
        },

        handleAudioPlay() {
            this.isPlaying = true;
        },

        handleAudioPause() {
            this.isPlaying = false;
        },

        handleAudioCanplay() {
            this.loading = false;
        },

        handleAudioLoadStart() {
            this.loading = true;
        }
    }
};

</script>