import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/base.css'
import './assets/css/main.styl'
import BaseComponents from './components/base'
import BaseDirective from './directive'
import Vue3ColorPicker from 'vue3-colorpicker'
import 'vue3-colorpicker/style.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { Icon, Dialog, Button, Slider } from 'vant'

import './views/tools/whiteboard/font/iconfont.css'
// 引入全局视频播放器video.js
// import Video from 'video.js';
// import 'video.js/dist/video-js.css';

// 引入vue3-player播放器
import vue3videoPlay from "vue3-video-play"; // 引入组件
import "vue3-video-play/dist/style.css"; // 引入css
// 引入ui
import abcUI from '@youyi/abc-ui';
import '@youyi/abc-ui/lib/style.css'
// 埋点
import vueMatomo from 'vue-matomo';

import Forbidden from './forbidden';
// 禁止移动端访问
var p = navigator.platform || (navigator.userAgentData ? navigator.userAgentData.platform : '');
if (p.indexOf('Win') !== 0 && p.indexOf('Mac') !== 0 && p.indexOf('Linux') !== 0 && p.indexOf('X11') !== 0) {
  createApp(Forbidden).mount('#app');
  throw '不支持移动端访问';
}

const {install} = abcUI;
const app = createApp(App)
install(app);
// app.config.globalProperties.$video = Video;
app.use(vue3videoPlay);

// ?video 插件
// import 'vue-video-player/src/custom-theme.css';
// import 'video.js/dist/video-js.css';
// import videoPlayer from 'vue-video-player/src';
// app.use(videoPlayer)
// 引入element-plus-icons 图标库
import * as Icons from "@element-plus/icons-vue";

import mitt from 'mitt';

Object.entries(Icons).forEach(([key, component]) => {
    // 注册组件
    app.component(key, component);
});
app.use(Icon)
app.use(Dialog)
app.use(Button)
app.use(Slider)

app.use(BaseComponents)
app.use(BaseDirective)

app.use(vueMatomo, {
    host: location.host.startWith('tea.biz') ? '//matomo.tob.yinweiart.com/' : '//test-matomo.tob.yinweiart.com',
    siteId: 1,
    router: router,
});
app.config.globalProperties.$bus = mitt();

app.use(store).use(router).use(ElementPlus).use(Vue3ColorPicker).mount('#app')
