/*
 * @Author: LiuXueChao
 * @Date: 2021-12-12 14:20:18
 * @LastEditors: your name
 * @LastEditTime: 2021-12-17 14:16:23
 * @Description: file content
 */
import request from '@/api/request'

// 教材列表
export const booklist = (params) => {
  return request({
    url: '/api-base/cur/knowledge/textbook',
    method: 'get',
    params,
  })
}

export const bookVersionList = () => {
  return request({
    url: '/api-base/cur/textbook/version/sch',
    method: 'get'
  });
}

export const showunitbook = (id) => {
  return request({
    url: '/api-base/cur/knowledge/unit',
    method: 'get',
    params: {
      parentId: id,
    },
  })
}

// 讲评接口 考试报告
export const textreport = (params) => {
  return request({
    url: '/api-sch/exam/reports',
    method: 'get',
    params,
  })
}

//自定义课件接口
// export const diysubject = () => {
//   return request({
//     url: '/api-sch/teachings/programme/list',
//     method: 'get',
//   })
// }
export const diysubject = (params) => {
  return request({
    url: '/api-sch/resources/private/list/office',
    method: 'GET',
    params,
  })
};
// 课件详情
export const reqFileDetailList = (resId, permission) => {
  return request({
      method: 'GET',
      url: `/api-sch/resources/${permission}/${resId}`,
  })
};