<script>
import { onMounted, reactive, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { fabric } from "../../lib/fabric";

import svg from "./whiteboard/svg/svg.js";
import common from "./whiteboard/common.js";

let canvasId = 0;
export default {
  name: "whiteboard",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    model: {
      type: String,
      default: '',
    }
  },
  emits: ['close'],
  setup(props, { emit }) {
    canvasId++;

    const route = useRoute();
    const state = reactive({
      isHome: true,
      isDetail: false,
      show: false,
      isOns: false,
      homepiano: false,
      metronome: false,
      keysound: false,
      options: {
        backgroundColor: props.model === 'marking' ? 'rgba(0,0,0,0)' : "#f4f4f4",
        penColor: "#000000",
        dotSize: 2,
        width: 0,
        height: 0,
      },
      cur: 0,
      // 记录当前的鼠标的位置
      mouse: {
        start: [],
        move: [],
        end: [],
      },
      isOn: false,
      noteHeight: 0,
      isOver: false,
      // 当前是什么模式：stave是五线谱模式
      collapse: "stave",
      backgroundBox: false,
      colorBox: false,
      sizeBox: false,
      canvasId: 'canvas_' + canvasId 
    });

    const changePath = (path) => {
      state.isHome = path == "/Home";
      state.isDetail = path == "/detail";
    };
    changePath(route.path);
    watch(route, () => {
      changePath(route.path);
    });

    watch(
      () => props.show,
      () => {
        state.show = !state.show;
      }
    );

    watch(
      () => props.model,
      (val) => {
        if (val === 'marking') {
          state.options.backgroundColor = 'rgba(0, 0, 0, 0)';
        } else {
          state.options.backgroundColor = '#f4f4f4';
        }
        colorChange(state.options.backgroundColor);
      }
    );

    let canvas = null,
      moveCount = 0,
      graph = null,
      textbox = null,
      eraserArr = [],
      lastGraph = [],
      activeGraph = null,
      staveArr = [];

    // 绘制箭头方法
    const drawArrow = () => {
      let x1 = state.mouse.start[0],
        x2 = state.mouse.move[0],
        y1 = state.mouse.start[1],
        y2 = state.mouse.move[1];

      let w = x2 - x1,
        h = y2 - y1,
        sh = Math.cos(Math.PI / 4) * 16;
      let sin = h / Math.sqrt(Math.pow(w, 2) + Math.pow(h, 2));
      let cos = w / Math.sqrt(Math.pow(w, 2) + Math.pow(h, 2));
      let w1 = (16 * sin) / 4,
        h1 = (16 * cos) / 4,
        centerx = sh * cos,
        centery = sh * sin;

      let path = " M " + x1 + " " + y1;
      path += " L " + (x2 - centerx + w1) + " " + (y2 - centery - h1);
      path += " L " + (x2 - centerx + w1 * 2) + " " + (y2 - centery - h1 * 2);
      path += " L " + x2 + " " + y2;
      path += " L " + (x2 - centerx - w1 * 2) + " " + (y2 - centery + h1 * 2);
      path += " L " + (x2 - centerx - w1) + " " + (y2 - centery + h1);
      path += " Z";
      return path;
    };

    const draw = (options) => {
      if (graph) {
        canvas.remove(graph);
      }
      let curTool = common[state.cur],
        selectable = curTool.selectable,
        newGraph = null,
        path = null,
        // 获取到当前的mouse鼠标的左侧和上方的初始化位置
        left = state.mouse.start[0],
        top = state.mouse.start[1],
        // x和y表示鼠标移动的距离
        x = state.mouse.move[0],
        y = state.mouse.move[1],
        width = state.mouse.move[0] - state.mouse.start[0],
        height = state.mouse.move[1] - state.mouse.start[1];

      switch (curTool.name) {
        case "pen": //画笔
          canvas.isDrawingMode = true;
          canvas.freeDrawingBrush.color = state.options.penColor;
          canvas.freeDrawingBrush.width = state.options.dotSize;
          break;
        case "line": //直线
        // TODO:直线作画的时候，这里的直线Line函数
          newGraph = new fabric.Line(
            [...state.mouse.start, ...state.mouse.move],
            {
              stroke: state.options.penColor,
              strokeWidth: state.options.dotSize,
              selectable,
            }
          );
          break;
        case "arrow": //箭头
          newGraph = new fabric.Path(drawArrow(), {
            stroke: state.options.penColor,
            fill: state.options.penColor,
            strokeWidth: state.options.dotSize,
            selectable,
          });
          break;
        case "square": //矩形
          var pathRect = `M${left} ${top} l 0 ${y - top} l ${x - left} 0 l 0 ${
            top - y
          } l ${left - x} 0`;
          if (options.e?.shiftKey) {
            pathRect = `M${left} ${top} l 0 ${x - left} l ${x - left} 0 l 0 ${
              left - x
            } l ${left - x} 0`;
          }
          newGraph = new fabric.Path(pathRect, {
            stroke: state.options.penColor,
            strokeWidth: state.options.dotSize,
            fill: "rgba(255, 255, 255, 0)",
            hasControls: false,
            selectable,
          });
          break;
        case "circle": //圆
          if (options.e?.shiftKey) {
            x - left > y - top ? (y = top + x - left) : (x = left + y - top);
          }
          newGraph = new fabric.Ellipse({
            left: (x - left) / 2 + left,
            top: (y - top) / 2 + top,
            stroke: state.options.penColor,
            fill: "rgba(255, 255, 255, 0)",
            originX: "center",
            originY: "center",
            rx: Math.abs(left - x) / 2,
            ry: Math.abs(top - y) / 2,
            hasControls: false,
            selectable,
            strokeWidth: state.options.dotSize,
          });
          break;
        case "text": //文字
          activeGraph = canvas.getActiveObject();
          if (activeGraph && activeGraph.text == "") {
            canvas.remove(activeGraph);
          }
          if (!state.isOver) {
            textbox = new fabric.Textbox("", {
              left,
              top,
              width: 150,
              fontSize: 20,
              borderColor: "#2c2c2c",
              fill: state.options.penColor,
              selectable,
              hasControls: true,
            });
            canvas.add(textbox);
          }
          break;
        case "stave": //五线谱M参数表示move,L参数表示直线从哪里到哪里，与位置相关的只有left和top
        // 这里的M和L也是严格按照svg中的图像path路径实现的API说明
        // 50,50（M命令的点）到点100,100（L命令的点）画一条线
          path = `M${left} ${top} l${x - left} 0
          M${left} ${top + 30} l${x - left} 0
          M${left} ${top + 60} l${x - left} 0
          M${left} ${top + 90} l${x - left} 0
          M${left} ${top + 120} l${x - left} 0`;
          // TODO:利用路径函数创造一个五线谱：路径Path函数
          newGraph = new fabric.Path(path, {
            stroke: state.options.penColor,
            strokeWidth: 4,
            fill: "rgba(255, 255, 255, 0)",
            hasControls: false,
            selectable,
          });
          break;
        case "select": //移动
          // activeGraph = canvas.getActiveObject()
          break;
        default:
          break;
      }
      if (newGraph) {
        canvas.add(newGraph);
        graph = newGraph;
      }
    };

    const mousedown = (options) => {
      if (!state.show) false;
      state.mouse.start = [options.pointer.x, options.pointer.y];
      state.isOn = true;
      let name = common[state.cur].name;
      graph = null;
      canvas.isDrawingMode = name == "pen" || name == "eraser";
      if (name == "text") {
        draw(options);
      }
      if (textbox) {
        textbox.enterEditing();
        textbox.hiddenTextarea.focus();
      }
    };
    const mousemove = (options) => {
      if (!state.show) false;
      if (!state.isOn && moveCount % 2) return;
      moveCount++;
      let needMove = common[state.cur].needMove;
      if (needMove) {
        state.mouse.move = [options.pointer.x, options.pointer.y];
        draw(options);
      }
    };
    const mouseup = (options) => {
      if (!state.show) false;
      state.mouse.move = [options.pointer.x, options.pointer.y];
      if (graph) {
        canvas.remove(graph);
      }
      draw(options);
      state.isOn = false;
      moveCount = 0;
      if (common[state.cur].name == "eraser") {
        // const all = canvas.getObjects();
        // if (!all.length) return;
        // const last = all[all.length - 1];
        // eraserArr.push(last);
      } else if (common[state.cur].name == "select") {
        // if(activeGraph){
        //   activeGraph.set({
        //     left: options.pointer.x,
        //     top: options.pointer.y
        //   })
        // }
      } else if (common[state.cur].name == "stave") {
        const all = canvas.getObjects();
        if (!all.length) return;
        const last = all[all.length - 1];
        staveArr.push(last);
      }
    };
    const mouseover = (options) => {
      if (!state.show) false;
      if (options.target != null) {
        state.isOver = true;
      } else {
        state.isOver = false;
      }
    };
    const mouseout = () => {
      if (!state.show) false;
      state.isOver = false;
    };

    const initCanvas = () => {
      canvas = new fabric.Canvas(state.canvasId, {
        isDrawingMode: false, //默认开启自由绘画模式
        selectable: false, //设置是否可以选中拖动
        selection: false, // 是否可以多个对象为一组
        skipTargetFind: false, //画板元素不能被选中
        backgroundColor: state.options.backgroundColor,
      });

      canvas.freeDrawingBrush.color = state.options.penColor;
      canvas.freeDrawingBrush.width = state.options.dotSize;
      canvas.setWidth(state.options.width);
      canvas.setHeight(state.options.height);

      if (state.isDetail) {
        // colorChange("rgba(0,0,0,0)");
      }
      // 监听canvas的各种操作
      canvas.on("mouse:down", mousedown);
      canvas.on("mouse:move", mousemove);
      canvas.on("mouse:up", mouseup);
      canvas.on("mouse:over", mouseover);
      canvas.on("mouse:out", mouseout);
    };

    const whiteboard = ref("");
    onMounted(() => {
      state.options.width = document.body.clientWidth;
      state.options.height = document.body.clientHeight;
      let resizeTimer = null;
      window.onresize = () => {
        if (resizeTimer) clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function () {
          state.options.width = document.body.clientWidth;
          state.options.height = document.body.clientHeight;
          canvas.setWidth(state.options.width);
          canvas.setHeight(state.options.height);
        }, 100);
      };
      initCanvas();
    });

    const toolClick = (e, i) => {
      state.cur = 0;

      if (e.name == "background") {
        state.backgroundBox = !state.backgroundBox;
      } else {
        state.backgroundBox = false;
      }

      if (e.name == "dotSize") {
        state.sizeBox = !state.sizeBox;
      } else {
        state.sizeBox = false;
      }

      if (e.name == "palette") {
        state.colorBox = !state.colorBox;
      } else {
        state.colorBox = false;
      }

      if (e.name == "note") {
        state.noteHeight = state.noteHeight == 0 ? 270 : 0;
      } else {
        state.noteHeight = 0;
      }

      if (textbox) {
        textbox.exitEditing(false);
        textbox = null;
      }
      if (e.isTool) state.cur = i;

      if (e.name == "delete") {
        canvas.clear();
        canvas.backgroundColor = state.options.backgroundColor;
        state.cur = 0;
      } else if (e.name == "eraser") {
        canvas.freeDrawingBrush = new fabric.EraserBrush(canvas);
        canvas.freeDrawingBrush.width = state.options.dotSize * 1 + 10;
        canvas.freeDrawingBrush.color = state.options.backgroundColor;
        canvas.isDrawingMode = true;
      } else if (e.name == "pen") {
        canvas.freeDrawingBrush = new fabric.PencilBrush(canvas);
        canvas.freeDrawingBrush.width = state.options.dotSize;
        canvas.freeDrawingBrush.color = state.options.penColor;
        canvas.isDrawingMode = true;
      } else if (e.name == "revoke") {
        revoke();
      } else if (e.name == "redo") {
        redo();
      } else if (e.name == "note") {
        // state.noteHeight = state.noteHeight == 0 ? 136 : 0;
      } else if (e.name == "close") {
        // state.show = false;
        emit('close');
      } else if (e.name === 'export') {
        exportImage();
      }
    };
    let count = 0;
    const exportImage = () => {
      const dataURL = canvas.toDataURL({
        format: 'png',
        quality: 0.8
      });

      const link = document.createElement('a');
      link.style.display = 'none';
      link.href = dataURL;
      const date = new Date();
      link.download = '白板-' +      
        date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() 
        + '-' + date.getHours() + '-' + date.getSeconds() + '-' + date.getMinutes() 
        + '-' + count + '.png' ;
      count += 1;
      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(link.href);// 释放URL对象
      document.body.removeChild(link);
    }

    const revoke = () => {
      const all = canvas.getObjects();
      if (!all.length) return;
      const last = all[all.length - 1];
      lastGraph.push(last);
      canvas.remove(last);
    };

    const redo = () => {
      if (lastGraph.length) {
        let that = lastGraph.splice(lastGraph.length - 1, 1);
        canvas.add(that[0]);
      }
    };

    const predefineColors = ref([
      "#f4f4f4",
      "#000000",
      "#ffffff",
      "#ff4500",
      "#ff8c00",
      "#ffd700",
      "#90ee90",
      "#00ced1",
      "#1e90ff",
      "rgba(0,0,0,0)",
    ]);

    const colorChange = (color) => {
      state.options.backgroundColor = color;
      eraserArr.forEach((item) => {
        item.set("stroke", color);
      });
      canvas.setBackgroundColor(color, canvas.renderAll.bind(canvas));
    };

    const penColorChange = (color) => {
      state.options.penColor = color;
    };

    const sizeChange = (size) => {
      if (common[state.cur].name == "eraser") size += 10;
      canvas.freeDrawingBrush.width = size;
    };

    const addImage = (e) => {
      fabric.Image.fromURL(
        e.target.src,
        (img) => {
          canvas.add(img);
        },
        {
          selectable: true,
        }
      );
    };

    const noteClick = (event, { width, height }) => {
      let src = event.target.src;
      fetch(src, {
        method: 'GET',
        mode: 'cors',
      })
        .then(response => {
          return response.text()
        })
        .then((text) => {
          fabric.loadSVGFromString(text, (objects, options) => {
            let svg = fabric.util.groupSVGElements(objects, options);
            svg.scaleToWidth(width);
            svg.scaleToHeight(height);
            if (staveArr.length >= 1) {
              let lastStave = staveArr[staveArr.length - 1];
              svg.left = lastStave.left;
              svg.top = lastStave.top;
            } else {
              svg.left = canvas.width / 2;
              svg.top = canvas.height / 2 - 50;
            }

            canvas.add(svg).renderAll();
          });
        })
    };

    const handleChangePenColor = (color) => {
      state.options.penColor = color;
      state.colorBox = false;
      canvas.freeDrawingBrush.color = state.options.penColor;
    }

    const handleChangeDotSize =  (size) => {
      state.options.dotSize = size;
      state.sizeBox = false;
      canvas.freeDrawingBrush.width = state.options.dotSize;
    }
    return {
      state,
      whiteboard,
      toolClick,
      predefineColors,
      colorChange,
      penColorChange,
      sizeChange,
      addImage,
      noteClick,
      common,
      svg,
      handleChangePenColor,
      handleChangeDotSize,
    };
  },
};
</script>
<template>
  <div v-show="state.show">
    <div class="whiteboard" ref="whiteboard"><canvas :id="state.canvasId"></canvas></div>

    <div class="note" :style="`height:${state.noteHeight}px`">
      <el-tabs v-model="state.collapse" size="small" class="collapse" v-if="state.show">
        <template v-for="item in svg" :key="item.id">
          <el-tab-pane :label="item.cname" :name="item.id">
            <el-space wrap :size="item.id == 'action' ? 30 : 5">
              <template v-for="(e, j) in item.children" :key="e.path + j">
                <div class="noteItem" :class="item.id === 'action' ? 'note-action' : ''">
                  <template v-if="item.id == 'action'">
                    <img
                      @click="noteClick($event, e)"
                      :src="`https://tob-biz.oss-cn-beijing.aliyuncs.com/static-file/white-board/svg/${e.path}`"
                      width="60"
                      height="60"
                      crossorigin="anonymous"
                    />
                  </template>
                  <template v-else>
                    <img
                      @click="noteClick($event, e)"
                      :src="`https://tob-biz.oss-cn-beijing.aliyuncs.com/static-file/white-board/svg/${e.path}`"
                      width="30"
                      height="30"
                      crossorigin="anonymous"
                    />
                  </template>
                </div>
              </template>
            </el-space>
          </el-tab-pane>
        </template>
      </el-tabs>
    </div>
    <div class="tools">
      <el-space :size="0">
        <template v-for="(e, i) in common" :key="e.name">
          <!-- <template v-if="e.name == 'background'">
            <div
              class="item"
              v-if="!state.isDetail"
              @click="
                () => {
                  state.backgroundBox = !state.backgroundBox;
                  state.colorBox = false;
                  state.sizeBox = false;
                }
              "
            >
              <span class="iconfont" :class="e.icon"></span>
              <p>{{ e.cname }}</p>
            </div>
          </template>
          <template v-else-if="e.name == 'palette'">
            <div
              class="item"
              @click="
                () => {
                  state.colorBox = !state.colorBox;
                  state.backgroundBox = false;
                  state.sizeBox = false;
                }
              "
            >
              <span class="iconfont" :class="e.icon"></span>
              <p>{{ e.cname }}</p>
            </div>
          </template>
          <template v-else-if="e.name == 'dotSize'">
            <div
              class="item"
              @click="
                () => {
                  state.sizeBox = !state.sizeBox;
                  state.colorBox = false;
                  state.backgroundBox = false;
                }
              "
            >
              <span class="iconfont" :class="e.icon"></span>
              <p>{{ e.cname }}</p>
            </div>
          </template>
          <template v-else> -->
          <div
            class="item"
            :class="`${state.cur == i ? 'active' : ''}`"
            @click="toolClick(e, i)"
            
          >
            <span class="iconfont" :class="e.icon"></span>
            <p>{{ e.cname }}</p>
          </div>
          <!-- </template> -->
        </template>
      </el-space>
    </div>
  </div>
  <div class="toolsbox" v-show="state.backgroundBox == true">
    <div class="title">
      画板背景
      <span
        :style="{
          background: state.options.backgroundColor,
        }"
      ></span>
    </div>
    <div>
      <el-space :size="10" wrap>
        <template v-for="color in predefineColors" :key="color">
          <div
            class="color"
            :class="{ active: state.options.backgroundColor == color }"
            :style="{
              background: color,
            }"
            @click="
              () => {
                state.options.backgroundColor = color;
                colorChange(color);
                state.backgroundBox = false;
              }
            "
          ></div>
        </template>
      </el-space>
    </div>
  </div>
  <div class="toolsbox" v-show="state.colorBox == true">
    <div class="title">
      画笔颜色
      <span
        :style="{
          background: state.options.penColor,
        }"
      ></span>
    </div>
    <div>
      <el-space :size="10" wrap>
        <template v-for="color in predefineColors" :key="color">
          <div
            class="color"
            :class="{ active: state.options.penColor == color }"
            :style="{
              background: color,
            }"
            @click="handleChangePenColor(color)"
          ></div>
        </template>
      </el-space>
    </div>
  </div>
  <div class="toolsbox" v-show="state.sizeBox == true">
    <div class="title">
      画笔大小
      <span
        class="pensize"
        :style="{
          height: state.options.dotSize + 'px',
        }"
      ></span>
    </div>
    <div>
      <el-space :size="10" wrap>
        <template v-for="size in 10" :key="size">
          <div
            class="size"
            :class="{ active: state.options.dotSize == size }"
            @click="handleChangeDotSize(size)"
          >
            <span
              :style="{
                height: size + 'px',
              }"
            ></span>
          </div>
        </template>
      </el-space>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.toolsbox {
  width: 260px;
  height: 160px;
  background: rgba(65, 76, 95, 0.75);
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 90px;
  border-radius: 12px;
  padding: 0 0 0 10px;
  z-index: 3;
  .title {
    color: #fff;
    font-size: 16px;
    line-height: 50px;
    padding: 0 10px;
    border-bottom: 1px solid #979797;
    margin: 0 0px 10px -10px;
    span {
      float: right;
      width: 30px;
      height: 30px;
      margin-top: 10px;
      border-radius: 5px;
    }
    .pensize {
      width: 35px;
      transform: rotate(-45deg);
      background: #fff;
      border-radius: 5px;
      margin-top: 22px;
    }
  }
  .iconfont {
    font-size: 34px;
    width: 100%;
    margin-top: 8px;
  }
  span {
    width: 78px;
    display: inline-block;
  }
  .item {
    color: #fff;
    width: 78px;
    text-align: center;
    cursor: pointer;
  }
}
.size,
.color {
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  border: 1px solid #fff;
  cursor: pointer;
  &.active {
    border: 1px solid #000;
    border-radius: 5px;
  }
}
.size {
  background: #fff;
  overflow: hidden;
  position: relative;
  span {
    width: 35px;
    transform: rotate(-45deg);
    background: #000;
    position: absolute;
    top: 18px;
    left: 3px;
    border-radius: 5px;
  }
}
:deep(.el-space__item) {
  padding-bottom: 10px !important;
}
.pt5 {
  padding-top: 5px;
}
.whiteboard {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
.tools {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  height: 78px;
  background: rgba(65, 76, 95, 0.75);
  border-radius: 12px;
  z-index: 3;
  .iconfont {
    font-size: 34px;
    width: 100%;
    margin-top: 8px;
  }
  .item {
    position: relative;
    width: 78px;
    height: 78px;
    border-radius: 12px;
    color: #fff;
    cursor: pointer;
    &:hover {
      background: #3a82ff;
    }
  }
  .active {
    background: #3a82ff;
  }
  p {
    font-size: 14px;
  }
  .w90 {
    width: 90px;
  }
}
.note {
  transition: height linear 0.3s;
  overflow: hidden;
  padding: 0 15px;
  z-index: 1;
  background: rgba(65, 76, 95, 0.35);
  position: fixed;
  bottom: 80px;
  left: 50%;
  margin-left: -445px;
  width: 890px;
  border-radius: 12px;
  height: 0;
  box-sizing: border-box;
  z-index: 3;
  img {
    width: auto;
    cursor: pointer;
    height: 30px;
    display: block;
  }
  .note-action {
    & > img {
      width: 59px;
    }
  }
  :deep(.el-space__item) {
    background: #f2f2f2;
    margin: 0 5px 5px 0;
    border-radius: 5px;
    padding: 5px !important;
  }
}
// .noteItem {
//   cursor: pointer;
//   border: 1px solid #eee;
// }
.iconfont {
  font-size: 22px;
  display: inline-block;
  cursor: pointer;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 6px;
  text-align: center;
}
.toolbox {
  position: fixed;
  right: 20px;
  top: 200px;
  width: 42px;
  background: #fff;
  border: 1px solid #eee;
  border-bottom: none;
  border-radius: 5px;
  overflow: hidden;
  transition: height linear 0.3s;
  cursor: pointer;
  span {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 26px;
    border-bottom: 1px solid #eee;
    border-radius: 0px;
    &.active {
      background: #6c92fe;
      color: #fff;
    }
  }
}
</style>
<style>
/* .colorPicker .el-color-dropdown__btn,
.colorPicker .el-color-dropdown__link-btn{
  display: none;
} */
.collapse .el-tabs__item {
  color: #fff;
}
/* .collapse .el-tabs__item.is-active{
color:#3a82ff
} */
.collapse .el-tabs__nav-wrap::after {
  height: 1px;
}
.collapse .el-collapse-item__content {
  padding-bottom: 0;
}
.collapse .el-collapse-item__wrap:last-child {
  border-bottom: none;
}
</style>
