<template>
  <div class="music-simulation-container">
    <CultureDialog @switchCulture="switchCulture">
      <template v-slot:header>
        <div class="title-name">模唱训练</div>
      </template>
      <template v-slot:content>
        <ul class="simulation-list">
          <li v-for="(item, index) in simulationList" :key="index" :data-src="item.src" :data-hash="item.hash">
            {{ item.name }}
            <img v-if="item.flag === 1" src="@/assets/img/play.gif" alt="播放中" />
            <div class="control-box">
              <button v-if="item.flag === 0" @click="play(index)">播放</button>
              <button v-if="item.flag === 1"  @click="pause(index)">暂停</button>
              <button v-if="item.flag === 2"  @click="play(index)">继续</button>
              <button v-if="item.flag === 1"  @click="stop(index)">停止</button>
            </div>
          </li>
        </ul>
      </template>
    </CultureDialog>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, reactive } from "vue";
import CultureDialog from "./cultureDialog.vue";
import soundAudio from '@/utils/soundAudio';
export default defineComponent({
  components: {
    CultureDialog,
  },
  setup(_, { emit }) {
    let playIndex = ref(0);
    const simulationList = reactive([
      {
        name: "二度音程",
        src: "2degree",
        flag: 0,
      },
      {
        name: "三度音程1",
        src: "3degree1",
        flag: 0,
      },
      {
        name: "三度音程2",
        src: "3degree2",
        flag: 0,
      },
      {
        name: "四度音程1",
        src: "4degree1",
        flag: 0,
      },
      {
        name: "四度音程2",
        src: "4degree2",
        flag: 0,
      },
      {
        name: "五度音程1",
        src: "5degree1",
        flag: 0,
      },
      {
        name: "五度音程2",
        src: "5degree2",
        flag: 0,
      },
      {
        name: "六度音程1",
        src: "6degree1",
        flag: 0,
      },
      {
        name: "六度音程2",
        src: "6degree2",
        flag: 0,
      },
      {
        name: "七度音程1",
        src: "7degree1",
        flag: 0,
      },
      {
        name: "七度音程2",
        src: "7degree2",
        flag: 0,
      },
      {
        name: "C大调音阶",
        src: "cMax",
        flag: 0,
      },
      {
        name: "a小调音阶",
        src: "aMinor",
        flag: 0,
      },
      {
        name: "G大调音阶",
        src: "GMax",
        flag: 0,
      },
      {
        name: "e小调音阶",
        src: "eMinor",
        flag: 0,
      },
      {
        name: "F大调音阶",
        src: "FMax",
        flag: 0,
      },
      {
        name: "d小调音阶",
        src: "dMinor",
        flag: 0,
      },
      {
        name: "D大调音阶",
        src: "DMax",
        flag: 0,
      },
      {
        name: "b小调音阶",
        src: "bMinor",
        flag: 0,
      },
      {
        name: "降b大调音阶",
        src: "dropBMax",
        flag: 0,
      },
      {
        name: "g小调音阶",
        src: "gMinor",
        flag: 0,
      },
    ]);
    const switchCulture = () => {
      emit("switchCulture", "showSimulation");
      soundAudio.removeAudio();
      simulationList[playIndex.value].flag = 0;
    };
    // const handlerSimulation = (e) => {
    //     if(e.target.nodeName.toLowerCase() === 'li') {
    //         simulationList[playIndex.value].flag = false;
    //         playIndex.value = simulationList.findIndex(item => item.src === e.target.dataset?.src);
    //         simulationList[playIndex.value].flag = true;
    //         soundAudio.addAudio(e.target.dataset?.src, e.target.dataset?.hash, simulationList[playIndex.value]);
    //     }
    // };

    const play = (index) => {
      if (simulationList[index].flag === 2) {
        playIndex.value = index;
        simulationList[playIndex.value].flag = 1;
        soundAudio.play();
      } else {
        simulationList[playIndex.value].flag = 0;
        playIndex.value = index;
        simulationList[playIndex.value].flag = 1;
        const simulation = simulationList[index];
        soundAudio.addAudio(simulation?.src, simulation?.hash, simulationList[playIndex.value]);
      }
    };

    const stop = (index) => {
      simulationList[playIndex.value].flag = 0;
      soundAudio.stop();
    }

    const pause = (index) => {
      simulationList[playIndex.value].flag = 2;
      soundAudio.pause();
    };

    return {
      simulationList,
      switchCulture,
      // handlerSimulation,
      play,
      stop,
      pause,
    };
  },
});
</script>
<style lang="stylus" scoped>
.music-simulation-container {
  .title-name {
    font-size: 30px;
  }

  .simulation-list {
    & > li {
      width: 100%;
      height: 130px;
      line-height: 130px;
      padding: 0 50px 0 50px;
      border-bottom: 1px solid #eaeaea;
      display: flex;
      justify-content: space-between;
      & > img {
        width: 30%;
        height: 70%;
        display: inline-block;
        height: 100%;
        float: right;
        margin-right: 50px;
      }

      &:hover {
        color: #5dc3d7;
      }

      & > span {
      }
    }
  }
  .control-box {
    button {
      line-height: 30px;
      margin: 0 0 0 20px;
      cursor: pointer;
    }
  }
}
</style>