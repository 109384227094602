<template>
  <div class="music-reading">
    <div class="content-wrapper">
      <div class="content-header">
        <div class="music-type">
          <span class="title">曲谱:</span>
          <el-radio-group v-model="musicType">
            <el-radio value="staff">五线谱</el-radio>
            <el-radio value="numbered">简谱</el-radio>
          </el-radio-group>
        </div>
        <div class="pitch-type">
          <span class="title">谱表：</span>
          <el-radio-group v-model="staffType">
            <el-radio value="treble">高音</el-radio>
            <el-radio value="bass">低音</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="notes">
        <Note v-for="one in notes" :value="one" :key="one" :music-type="musicType" :staff-type="staffType"
          @check="handleClickNote" />
      </div>
      <div class="note-detail" v-if="showDetail">
        <div class="note-image">
          <Note :value="currentNote" :music-type="musicType" :staff-type="staffType" />
        </div>
        <div class="note-info">
          <div><span class="label">音名：</span><span>{{ noteMap[currentNote - 1].pitchName }}</span></div>
          <div><span class="label">唱名：</span><span>{{ noteMap[currentNote - 1].syllableName }}</span></div>
          <div class="play-btn" @click="handlePlayNote">
            <el-icon>
              <VideoPlay />
            </el-icon>
          </div>
        </div>
        <div class="ctl-btn-left" v-if="currentIndex > 0" @click="handlePreview">
          <el-icon>
            <ArrowLeftBold />
          </el-icon>
        </div>
        <div class="ctl-btn-right" v-if="currentIndex < notes.length - 1" @click="handleNext">
          <el-icon>
            <ArrowRightBold />
          </el-icon>
        </div>
        <div class="ctl-back">
          <el-button type="primary" @click="showDetail = false">返回</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, reactive, toRefs, watch } from 'vue';
import { useStore } from 'vuex';
import CultureDialog from "../cultureDialog.vue";
import Note from './note.vue';
import soundAudio from '@/utils/soundAudio';

const noteMap = [
  {
    syllableName: 'Do',
    pitchName: 'C',
  },
  {
    syllableName: 'Re',
    pitchName: 'D',
  },
  {
    syllableName: 'Mi',
    pitchName: 'E',
  },
  {
    syllableName: 'Fa',
    pitchName: 'F',
  },
  {
    syllableName: 'Sol',
    pitchName: 'G',
  },
  {
    syllableName: 'La',
    pitchName: 'A',
  },
  {
    syllableName: 'Si',
    pitchName: 'B',
  }];

// const audioUrl = 'https://yw-tob-teacher.oss-cn-beijing.aliyuncs.com/assets/samples/piano/';
const audioUrl = 'https://yw-tob-teacher.oss-cn-beijing.aliyuncs.com/assets/samples/vocal/';

export default defineComponent({
  components: { CultureDialog, Note },

  setup(_, { emit }) {
    const store = useStore();

    const state = reactive({
      musicType: store.state.account.schoolInfo.scoreType === 2 ? 'staff' : 'numbered',
      staffType: 'treble',
      notes: [1, 2, 3, 4, 5, 6, 7],
      currentNote: 0,
      currentIndex: -1,
      showDetail: false,
      noteMap,
    });

    function switchCulture() {
      emit('switchCulture', 'showCulture');
      soundAudio.removeAudio();
    }

    const generateNumbered = () => {
      const temp = [];
      const data = [1, 2, 3, 4, 5, 6, 7];
      while (data.length) {
        const random = parseInt(Math.random() * 10) % data.length;
        temp.push(data.splice(random, 1)[0]);
      }
      state.notes = temp;
    }

    onMounted(() => {
      // generateNumbered();
    });

    onUnmounted(() => {
      soundAudio.removeAudio();
    });

    watch(() => state.musicType, () => {
      // generateNumbered();
    })

    watch(() => state.staffType, () => {
      // generateNumbered();
    })

    function handleClickNote(note) {
      state.currentNote = note;
      state.currentIndex = state.notes.indexOf(note);
      state.showDetail = true;
    }

    function handlePreview() {
      const index = state.currentIndex - 1;
      state.currentIndex = Math.max(0, index);
      state.currentNote = state.notes[state.currentIndex];
    }

    function handleNext() {
      const index = state.currentIndex + 1;
      state.currentIndex = Math.min(state.notes.length - 1, index);
      state.currentNote = state.notes[state.currentIndex];
    }

    function handlePlayNote() {
      let name = noteMap[state.currentNote - 1].pitchName;
      name += state.staffType === 'bass' ? 3 : 4;
      // audio播放
      soundAudio.addAudio(name, '', { audioUrl });
    }

    return {
      ...toRefs(state),
      switchCulture,
      handleClickNote,
      handlePreview,
      handleNext,
      handlePlayNote,
    }
  }
})
</script>

<style scoped lang="scss" src="./style.scss"></style>
<style scoped lang="scss">
.notes {
  margin: 20px;
  display: flex;
  flex-wrap: wrap;

  .numbered-note,
  .song-image {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 260px;
    padding: 10px;
    box-sizing: border-box;
  }
}

.content-wrapper {
  position: relative;
  height: 100%;

  .note-detail {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: #fff;
  }

  .note-image {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 30px;
  }

  .note-info {
    display: flex;
    align-items: center;
    justify-content: center;

    &>div {
      font-size: 40px;
      display: flex;
      align-items: center;
    }

    span.label {
      color: #666;
      font-size: 30px;
    }

    &>div+div {
      margin: 0 0 0 40px;
    }

    .play-btn {
      color: orange;
      font-size: 50px;
      line-height: 0;
    }
  }

  .ctl-btn-left,
  .ctl-btn-right {
    position: absolute;
    top: 40%;
    font-size: 50px;
    cursor: pointer;
  }

  .ctl-btn-left {
    left: 20px;
  }

  .ctl-btn-right {
    right: 20px;
  }

  .ctl-back {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}
</style>