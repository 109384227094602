<script>
import { defineComponent, reactive, onMounted, watch, ref } from 'vue'
import * as Tone from 'tone';
import { initTone } from './tone';
import { onBeforeUnmount } from 'vue';

// const preload = (src) => {
//   let image = new Image();
//   image.src = src;
//   image.onerror = () => {
//     image.onerror = null;
//     image.onload = null;
//     image = null;
//   };

//   image.onload = () => {
//     image.onerror = null;
//     image.onload = null;
//     image = null;
//   }
// }

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const state = reactive({
      show: false,
      top: '200px',
      left: '200px',
      sliderLeft: '-435px',
      sliderTop: '0px',
      pianoLeft: '-1380px',
      pianoTop: '0px',
      cur: 'c'
    });

    let piano;
    initTone().then(res => {
      piano = res;
    });

    const sliderBlockRef = ref(null)

    watch(() => props.show, () => {
      state.show = !state.show
      clearInterval(state.timer);
    })

    // const remoteWhiteUrl = 'https://yw-tob-teacher.oss-cn-beijing.aliyuncs.com/assets/sounds/piano/white/'
    // const remoteBlackUrl = 'https://yw-tob-teacher.oss-cn-beijing.aliyuncs.com/assets/sounds/piano/black/'
    // const pianoClick = (type,index)=>{
    //   // let classe = type ? `.white${index}` : `.black${index}`;
    //   // let audio = document.querySelectorAll(classe)[0];
    //   const pianoAudio = window.__pianoAudio;
    //   pianoAudio.src = (type ? remoteWhiteUrl : remoteBlackUrl) + index + '.mp3'
    //   pianoAudio.load()
    //   pianoAudio.play()
    // }

    const pianoClick = (e) => {
      e.preventDefault();
      const targets = [];
      let touches;
      if (e.type === 'touchstart') {
        touches = e.changedTouches || e.targetTouches;
        touches = Array.prototype.slice.call(touches);
      } else if (e.type === 'click') {
        touches = [{target: e.target}];
      }
      touches.forEach(one => {
        let target = one.target;
        while(target) {
          if (target && target.nodeName.toLowerCase() === 'li' && target.getAttribute('data-notation')) {
            targets.push(target.getAttribute('data-notation'));
            break;
          }
          target = target.parentNode;
          if (target.nodeName.toLowerCase() === 'body') {
            break;
          }
        }
      });
      if (targets.length) {
        piano.triggerAttackRelease(targets, '2n');
      }
    };

    /**
     * @description:当前钢琴上的拖动函数的，这里有两类拖动：一种是直接拖动窗口，一种是拖动下面钢琴阴影部分 
     * @param {*} e
     * @return {*}
     */
    let move = (e) => {
      if (typeof (e.target.className) == 'object') {
        document.onmousemove = null;
        document.onmouseup = null;
        return;
      }

      let thatLeft = parseFloat(state.left),
        thatTop = parseFloat(state.top);
      if (e.target.className.indexOf('parent-not-move') != -1) {
        thatLeft = parseFloat(state.sliderLeft),
          thatTop = parseFloat(state.sliderTop);
      }
      let disX = e.clientX - thatLeft;
      let disY = e.clientY - thatTop;

      let maxSliderLeft = sliderBlockRef.value.clientWidth;

      let lastTarget;
      // TODO:操作当前的中间展示区的代码，如果是当前的拖动展示区
      document.onmousemove = (e) => {
        let left = e.clientX - disX;
        let top = e.clientY - disY;
        if (e.target.className.indexOf('parent-not-move') != -1) {
          lastTarget = e.target;
          // 左侧的边界
          if (left < - maxSliderLeft) left = -maxSliderLeft;
          // 右侧的拖动的边界
          if (left > (-70 - 173.0769230769)) left = -70 - 173.0769230769;
          let rate = 11.53846153846 / 60;
          state.sliderLeft = left + 'px';
          state.pianoLeft = -(left + maxSliderLeft) / rate + 'px';
          return
        } else {
          if (lastTarget && lastTarget.className.indexOf('parent-not-move') !== -1) {
            return;
          }
          state.left = (left < 10 ? 10 : left) + 'px';
          state.top = (top < 10 ? 10 : top) + 'px';
        }
      };
      document.onmouseup = (e) => {
        lastTarget = null;
        document.onmousemove = null;
        document.onmouseup = null;
      };
    };

    let deviation = 0;
    // 初始化定义当前的黑键的渲染位置，大致的渲染位置52-36=16,白键比黑键多16个，这里是模糊的测试
    const arr = [2, 4, 7, 9, 12, 14, 17, 19, 22, 24, 27, 29, 32, 34];


    /**
     * @description:循环展示的时候，处理黑键作为居中的键，如何展示样式位置的处理 
     * @param {*} index
     * @param {*} isSlider
     * @return {*}
     */
    const left = (index, isSlider) => {
      const num = isSlider ? 11.53846153846 : 60;
      if (arr.indexOf(index) != -1) deviation += num
      let width = index * num - (num / 2) + deviation + 10;
      if (isSlider) width = index * num - (num / 2) + deviation;
      if (index == 36) deviation = 0;
      return width
    }

    const tagChange = () => {
      if (state.cur == 'a') {
        state.cur = 'b'
      } else if (state.cur == 'b') {
        state.cur = 'c'
      } else {
        state.cur = 'a'
      }
    }

    let textArr = [{
      a: 1,
      b: 'do',
      c: 'C'
    }, {
      a: 2,
      b: 're',
      c: 'D'
    }, {
      a: 3,
      b: 'mi',
      c: 'E'
    }, {
      a: 4,
      b: 'fa',
      c: 'F'
    }, {
      a: 5,
      b: 'sol',
      c: 'G'
    }, {
      a: 6,
      b: 'la',
      c: 'A'
    }, {
      a: 7,
      b: 'si',
      c: 'B'
    }]

    let isCurrentLoop = 2;
    const showText = (index) => {
      if (index === 1) {
        if (state.cur == 'c') {
          isCurrentLoop = 2;
        } else if (state.cur === 'a') {
          isCurrentLoop = -4;
        }
      }
      let i = (index + 4) % 7;
      let text = textArr[i][state.cur] || '';
      if (i == 0) {
        if (state.cur !== 'c') {
          isCurrentLoop++;
        } else {
          isCurrentLoop--;
        }
      }
      let num = isCurrentLoop;
      // if(index == 52) isCurrentLoop = 2;
      if (state.cur === 'c') {
        // text += num
        if (num > 0) {
          text = `<span class="piano-key-note"><em>${text}</em><sub>${num}</sub></span>`;
        } else if (num === 0) {
          text = `<span class="piano-key-note"><em>${text}</em></span>`;
        } else if (num < 0) {
          if (num === -1) {
            text = `<span class="piano-key-note"><em>${text.toLowerCase()}</em></span>`;
          } else {
            text = `<span class="piano-key-note"><em>${text.toLowerCase()}</em><sup>${Math.abs(num + 1)}</sup></span>`
          }
        }
      } else if (state.cur === 'a') {
        if (num > 0) {
          let dotStr = '';
          for (let j = 0; j < num; j++) {
            dotStr += `<span class="piano-key-dot"></span>`;
          }
          text = dotStr + `<em class="piano-key-note">${text}</em>`;
        } else if (num === 0) {
          text = `<em class="piano-key-note">${text}</em>`;
        } else if (num < 0) {
          let dotStr = '';
          for (let j = 0; j < Math.abs(num); j++) {
            dotStr += `<span class="piano-key-dot"></span>`;
          }
          text = `<em class="piano-key-note">${text}</em>` + dotStr;
        }
      }
      return text
    }

    let x = 0;
    const getScientificPitchNotaion = (index, type) => {
      let text = '';
      if (type === 'white') { // 白键
        let x = parseInt(Math.abs((index + 4) / 7));
        let i = (index + 4) % 7;
        text = textArr[i].c || '';
        text += x;
      } else { // 黑键
        let x = parseInt(Math.abs((index + 3) / 5));
        let i = (index + 4 + x) % 6;
        if (i <= 2) {
          i--;
        } 
        text = (textArr[i].c || '') + '#';
        text += x;
      }

      // 1 A#0 5

      // 2 C#1 0
      // 3 D#1 1
      // 4 F#1 3
      // 5 G#1 4
      // 6 A#1 5

      // 7 C#2 0
      // 8 D#2 1
      // 9 F#2 3
      // 10 G#2 4
      // 11 A#2 5

      
      return text;
    };

    let isCurrentLoop2 = 0;
    /**
     * @description:这个函数没有用处，可以删除 review 
     * @param {*} index
     * @return {*}
     */
    const getClass = (index) => {
      let i = (index + 4) % 7;
      if (i == 0) isCurrentLoop2++;
      let num = isCurrentLoop2;
      if (index == 52) isCurrentLoop2 = 0;
      return 'color' + num
    }
    onMounted(() => {
      // if (!window.__pianoAudio) {
      //   for (var i = 1; i <= 52; i++) {
      //     preload(`${remoteWhiteUrl}${i}.mp3`)
      //   }
      //   for (var j = 1; j <= 36; j++) {
      //     preload(`${remoteBlackUrl}${j}.mp3`)
      //   }

      //   const pianoAudio = document.createElement('audio')
      //   pianoAudio.setAttribute('id', 'piano-key-audio')
      //   document.body.appendChild(pianoAudio)
      //   window.__pianoAudio = pianoAudio;
      // }
      let pianoHeader = document.getElementById('piano-header');
      let piano = document.getElementById('piano');
      if (piano) {
        piano.addEventListener("contextmenu", (event) => {
          event.preventDefault()
        })
      }

      let oL, oT;
      if (pianoHeader) {
        pianoHeader.addEventListener('touchstart', (e) => {
          e.preventDefault();

          var ev = e || window.event;
          if (ev.targetTouches.length > 1) {
            return;
          }
          var touch = ev.targetTouches[0];
          oL = touch.clientX - parseFloat(state.left);
          oT = touch.clientY - parseFloat(state.top);
          if (ev.target.className.indexOf('parent-not-move') != -1) {
            oL = touch.clientX - parseFloat(state.sliderLeft),
              oT = touch.clientY - parseFloat(state.sliderTop);
          }
          document.addEventListener("touchmove", pianoTouchMove, {passive: false});
          document.addEventListener("touchend", pianoTouchEnd);
          document.addEventListener("touchcancel", pianoTouchEnd);
        });
      }
      

      onBeforeUnmount(() => {
        document.removeEventListener("touchmove", pianoTouchMove, {passive: false});
        document.removeEventListener("touchend", pianoTouchEnd);
        document.removeEventListener("touchcancel", pianoTouchEnd);
      })

      const pianoTouchMove = function (e) {
        e.preventDefault();

        var ev = e || window.event;
        if (ev.targetTouches.length > 1) {
          return;
        }
        var touch = ev.targetTouches[0];
        var oLeft = touch.clientX - oL;
        var oTop = touch.clientY - oT;
        if (ev.target.className.indexOf('parent-not-move') != -1) {
          if (Math.abs(parseInt(state.sliderLeft) - oLeft) < 15) return;

          if (oLeft < -700) oLeft = -700;
          if (oLeft > (-70 - 173.0769230769)) oLeft = -70 - 173.0769230769;
          let rate = 11.53846153846 / 60;
          state.sliderLeft = oLeft + 'px';
          state.pianoLeft = -(oLeft + 700) / rate + 'px';
        } else {
          if (Math.abs(parseInt(state.left) - oLeft) < 15 && Math.abs(parseInt(state.top) - oTop) < 15) return; 
          state.left = (oLeft < 10 ? 10 : oLeft) + 'px';
          state.top = (oTop < 10 ? 10 : oTop) + 'px';
        }
      };
      const pianoTouchEnd = function (e) {
        document.removeEventListener("touchmove", pianoTouchMove, {passive: false});
        document.removeEventListener("touchend", pianoTouchEnd);
      };document.removeEventListener("touchcancel", pianoTouchEnd);

    })

    return {
      getClass,
      showText,
      tagChange,
      left,
      state,
      move,
      pianoClick,
      sliderBlockRef,
      getScientificPitchNotaion
    }
  },
})
</script>

<template>
  <div id="piano" class="piano" v-show="state.show" @mousedown="move" :style="`left:${state.left};top:${state.top};`">
    <div class="tool" id="piano-header">
      <div class="tag">
        <a @click="tagChange">显示标签</a>
        <span class="close" @touchstart.stop @click.stop="state.show = false"><svg width="30" height="30" viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg" data-v-fd76e4fe="">
            <path fill="currentColor"
              d="M466.752 512l-90.496-90.496a32 32 0 0145.248-45.248L512 466.752l90.496-90.496a32 32 0 1145.248 45.248L557.248 512l90.496 90.496a32 32 0 11-45.248 45.248L512 557.248l-90.496 90.496a32 32 0 01-45.248-45.248L466.752 512z">
            </path>
            <path fill="currentColor"
              d="M512 896a384 384 0 100-768 384 384 0 000 768zm0 64a448 448 0 110-896 448 448 0 010 896z"></path>
          </svg></span>
      </div>
      <!-- 可以拖动当前的滑动条的部分设置 -->
      <div class="slider">
        <div class="keyboard">
          <ul class="white">
            <template v-for="e in 52" :key="'white'+e">
              <li><span></span></li>
            </template>
          </ul>
          <ul class="black">
            <template v-for="e in 36" :key="'black'+e">
              <li :style="`left:${left(e, 1)}px`"><span></span></li>
            </template>
          </ul>
        </div>
        <!-- 模拟拖动区，可以拖动的部分，分为三部分，左右部分是当前的阴影不可以展示的部分，中间是当前的展示区 -->
        <div class="mask-outer parent-not-move" :style="`left:${state.sliderLeft};`">
          <span class="parent-not-move" ref="sliderBlockRef"></span>
          <!-- 中间拖动展示区 -->
          <span class="parent-not-move"></span>
          <span class="parent-not-move"></span>
        </div>
      </div>
    </div>
    <!-- 钢琴琴键的88键的汇总：每一个钢琴展示当前的钢琴音乐 -->
    <div class="keyboard" @touchstart="pianoClick" @click="pianoClick">
      <ul class="white" :style="`left:${state.pianoLeft};`">
        <template v-for="e in 52" :key="'white'+e">
          <li :data-notation="getScientificPitchNotaion(e, 'white')"><span>
              <!-- {{ getScientificPitchNotaion(e, 'white') }} -->
              <i :class="getClass(e)" v-html="showText(e)"></i>
            </span></li>
        </template>
      </ul>
      <ul class="black" :style="`left:${state.pianoLeft};`">
        <template v-for="e in 36" :key="'black'+e">
          <li :data-notation="getScientificPitchNotaion(e, 'black')" :style="`left:${left(e)}px`">
            <!-- {{getScientificPitchNotaion(e, 'black')}} -->
            <span></span>
          </li>
        </template>
      </ul>
    </div>
  </div>

</template>

<style lang="scss" scoped>
.piano {
  background: #000;
  width: 940px;
  height: 500px;
  position: fixed;
  z-index: 8;
}

$keyWidth: 60px;
$keyHeight: 300px;

.keyboard {
  height: 360px;
  width: $keyWidth * 15;
  overflow: hidden;
  position: relative;
  margin: 0 20px 20px;

  ul {
    overflow: hidden;
    width: $keyWidth * 52;
    position: absolute;
  }

  li {
    float: left;
    width: $keyWidth;
    height: $keyHeight;
    padding: 0 1px;
    cursor: pointer;
    position: relative;

    span {
      display: block;
      background: #fff;
      width: 100%;
      height: $keyHeight;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.4) inset;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      text-align: center;
      font-size: 14px;
    }

    &:active span {
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.4) inset;
    }

    i {
      position: absolute;
      width: 100%;
      text-align: center;
      bottom: 15px;
      font-style: normal;
      font-size: 18px;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: center;
      height: 60px;
    }
  }

  ul.black {
    height: $keyHeight - 120px + 10px;

    li {
      position: absolute;
      top: 0;
      width: $keyWidth - 20px;
      height: $keyHeight - 120px;

      span {
        background: #000;
        height: $keyHeight - 120px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);

        &::before {
          content: '';
          display: block;
          width: calc(100% - 8px);
          height: $keyHeight - 130px;
          border-color: #ccc;
          margin-left: 3px;
          background: rgba(255, 255, 255, 0.2);
          box-shadow: 0 0 10px rgba(112, 112, 112, 0.2) inset;
          border-style: solid;
          border-width: 1px;
          border-color: #fff;
          border-image: linear-gradient(#000, rgb(155, 155, 155)) 30 30;
        }
      }

      &:active span::before {
        background: rgba(255, 255, 255, 0.3);
      }
    }
  }
}

.slider {
  position: relative;
  overflow: hidden;
  // margin-bottom: 20px;
  width: 600px;
  margin: 0 auto 20px;

  .keyboard {
    height: 60px;

    ul {
      overflow: hidden;
      width: 1200px;
    }

    li {
      width: 11.53846153846px;
      height: 60px;
      cursor: pointer;

      span {
        height: 60px;
      }
    }

    ul.black {
      li {
        width: 11.53846153846px;
        height: 40px;

        span {
          height: 40px;
          background: #000;

          &::before {
            width: 100%;
            height: 40px;
          }
        }

      }
    }
  }
}

.mask-outer {
  width: 1515.3846153846px;
  ;
  position: absolute;
  display: flex;
  top: 0;

  span {
    height: 60px;
    width: 173.0769230769px;
    display: inline-block;
    cursor: pointer;
  }

  span:first-child,
  span:last-child {
    width: 700px;
    background: rgba(0, 0, 0, 0.6);
    cursor: default;
  }
}

.tag {
  margin: 20px;
  height: 30px;

  a {
    padding: 5px 10px;
    height: 30px;
    background: #fff;
    border-radius: 4px;
    box-sizing: border-box;
    float: left;
    font-size: 14px;
  }

  .close {
    float: right;
    width: 30px;
    height: 30px;
    color: #fff;
  }
}
</style>

<style>
.piano-key-note {
  margin: 2px 0;
  line-height: 18px;
  font-style: normal;
}

.piano-key-note em {
  font-style: normal;
  vertical-align: bottom;
}

.piano-key-note sub {
  vertical-align: middle;
}

span.piano-key-dot {
  display: block;
  width: 2px;
  height: 2px;
  background: #000;
  margin: 2px 0;
}
</style>