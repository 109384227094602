import { session } from "@/utils/utils";
const state = session("homeState") || {
  latestCourse: null,
};

const Obj = {
  namespaced: true,
  state,
  mutations: {
    setData(state, obj) {
      Object.assign(state, obj);
      session("homeState", state);
    },
  },
};

export default Obj;
