export default [
  {
    id: "stave",
    path: "stave",
    cname: "五线谱类",
    children: [
      {
        path: "stave/1/2.svg",
        cname: "高音谱号",
        width: 150,
        height: 150,
      },
      {
        path: "stave/1/1.svg",
        cname: "低音谱号",
        width: 120,
        height: 120,
      },
      {
        path: "stave/2/1.svg",
        cname: "全音符",
        width: 30,
        height: 30,
      },
      {
        path: "stave/2/2.svg",
        cname: "上二分音符",
        width: 140,
        height: 140,
      },
      {
        path: "stave/2/5.svg",
        cname: "下二分音符",
        width: 140,
        height: 140,
      },
      {
        path: "stave/2/4.svg",
        cname: "上四分音符",
        width: 140,
        height: 140,
      },
      {
        path: "stave/2/7.svg",
        cname: "下四分音符",
        width: 140,
        height: 140,
      },
      {
        path: "stave/2/8.svg",
        cname: "上八分音符",
        width: 140,
        height: 140,
      },
      {
        path: "stave/2/9.svg",
        cname: "下八分音符",
        width: 140,
        height: 140,
      },
      {
        path: "stave/2/3.svg",
        cname: "上十六分音符",
        width: 140,
        height: 140,
      },
      {
        path: "stave/2/6.svg",
        cname: "下十六分音符",
        width: 140,
        height: 140,
      },
      {
        path: "stave/2/10.svg",
        cname: "上三十二分音符",
        width: 140,
        height: 140,
      },
      {
        path: "stave/2/11.svg",
        cname: "下三十二分音符",
        width: 140,
        height: 140,
      },

      {
        path: "stave/5/3.svg",
        cname: "全休止符",
        width: 90,
        height: 90,
      },

      {
        path: "stave/5/2.svg",
        cname: "二分休止符",
        width: 90,
        height: 90,
      },

      {
        path: "stave/5/5.svg",
        cname: "四分休止符",
        width: 90,
        height: 90,
      },
      {
        path: "stave/5/1.svg",
        cname: "八分休止符",
        width: 60,
        height: 60,
      },
      {
        path: "stave/5/4.svg",
        cname: "十六分休止符",
        width: 90,
        height: 90,
      },
      {
        path: "stave/5/7.svg",
        cname: "三十二分休止符",
        width: 140,
        height: 140,
      },
      {
        path: "stave/3/2.svg",
        cname: "上两个八分音符",
        width: 200,
        height: 200,
      },
      {
        path: "stave/3/7.svg",
        cname: "下两个八分音符",
        width: 200,
        height: 200,
      },
      {
        path: "stave/3/3.svg",
        cname: "上三连音",
        width: 200,
        height: 200,
      },
      {
        path: "stave/3/8.svg",
        cname: "下三连音",
        width: 200,
        height: 200,
      },
      {
        path: "stave/3/11.svg",
        cname: "上三连音",
        width: 200,
        height: 200,
      },
      {
        path: "stave/3/12.svg",
        cname: "下三连音",
        width: 200,
        height: 200,
      },
      {
        path: "stave/3/1.svg",
        cname: "上附点八分音符加一个十六",
        width: 200,
        height: 200,
      },

      {
        path: "stave/3/6.svg",
        cname: "下附点八分音符加一个十六",
        width: 200,
        height: 200,
      },
      {
        path: "stave/3/4.svg",
        cname: "上十六分音符加附点八分音符",
        width: 200,
        height: 200,
      },
      {
        path: "stave/3/9.svg",
        cname: "下十六分音符加附点八分音符",
        width: 200,
        height: 200,
      },

      {
        path: "stave/3/5.svg",
        cname: "上小切分",
        width: 200,
        height: 200,
      },
      {
        path: "stave/3/10.svg",
        cname: "下小切分",
        width: 200,
        height: 200,
      },
      {
        path: "stave/4/1.svg",
        cname: "上两个十六分音符",
        width: 200,
        height: 200,
      },
      {
        path: "stave/4/5.svg",
        cname: "下两个十六分音符",
        width: 200,
        height: 200,
      },

      {
        path: "stave/4/4.svg",
        cname: "上四个十六分音符",
        width: 200,
        height: 200,
      },

      {
        path: "stave/4/8.svg",
        cname: "下四个十六分音符",
        width: 200,
        height: 200,
      },
      {
        path: "stave/4/2.svg",
        cname: "上前八后十六",
        width: 175,
        height: 175,
      },
      {
        path: "stave/4/6.svg",
        cname: "下前八后十六",
        width: 175,
        height: 175,
      },
      {
        path: "stave/4/3.svg",
        cname: "上前十六后八",
        width: 175,
        height: 175,
      },
      {
        path: "stave/4/7.svg",
        cname: "下前十六后八",
        width: 175,
        height: 175,
      },
      {
        path: "stave/5/6.svg",
        cname: "跳音或附点",
        width: 12,
        height: 12,
      },
      {
        path: "stave/6/1.svg",
        cname: "",
        width: 280,
        height: 120,
      },
      {
        path: "stave/6/2.svg",
        cname: "",
        width: 320,
        height: 120,
      },
      {
        path: "stave/6/3.svg",
        cname: "",
        width: 180,
        height: 120,
      },
      {
        path: "stave/6/4.svg",
        cname: "",
        width: 200,
        height: 120,
      },
      {
        path: "stave/6/5.svg",
        cname: "",
        width: 160,
        height: 120,
      },
      {
        path: "stave/6/6.svg",
        cname: "",
        width: 180,
        height: 120,
      },
      {
        path: "note/7/1.svg",
        cname: "2/4",
        width: 180,
        height: 180,
      },
      {
        path: "note/7/2.svg",
        cname: "3/4",
        width: 180,
        height: 180,
      },
      {
        path: "note/7/4.svg",
        cname: "4/4",
        width: 180,
        height: 180,
      },
      {
        path: "note/7/5.svg",
        cname: "5/4",
        width: 180,
        height: 180,
      },
      {
        path: "note/7/3.svg",
        cname: "3/8",
        width: 180,
        height: 180,
      },
      {
        path: "note/7/6.svg",
        cname: "6/8",
        width: 180,
        height: 180,
      },
      {
        path: "note/7/7.svg",
        cname: "7/8",
        width: 180,
        height: 180,
      },
    ],
  },
  {
    id: "note",
    path: "note",
    cname: "简谱类",
    children: [
      {
        path: "note/6/0.svg",
        cname: "0",
        width: 80,
        height: 80,
      },
      {
        path: "note/6/1.svg",
        cname: "1",
        width: 80,
        height: 80,
      },
      {
        path: "note/6/2.svg",
        cname: "2",
        width: 80,
        height: 80,
      },
      {
        path: "note/6/3.svg",
        cname: "3",
        width: 80,
        height: 80,
      },
      {
        path: "note/6/4.svg",
        cname: "4",
        width: 80,
        height: 80,
      },
      {
        path: "note/6/5.svg",
        cname: "5",
        width: 80,
        height: 80,
      },
      {
        path: "note/6/6.svg",
        cname: "6",
        width: 80,
        height: 80,
      },
      {
        path: "note/6/7.svg",
        cname: "7",
        width: 80,
        height: 80,
      },
      {
        path: "note/6/13.svg",
        cname: "",
        width: 283,
        height: 80,
      },
      {
        path: "note/6/12.svg",
        cname: "",
        width: 221,
        height: 80,
      },
      {
        path: "note/6/11.svg",
        cname: "",
        width: 154,
        height: 80,
      },
      {
        path: "note/6/8.svg",
        cname: "叉号",
        width: 120,
        height: 120,
      },
      {
        path: "note/6/14.svg",
        cname: "",
        width: 56,
        height: 80,
      },
      {
        path: "note/6/15.svg",
        cname: "",
        width: 45,
        height: 80,
      },
      {
        path: "note/6/16.svg",
        cname: "",
        width: 97,
        height: 80,
      },
      {
        path: "note/6/17.svg",
        cname: "",
        width: 165,
        height: 80,
      },
      {
        path: "note/6/18.svg",
        cname: "",
        width: 126,
        height: 80,
      },
      {
        path: "note/6/19.svg",
        cname: "",
        width: 126,
        height: 80,
      },
      {
        path: "note/6/20.svg",
        cname: "",
        width: 130,
        height: 120,
      },
      {
        path: "note/6/21.svg",
        cname: "",
        width: 86,
        height: 80,
      },
      {
        path: "note/6/22.svg",
        cname: "",
        width: 86,
        height: 80,
      },
      {
        path: "note/6/23.svg",
        cname: "",
        width: 134,
        height: 80,
      },
      {
        path: "note/6/24.svg",
        cname: "",
        width: 134,
        height: 80,
      },
      {
        path: "note/6/9.svg",
        cname: "-",
        width: 160,
        height: 6,
      },
      {
        path: "note/6/10.svg",
        cname: "——",
        width: 8,
        height: 8,
      },
      {
        path: "stave/5/6.svg",
        cname: "跳音或附点",
        width: 12,
        height: 12,
      },
      {
        path: "note/6/25.svg",
        cname: "",
        width: 30,
        height: 50,
      },
      {
        path: "note/6/26.svg",
        cname: "",
        width: 30,
        height: 50,
      },
      {
        path: "note/6/27.svg",
        cname: "",
        width: 30,
        height: 50,
      },
      {
        path: "note/6/28.svg",
        cname: "",
        width: 30,
        height: 50,
      },
      {
        path: "note/6/29.svg",
        cname: "",
        width: 30,
        height: 50,
      },
      {
        path: "note/6/30.svg",
        cname: "",
        width: 30,
        height: 50,
      },
      {
        path: "note/6/31.svg",
        cname: "",
        width: 30,
        height: 50,
      },
    ],
  },
  {
    id: "symbol",
    path: "symbol",
    cname: "符号类",
    children: [
      {
        path: "symbol/8/2.svg",
        cname: "反复记号",
        width: 40,
        height: 40,
      },
      {
        path: "symbol/8/3.svg",
        cname: "小节线",
        width: 100,
        height: 190,
      },
      {
        path: "symbol/8/1.svg",
        cname: "段落线",
        width: 100,
        height: 190,
      },
      {
        path: "symbol/8/4.svg",
        cname: "终止线",
        width: 100,
        height: 190,
      },
      {
        path: "symbol/8/5.svg",
        cname: "",
        width: 80,
        height: 130,
      },
      {
        path: "symbol/8/6.svg",
        cname: "",
        width: 80,
        height: 130,
      },
      {
        path: "symbol/8/7.svg",
        cname: "",
        width: 110,
        height: 130,
      },
      {
        path: "symbol/8/8.svg",
        cname: "",
        width: 130,
        height: 60,
      },
      {
        path: "symbol/8/9.svg",
        cname: "",
        width: 130,
        height: 60,
      },
      {
        path: "symbol/10/1.svg",
        cname: "1反复跳跃记号",
        width: 150,
        height: 150,
      },
      {
        path: "symbol/10/2.svg",
        cname: "2反复跳跃记号",
        width: 150,
        height: 150,
      },
      {
        path: "symbol/9/1.svg",
        cname: "还原记号",
        width: 45,
        height: 45,
      },
      {
        path: "symbol/9/2.svg",
        cname: "降记号",
        width: 45,
        height: 45,
      },
      {
        path: "symbol/9/3.svg",
        cname: "升记号",
        width: 45,
        height: 45,
      },
      {
        path: "symbol/9/4.svg",
        cname: "重降记号",
        width: 85,
        height: 85,
      },
      {
        path: "symbol/9/5.svg",
        cname: "重升记号",
        width: 35,
        height: 35,
      },
      {
        path: "symbol/10/3.svg",
        cname: "上连线",
        width: 25,
        height: 25,
      },
      {
        path: "symbol/10/4.svg",
        cname: "下连线",
        width: 25,
        height: 25,
      },
      {
        path: "symbol/8/10.svg",
        cname: "",
        width: 80,
        height: 50,
      },
      {
        path: "symbol/8/11.svg",
        cname: "",
        width: 130,
        height: 60,
      },
      {
        path: "symbol/8/12.svg",
        cname: "",
        width: 130,
        height: 60,
      },
      {
        path: "symbol/8/13.svg",
        cname: "",
        width: 11,
        height: 5,
      },
      {
        path: "symbol/8/14.svg",
        cname: "",
        width: 130,
        height: 60,
      },
      {
        path: "symbol/12/1.svg",
        cname: "重音记号",
        width: 20,
        height: 20,
      },
      {
        path: "symbol/11/3.svg",
        cname: "渐强",
        width: 85,
        height: 85,
      },
      {
        path: "symbol/11/4.svg",
        cname: "渐弱",
        width: 85,
        height: 85,
      },
      {
        path: "symbol/11/9.svg",
        cname: "cresc",
        width: 25,
        height: 25,
      },
      {
        path: "symbol/11/10.svg",
        cname: "dim",
        width: 38,
        height: 38,
      },
      {
        path: "symbol/11/1.svg",
        cname: "很强",
        width: 45,
        height: 45,
      },
      {
        path: "symbol/11/2.svg",
        cname: "很弱",
        width: 35,
        height: 35,
      },
      {
        path: "symbol/11/7.svg",
        cname: "中强",
        width: 45,
        height: 45,
      },
      {
        path: "symbol/11/8.svg",
        cname: "中弱",
        width: 35,
        height: 35,
      },
      {
        path: "symbol/11/5.svg",
        cname: "强",
        width: 45,
        height: 45,
      },
      {
        path: "symbol/11/6.svg",
        cname: "弱",
        width: 35,
        height: 35,
      },
      {
        path: "symbol/13/3.svg",
        cname: "C",
        width: 100,
        height: 100,
      },
      {
        path: "symbol/13/4.svg",
        cname: "D",
        width: 150,
        height: 150,
      },
      {
        path: "symbol/13/5.svg",
        cname: "E",
        width: 150,
        height: 150,
      },
      {
        path: "symbol/13/6.svg",
        cname: "F",
        width: 150,
        height: 150,
      },
      {
        path: "symbol/13/7.svg",
        cname: "G",
        width: 150,
        height: 150,
      },
      {
        path: "symbol/13/1.svg",
        cname: "A",
        width: 150,
        height: 150,
      },
      {
        path: "symbol/13/2.svg",
        cname: "B",
        width: 150,
        height: 150,
      },
      {
        path: "symbol/13/8.svg",
        cname: "Do",
        width: 150,
        height: 150,
      },
      {
        path: "symbol/13/9.svg",
        cname: "Re",
        width: 150,
        height: 150,
      },
      {
        path: "symbol/13/10.svg",
        cname: "Mi",
        width: 150,
        height: 150,
      },
      {
        path: "symbol/13/11.svg",
        cname: "Fa",
        width: 150,
        height: 150,
      },
      {
        path: "symbol/13/12.svg",
        cname: "Sol",
        width: 150,
        height: 150,
      },
      {
        path: "symbol/13/13.svg",
        cname: "La",
        width: 150,
        height: 150,
      },
      {
        path: "symbol/13/14.svg",
        cname: "Si",
        width: 150,
        height: 150,
      },
    ],
  },
  {
    id: "action",
    path: "action",
    cname: "动作指令",
    children: [
      {
        path: "action/1.svg",
        cname: "弹舌",
        width: 120,
        height: 120,
      },
      {
        path: "action/2.svg",
        cname: "跺脚",
        width: 120,
        height: 120,
      },
      {
        path: "action/3.svg",
        cname: "捻指",
        width: 120,
        height: 120,
      },
      {
        path: "action/4.svg",
        cname: "拍肩",
        width: 120,
        height: 120,
      },
      {
        path: "action/5.svg",
        cname: "拍手",
        width: 120,
        height: 120,
      },
      {
        path: "action/6.svg",
        cname: "拍腿",
        width: 120,
        height: 120,
      },
      {
        path: "action/7.svg",
        cname: "拍胸",
        width: 120,
        height: 120,
      },
    ],
  },
];
