const env = process.env.VUE_APP_ENV;

const serveApiConfig = (function (env) {
  const config = {
    dev: {
      baseUrl: '',
    },
    test: {
      // baseUrl: 'http://test-api.tob.yinweiart.com',
      baseUrl: '',
    },
    pre: {
      baseUrl: '',
    },
    prod: {
      baseUrl: '',
    },
  };
  return config[env] || config['dev'];
})(env);

export default serveApiConfig;
