import serviceApi from '@/api/serivce'
import Cookies from 'js-cookie'

export default {
  namespaced: true,
  state: {
    reportsList: [],
  },
  mutations: {
    setReportsList(state, payload = []) {
      state.reportsList = payload
    },
  },
  actions: {
    async getReportsList({ commit }, params) {
      const res = await serviceApi.getReportsList(params).then(res => {
        // debugger
        return res
      }).catch(err => {
        // debugger
        return Promise.reject(err)
      })
      commit('setReportsList', res?.result || []);
      return res
    },
  },
}
