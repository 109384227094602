<template>
  <div class="wrapper">
    <router-view />
    <!--    <router-view v-slot="{ Component }">
      <template v-if="!!$route.meta.disableCache">
        <component :is="Component" />
      </template>
      <keep-alive v-else>
        <component :is="Component" />
      </keep-alive>
    </router-view>-->
  </div>
  <Toolbox v-if="ishow" />
</template>

<script>
import { watch, ref } from "vue";
import { useRoute } from "vue-router";
import Toolbox from "@/views/tools/toolbox.vue";
export default {
  name: "App",
  components: {
    Toolbox,
  },
  setup() {
    const Route = useRoute();
    // 白板的相关的样式文件页面，不需要路由的配置检测
    const whitePath = {
      "/bcw": true,
      "/detail": false,
      "/ccw": true,
      "/pc": true,
      "/cr": true,
      "/crd": true,
      "/musicVideo": true,
      "/comment": true,
    };

    const ishow = ref(!!whitePath[Route.path]);

    watch(Route, ({ path }) => {
      let scale = bodyScale();
      ishow.value = !!whitePath[Route.path];
      if (
        path == "/Home" ||
        path == "/" ||
        path == "/whiteboard" ||
        path == "/login" ||
        path == "/bcw" ||
        path == "/detail" ||
        path == "/ccw" ||
        path == "/pc" ||
        path == "/cr" ||
        path == "/crd" ||
        path == "/musicVideo" ||
        path == "/comment" || 
        path == "/detailSpectrum" ||
        path == "/spectrum" 
      ) {
        document.body.style.zoom = 1;
      } else {
        document.body.style.zoom = scale;
      }
    });
    return { ishow };
  },
  // setup() {
  //   const {state: { account }, dispatch} = useStore();
  //   const token = Cookies.get('systemToken');
  //   const {testData} = toRefs(account);
  //   if (token) {
  //     // 获取用户信息 userinfo
  //   } else {
  //     // 获取token
  //     const params = {
  //       username: '122330001',
  //       password: md5('330001'),
  //       code: '',
  //       phone: '18434306787',
  //
  //       client_id: 'teacher',
  //       client_secret: '654321',
  //       grant_type: 'password'
  //     }
  //     dispatch('account/getToken', params).then(res => {
  //       debugger
  //     });
  //   }
  //   return reactive({
  //     testData,
  //   })
  // },
};
function bodyScale() {
  var devicewidth = document.documentElement.clientWidth; //获取当前分辨率下的可是区域宽度
  var scale = devicewidth / 1920; // 分母——设计稿的尺寸
  return scale;
}
</script>
<style>
.wrapper {
  width: 100%;
  height: 100%;
}
.el-overlay-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-dialog {
  margin: 0 !important;
}
</style>
