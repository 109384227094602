<template>
  <div class="music-culture-container music-reading">
    <div class="content-header">
      <div class="title-type">
        <span class="title">题型:</span>
        <el-radio-group v-model="audioGroup">
          <el-radio value="sing">唱名</el-radio>
          <el-radio value="music">音名</el-radio>
          <!-- <el-radio value="琴键"></el-radio> -->
        </el-radio-group>
      </div>
      <div class="music-type">
        <span class="title">曲谱:</span>
        <el-radio-group v-model="musicType">
          <el-radio value="staff">五线谱</el-radio>
          <el-radio value="numbered">简谱</el-radio>
        </el-radio-group>
      </div>
      <div class="pitch-type">
        <span class="title">谱表：</span>
        <el-radio-group v-model="staffType">
          <el-radio value="treble">高音</el-radio>
          <el-radio value="bass">低音</el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="song">
      <span>下图曲谱所对应的唱名是什么？</span>
      <div class="song-image" id="songSvg" v-if="musicType === 'staff'">
        <svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"
          xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 876.4 238.2"
          style="enable-background:new 0 0 876.4 238.2;" xml:space="preserve">
          <svg:style type="text/css">
            .st0 {
            fill: none;
            stroke: #000000;
            stroke-width: 2;
            stroke-linejoin: bevel;
            }

            .st1 {
            fill: none;
            stroke: #000000;
            stroke-width: 4;
            stroke-linejoin: bevel;
            }

            .st2 {
            fill: none;
            stroke: #000000;
            stroke-width: 12.5;
            stroke-linejoin: bevel;
            }

            .st3 {
            fill: none;
            stroke: #000000;
            stroke-width: 4;
            stroke-linecap: square;
            stroke-linejoin: bevel;
            }
          </svg:style>
          <title>未命名</title>
          <desc>Generated by MuseScore 3.5.0</desc>
          <line class="st0" x1="33.2" y1="68.2" x2="827.4" y2="68.2" />
          <line class="st0" x1="33.2" y1="93.2" x2="827.4" y2="93.2" />
          <line class="st0" x1="33.2" y1="118.2" x2="827.4" y2="118.2" />
          <line class="st0" x1="33.2" y1="143.2" x2="827.4" y2="143.2" />
          <line class="st0" x1="33.2" y1="168.2" x2="827.4" y2="168.2" />
          <line class="st1" x1="802.9" y1="67.2" x2="802.9" y2="169.2" />
          <line class="st2" x1="821.2" y1="67.2" x2="821.2" y2="169.2" />
          <line v-if="pathmus1Transform === 0" class="st3" x1="205.6" y1="193.2" x2="270.1" y2="193.2" />
          <line v-if="pathmus2Transform === 23" class="st3" x1="493.6" y1="193.2" x2="558.1" y2="193.2" />
          <path :transform="`translate(0, ${pathmus1Transform})`" class="mus1" d="M234.6,181.9c-2.3,0-4,0.8-5.1,2.4c-1.2,1.6-1.8,3.7-1.8,6.4c0,3.1,0.8,5.7,2.4,7.9c1.6,2.1,3.4,3.6,5.4,4.5
      c1.8,0.8,3.6,1.3,5.6,1.3c2.3,0,4-0.8,5.2-2.4c1.1-1.6,1.7-3.8,1.7-6.4c0-3.1-0.8-5.7-2.4-7.9c-1.6-2.1-3.4-3.6-5.3-4.5
      C238.4,182.4,236.5,181.9,234.6,181.9 M237.9,179.4c7,0,12.9,1.4,17.6,4.1c4.8,2.7,7.1,6,7.1,9.7c0,4-2.4,7.2-7.3,9.8
      c-4.9,2.7-10.7,4-17.4,4c-6.9,0-12.8-1.4-17.6-4.1c-4.8-2.7-7.2-6-7.2-9.7c0-4,2.5-7.2,7.4-9.8C225.4,180.7,231.2,179.4,237.9,179.4
      " />
          <rect class="mus1" @click="handlerPathActive('mus1')" width="100" height="60" x="190.6" y="161.9" fill="red"
            opacity="0" :transform="`translate(0, ${pathmus1Transform})`">
          </rect>
          <path class="mus2" :transform="`translate(0, ${pathmus2Transform})`"
            d="M524.4,156.9c-2.3,0-4,0.8-5.1,2.4c-1.2,1.6-1.8,3.7-1.8,6.4c0,3.1,0.8,5.7,2.4,7.9c1.6,2.1,3.4,3.6,5.4,4.5
      c1.8,0.8,3.6,1.3,5.6,1.3c2.3,0,4-0.8,5.2-2.4c1.1-1.6,1.7-3.8,1.7-6.4c0-3.1-0.8-5.7-2.4-7.9c-1.6-2.1-3.4-3.6-5.3-4.5
      C528.1,157.4,526.2,156.9,524.4,156.9 M527.7,154.4c7,0,12.9,1.4,17.6,4.1c4.8,2.7,7.1,6,7.1,9.7c0,4-2.4,7.2-7.3,9.8
      c-4.9,2.7-10.7,4-17.4,4c-6.9,0-12.8-1.4-17.6-4.1c-4.8-2.7-7.2-6-7.2-9.7c0-4,2.5-7.2,7.4-9.8C515.2,155.7,521,154.4,527.7,154.4" />
          <rect class="mus2" @click="handlerPathActive('mus2')" width="100" height="60" x="474.4" y="135.9" fill="red"
            opacity="0" :transform="`translate(0, ${pathmus2Transform})`">
          </rect>
          <path v-if="staffType === 'treble'" d="M89.9,117.6c0,0,0.2,0,0.6,0c0.3-0.1,0.5-0.1,0.7-0.1c7.3,0,13.3,2.6,18,7.7c4.7,5.1,7,11,7,17.8c0,8.4-3.5,15.2-10.4,20.5
      c-1.8,1.4-4.2,2.6-7.1,3.5c0.3,4.2,0.5,7.6,0.5,10.3c0,0.5-0.1,2.4-0.2,5.7c-0.3,6.1-2.3,11.3-6.3,15.7c-3.8,4.4-8.5,6.5-14.1,6.5
      c-5.3,0-9.8-1.9-13.5-5.6c-3.7-3.8-5.6-8.3-5.6-13.7c0-2.8,1.1-5.2,3.2-7.1c2.2-1.9,4.8-2.9,7.7-2.9c2.6,0,4.8,1,6.5,3
      c1.8,1.9,2.7,4.2,2.7,7c0,2.5-0.9,4.7-2.7,6.5s-4,2.7-6.5,2.7c-1,0-2-0.2-3-0.6c2.9,4.2,6.7,6.3,11.4,6.3c4.5,0,8.2-1.8,11-5.5
      c3-3.8,4.6-8.2,4.9-13.2c0.1-3.1,0.2-4.9,0.2-5.4c0-3.6-0.1-6.6-0.4-9c-3.3,0.5-6.1,0.8-8.7,0.8c-9.1,0-16.8-3.6-23.1-10.8
      c-6.3-7.2-9.4-15.8-9.4-25.8c0-2.1,0.2-4.2,0.6-6.3c0.4-1.8,0.9-3.7,1.5-5.8c0.3-1.2,1.2-3.1,2.6-5.7c0.5-0.8,1.1-1.8,1.8-2.9
      s1.1-2,1.4-2.3c0.6-1.3,1.9-3.1,3.8-5.3c0.5-0.6,1.1-1.2,1.6-1.9c0.6-0.7,1-1.3,1.4-1.8c0.4-0.5,0.7-0.8,0.9-1.1
      c0.2-0.3,1.7-2,4.4-5.2c0.6-0.7,1.3-1.4,1.9-2.1c0.6-0.7,1.1-1.3,1.5-1.8c0.4-0.4,0.7-0.7,0.9-0.9c-2.3-7.8-3.8-13.9-4.4-18.6
      c-0.4-3.7-0.7-8.8-0.7-15.2c0-5.7,1.3-11,3.9-15.9c2.5-5.1,6.1-9.3,10.6-12.6c0.3-0.2,0.6-0.3,1-0.3c0.5,0,0.8,0.1,1,0.3
      c3.5,4.2,6.5,9.7,9.1,16.5c2.6,6.8,3.9,12.9,3.9,18.5c0,6.3-1.5,12.1-4.5,17.6c-3.4,5.8-7.8,11.8-13.1,18
      C85.8,101.7,87.6,108.4,89.9,117.6 M98.2,163c3.4-1.2,6-3.4,7.9-6.3c2.1-2.9,3.1-6.1,3.1-9.7c0-4.6-1.6-8.7-4.7-12.3
      c-3.2-3.7-7.3-5.8-12.2-6.3C95.1,142.2,97.1,153.7,98.2,163 M60.4,140.4c0,6.4,2.6,12.1,7.8,17c5.3,4.8,11.3,7.1,18,7.1
      c2.7,0,5.4-0.2,8-0.6c-1-9.9-3.1-21.7-6.2-35.5c-3.7,0.4-6.7,1.7-8.9,3.9c-2.2,2.3-3.2,4.9-3.2,7.7c0,4.9,2.6,9,7.9,12
      c0.4,0.5,0.7,0.9,0.7,1.5c0,0.5-0.2,1-0.7,1.5c-0.4,0.5-0.9,0.7-1.5,0.7c-0.1,0-0.4-0.1-0.9-0.2c-3.7-2-6.6-4.6-8.6-7.8
      c-1.9-3.4-2.8-6.8-2.8-10.3c0-4.5,1.4-8.6,4.2-12.2c2.9-3.6,6.7-6,11.4-7c-1.8-7.3-3.3-13-4.5-17.1c-1,1.2-2.3,2.6-3.8,4.3
      c-1.5,1.7-2.3,2.7-2.6,3c-2.3,3.1-4.1,5.4-5.4,7.1c-2.2,3.1-3.8,5.7-4.9,7.9c-1.1,2.5-2.1,5.2-2.9,8.1
      C60.8,134.3,60.4,137.3,60.4,140.4 M93,41.1c-4.9,2.4-8.7,5.9-11.5,10.3c-2.9,4.6-4.3,9.6-4.3,14.9c0,4.7,1.2,11,3.5,19
      c4.7-5.7,8.2-10.9,10.5-15.7c2.5-5.1,3.7-10.4,3.7-15.9C95,49.6,94.3,45.4,93,41.1" />
          <path v-else
            d="m78.15509,69c-7.55,0 -13.59,4.36 -13.7,4.36c-5.75,4.26 -8.03,9.33 -8.93,12.8c-0.5,1.78 -0.68,3.56 -0.68,5.25c0,2.39 0.39,4.56 1.29,6.45c1.19,2.68 3.47,4.56 6.45,5.55c1.19,0.4 2.48,0.6 3.77,0.6c2.19,0 4.46,-0.6 6.36,-1.69c3.07,-1.69 4.96,-5.25 4.96,-9.13c0,-3.47 -1.8,-6.65 -4.77,-8.53c-2.08,-1.39 -4.06,-1.78 -5.75,-1.78c-1.19,0 -2.28,0.19 -3.18,0.5c-0.99,0.39 -2.67,1.38 -3.47,2.08c0.31,-1.89 1.49,-4.76 2.59,-6.36c1.09,-1.58 3.27,-3.56 4.96,-4.46c2.48,-1.38 5.66,-1.98 9.13,-1.98c10.12,0 15.08,8.14 15.08,24.8c0,9.24 -3.47,18.06 -6.45,23.92c-3.29,6.54 -7.64,12.8 -11.52,16.28c-10.32,9.42 -20.04,13.89 -20.13,13.89c0,0 -1.49,0.88 -1.1,2.37c0.31,0.99 1.19,1.19 1.8,1.19c0.09,0 0.39,-0.09 0.5,-0.09c0.09,0 11.41,-4.67 20.43,-11.72c5.07,-3.66 9.63,-7.63 13.59,-11.9c3.57,-3.86 6.65,-8.03 9.13,-12.2c4.36,-7.44 6.84,-15.17 6.84,-21.82c0,-8.23 -2.17,-15.08 -6.25,-19.93c-4.69,-5.58 -11.73,-8.45 -20.95,-8.45m31.14,12.59c0,2.79 2.19,4.96 4.77,4.96c2.57,0 4.76,-2.17 4.76,-4.96c0,-2.77 -2.19,-5.05 -4.76,-5.05c-2.58,-0.01 -4.77,2.27 -4.77,5.05m0,24.5c0,2.77 2.19,5.07 4.77,5.07c2.57,0 4.76,-2.29 4.76,-5.07c0,-2.77 -2.19,-5.05 -4.76,-5.05c-2.58,0 -4.77,2.28 -4.77,5.05" />
        </svg>
      </div>
      <div class="numbered-score" v-else>
        <el-radio-group v-model="keySignature">
          <el-radio value="C">C</el-radio>
          <!--<el-radio value="F">F</el-radio>
                              <el-radio value="G">G</el-radio>-->
        </el-radio-group>
        <div class="numbered-notes" :class="staffType">
          <span>{{ firstNote }}</span>
          <span>{{ secondNote }}</span>
        </div>
      </div>
      <div class="song-btn" @click="handlerSong">
        <div class="mus-list">
          <span v-for="(item, index) in noteList" :key="item.val" 
            :data-value="item.id1 + ':' + item.id2"
            :data-music="item.music" :data-name="item.val" :data-hash="item.hash">{{
          audioGroup === 'sing' ? item.val : pitchMap[keySignature].pitchs[item.origIndex]
        }}</span>
        </div>
      </div>

      <div class="answer">
        <div v-if="activeNum === 2">
          <div>正确答案:{{ innerStr }}</div>
          <div>你的正确率:{{ innerCorrect }}%</div>
        </div>
      </div>
    </div>
    <div class="reset" @click="resetMusic">
      <el-button type="primary" size="large">再来一题</el-button>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, watch, nextTick, onBeforeUnmount } from 'vue';
import soundAudio from '@/utils/soundAudio';
import { useStore } from 'vuex';

const pitchMap = {
  C: { pitchs: ['C', 'D', 'E', 'F', 'G', 'A', 'B'], diff: 0 },
  F: { pitchs: ['bC', 'D', 'E', 'F', 'G', 'A', 'B'], diff: 3 },
  G: { pitchs: ['C', 'D', 'E', '#F', 'G', 'A', 'B'], diff: 4 },
};

const audioUrl = 'https://yw-tob-teacher.oss-cn-beijing.aliyuncs.com/assets/samples/piano/';

export default defineComponent({
  setup(_, { emit }) {
    const store = useStore();
    let svgContainer = null,
      svg = null,
      pathList = [],
      rectList = [];
    // 初始位移  根据svg高度计算 间隔13
    let pathmus1Transform = ref(0);
    let pathmus2Transform = ref(0);
    let activeNum = ref(0);
    let innerStr = ref('');
    let innerCorrect = ref(100);
    let audioGroup = ref('sing');
    let musicType = ref(store.state.account.schoolInfo.scoreType === 2 ? 'staff' : 'numbered');
    let keySignature = ref('C');
    let firstNote = ref(1);
    let secondNote = ref(1);
    let staffType = ref('treble');

    const markPathY = [0, -10, -23, -36, -49, -62, -75, -88, -101, -112, -124, -136];
    const markTwoPathY = [23, 10, 0, -13, -26, -39, -52, -64, -76, -88, -101, -112];
    const musicList = {
      treble: [
        {
          id1: 0,
          id2: 23,
          val: 'Do',
          music: 'C',
        },
        {
          id1: -10,
          id2: 10,
          val: 'Re',
          music: 'D',
        },
        {
          id1: -23,
          id2: 0,
          val: 'Mi',
          music: 'E',
        },
        {
          id1: -36,
          id2: -13,
          val: 'Fa',
          music: 'F',
        },
        {
          id1: -49,
          id2: -26,
          val: 'Sol',
          music: 'G',
        },
        {
          id1: -62,
          id2: -39,
          val: 'La',
          music: 'A',
        },
        {
          id1: -75,
          id2: -52,
          val: 'Si',
          music: 'B',
        },
      ],
      bass: [
        {
          id1: -62,
          id2: -39,
          val: 'Do',
          music: 'C',
        },
        {
          id1: -75,
          id2: -52,
          val: 'Re',
          music: 'D',
        },
        {
          id1: -88,
          id2: -64,
          val: 'Mi',
          music: 'E',
        },
        {
          id1: -101,
          id2: -76,
          val: 'Fa',
          music: 'F',
        },
        {
          id1: -112,
          id2: -88,
          val: 'Sol',
          music: 'G',
        },
        {
          id1: -124,
          id2: -101,
          val: 'La',
          music: 'A',
        },
        {
          id1: -136,
          id2: -112,
          val: 'Si',
          music: 'B',
        }
      ]
    };

    const noteList = ref([]);
    

    watch(musicType, (val) => {
      if (val === 'staff') {
        keySignature.value = 'C';
        nextTick(() => {
          refreshStaff();
        })
      }
    });

    watch(staffType, val => {
      if (musicType.value === 'staff') {
        keySignature.value = 'C';
        nextTick(() => {
          refreshStaff();
        })
      }
    })

    onBeforeUnmount(() => {
      // 关闭音乐
      soundAudio.removeAudio();
    });

    const handlerSong = e => {
      if (activeNum.value < 2) {
        if (e.target.nodeName.toLowerCase() === 'span' && e.target.classList?.contains('active')) {
          clearActive();
          return;
        }
      }
      if (e.target.nodeName.toLowerCase() === 'span') {
        if (activeNum.value < 2) {
          e.target.classList.add('active');
          activeNum.value++;
          const iNode = document.createElement('i');
          iNode.textContent = activeNum.value;
          iNode.classList.add('active-num');
          e.target.appendChild(iNode);
          // 对比
          contrastCorrect(e.target.dataset?.value, activeNum.value);
        }
        let name = e.target.dataset?.music;
        name += staffType.value === 'bass' ? 3 : 4;
        // audio播放
        soundAudio.addAudio(name, e.target.dataset?.hash, { audioUrl });
      }
    }
    const contrastCorrect = (value, num) => {
      let firstValue;
      let secondValue;
      const list = musicList[staffType.value];
      if (musicType.value === 'staff') {
        firstValue = pathmus1Transform.value;
        secondValue = pathmus2Transform.value;
      } else {
        let one = (firstNote.value + parseInt(pitchMap[keySignature.value].diff)) % 8;
        let two = (secondNote.value + parseInt(pitchMap[keySignature.value].diff)) % 8;
        firstValue = list[one - 1].id1;
        secondValue = list[two - 1].id2;
      }
      if (num === 1) {
        if (value.split(':').indexOf(firstValue.toString()) === -1) {
          // 第一次为错
          innerCorrect.value /= 2;
        }
      } else if (num === 2) {
        // 获取正确答案 只获取一次
        const correctIndex = list.findIndex(item => item.id1 === firstValue);
        const correctTwoIndex = list.findIndex(item => item.id2 === secondValue);
        innerStr.value = `${audioGroup.value === 'sing' ? list[correctIndex].val : list[correctIndex].music}    ${audioGroup.value === 'sing' ? list[correctTwoIndex].val : list[correctTwoIndex].music}`;
        if (value.split(':').indexOf(secondValue.toString()) === -1) {
          innerCorrect.value -= 50;
        }
      }
    }

    const generateNumbered = () => {
      firstNote.value = 1;
      secondNote.value = 1;
      while (firstNote.value === secondNote.value) {
        firstNote.value = parseInt(Math.random() * 8) || 1;
        secondNote.value = parseInt(Math.random() * 8) || 1;
      }
    }

    const refreshStaff = () => {
      svgContainer = document.getElementById('songSvg');
      svg = svgContainer.querySelector('svg');
      pathList = svg.querySelectorAll('path[transform]');
      rectList = svg.querySelectorAll('rect[transform]');
      if (svg && pathList && pathList.length) {
        let indexOne = firstNote.value - 1;
        let indexTwo = secondNote.value - 1;

        pathList.forEach(item => {
          if (indexOne === indexTwo) {
            if (indexOne + 1 === 7) --indexOne;
            else ++indexOne;
          }
          if (item.classList?.length && item.classList[0] === 'mus1') {
            if (staffType.value === 'bass') {
              indexOne += 5;
            }
            pathmus1Transform.value = markPathY[indexOne];
          } else {
            if (staffType.value === 'bass') {
              indexTwo += 5;
            }
            pathmus2Transform.value = markTwoPathY[indexTwo];
          }
        })
      }
    }

    const resetMusic = () => {
      generateNumbered();

      if (musicType.value === 'staff') {
        refreshStaff();
      }
      clearActive();
      musicList[staffType.value].forEach((one, index) => {
        one.origIndex = index; 
      });
      noteList.value = [...musicList[staffType.value]].sort(() => Math.random() - 0.5);
    }
    const clearActive = () => {
      activeNum.value = 0;
      innerCorrect.value = 100;
      const spanList = [...document.getElementsByClassName('song-btn')[0]?.getElementsByTagName('span')]
      spanList.forEach(item => {
        item.classList.remove('active');
        const iNode = item.getElementsByTagName('i')[0];
        if (iNode) {
          item.removeChild(iNode);
        }
      })
    }
    const handlerPathActive = val => {
      rectList.forEach(item => {
        item.setAttribute('opacity', 0);
        if (item.classList.length && item.classList[0] === val) {
          item.setAttribute('opacity', 0.33);
        }
      })
    }
    onMounted(() => {
      resetMusic();
    })
    return {
      handlerSong,
      resetMusic,
      pathmus1Transform,
      pathmus2Transform,
      handlerPathActive,
      innerCorrect,
      innerStr,
      activeNum,
      audioGroup,
      musicType,
      keySignature,
      firstNote,
      secondNote,
      pitchMap,
      staffType,
      noteList,
    }
  },
})
</script>
<style scoped lang="scss" src="./style.scss"></style>
<style scoped lang="scss">
.music-culture-container {
  height: 100%;
  .song {
    width: 100%;
    position: relative;
    height: calc(88% - 90px);
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    align-items: center;

    span {
      &:first-child(1) {
        font-size: 40px;
      }
    }

    .song-image {
      width: 100%;
      height: 45%;
      margin-top: 10px;
    }

    .numbered-score {
      height: 45%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      .el-radio-group {
        line-height: 1;
        margin: 0 0 20px;
        text-align: center;
      }

      .numbered-notes {
        font-size: 40px;
        text-align: center;

        span {
          padding: 0 40px;
          position: relative;
          display: inline-block;
        }

        &.bass {
          span {
            &::after {
              content: ' ';
              display: block;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              overflow: hidden;
              background: #000;
              position: absolute;
              bottom: -5px;
              left: calc(50% - 3px);
            }
          }
        }
      }
    }

    .song-btn {
      width: 100%;
      height: 25%;
      display: flex;
      justify-content: center;

      .mus-list {
        width: 60%;
        margin-top: 5px;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .active {
          position: relative;
          background: #f29a38;
          color: #fff;
          border: 1px solid #f29a38;

          &:hover {
            background: #f29a38;
          }

          :deep(.active-num) {
            font-style: normal;
            display: inline-block;
            width: 25px;
            height: 25px;
            border: 1px solid #fff;
            border-radius: 50%;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            position: absolute;
            right: -11%;
            top: -11%;
            background: #f29a38;
            color: #fff;
          }
        }

        span {
          display: inline-block;
          width: 20%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40%;
          border-radius: 7px;
          font-size: 14px;
          margin-right: 15px;
          border: 1px solid #d9d9d9;
          cursor: pointer;
          font-size: 26px;

          &:hover {
            background: #eee;
          }
        }
      }
    }
  }

  .answer {
    width: 70%;
    height: 30%;

    &>div {
      padding-top: 20px;
      width: 100%;
      height: 100%;
      background: #f2f8fd;
      font-size: 20px;
      color: #69adf8;
      padding-left: 90px;
      border-radius: 7px;

      div {
        &:last-child {
          margin-top: 3%;
        }
      }
    }
  }

  .reset {
    position: absolute;
    right: 30px;
    bottom: 60px;
    cursor: pointer;
  }
}

.music-culture-container .culture-header .music-type,
.music-culture-container .culture-header .title-type {
  display: flex;
  align-items: center;
}
</style>