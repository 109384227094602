import request from '@/api/request'

import qs from 'qs'
// 获取登录用户信息
export const GetUserinfo = () => {
  return request({
    url: '/api-sch/students/current',
    method: 'get',
  })
}
// 获取登录老师信息
export const getteacherapi = () => {
  return request({
    url: '/api-sch/teachers/current',
    method: 'get',
  })
}

// 获取token
export const loginToken = (data) => {
  return request({
    url: '/api-auth/oauth/token',
    method: 'post',
    data: qs.stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    },
  })
}

export const getSmsCode = (data) => {
  return request({
    url: `/api-auth/validata/smsCode/${data.mobile}?smsType=${data.smsType}`,
    method: 'get',
  })
}
export const savepassword = (data) => {
  return request({
    url: `/api-sch/students/password`,
    method: 'PUT',
    data: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
