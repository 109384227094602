import { createRouter, createWebHashHistory } from 'vue-router'
import { getLocalStorage } from '@/utils/storage.js'
import store from '@/store'
const routes = [
  {
    path: '/',
    redirect: 'login',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "home" */ '@/views/login'),
  },
  {
    path:'/update',
    name:'update',
    component:() => import('@/views/update.vue'),
  },
  {
    path: '/Home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
  },

  {
    path: '/test',
    name: 'Test',
    component: () => import(/* webpackChunkName: "Test" */ '@/views/Test.vue'),
  },
  {
    // 精品课件 #/bcw?textbooksId=903&unitId=904
    path: '/bcw',
    name: 'BoutiqueCourseWare',
    component: () =>
      import(
        /* webpackChunkName: "BoutiqueCourseWare" */ '@/views/BoutiqueCourseWare.vue'
      ),
  },
  {
    // 自定义课件
    path: '/ccw',
    name: 'CustomCourseWare',
    component: () =>
      import(
        /* webpackChunkName: "CustomCourseWare" */ '@/views/CustomCourseWare.vue'
      ),
  },
  {
    // 精品课件与自定义课件的详情页面
    path: '/detail',
    name: 'CoursewareDetail',
    // meta: {disableCache: true},
    component: () =>
      import(
        /* webpackChunkName: "CoursewareDetail" */ '@/views/CoursewareDetail.vue'
      ),
  },
  {
    // 讲评报告列表
    path: '/cr',
    name: 'CommentReportSingle',
    component: () =>
      import(
        /* webpackChunkName: "CommentReport" */ '@/views/CommentReport.vue'
      ),
  },
  {
    // 讲评报告详情
    path: '/crd',
    name: 'CommentReportDetail',
    meta: { disableCache: true },
    component: () =>
      import(
        /* webpackChunkName: "CommentReportDetail" */ '@/views/CommentReportDetail.vue'
      ),
  },
  // 讲评首页
  {
    path: '/commentary',
    component: () => 
      import(
        /* webpackChunkName: "CommentReport" */ '@/views/Commentary.vue'
      )
    ,
    children: [{
      path: '',
      name: 'CommentReport',
      component: () =>
        import(
          /* webpackChunkName: "CommentReport" */ '@/views/CommentReport.vue'
        ),
    }, {
      path: '/commentary/exam-paper',
      name: 'ExamPaper',
      component: () => 
        import(
          /* webpackChunkName: "CommentReport" */ '@/views/ExamPaper.vue'
        )
    }]
  },

  {
    // 讲评报告详情
    path: '/exam-paper-detail',
    name: 'ExamPaperDetail',
    component: () =>
      import(
        /* webpackChunkName: "CommentReportDetail" */ '@/views/ExamPaperDetail.vue'
      ),
  },

  {
    // 个人中心
    path: '/pc',
    name: 'PersonCenter',
    component: () =>
      import(/* webpackChunkName: "PersonCenter" */ '@/views/PersonCenter.vue'),
  },
  {
    // 白板
    path: '/whiteboard',
    name: 'whiteboard',
    component: () =>
      import(/* webpackChunkName: "todo" */ '@/views/tools/whiteboard--origin.vue'),
  },
  {
    // 节拍器
    path: '/Metronome',
    name: 'Metronome',
    component: () =>
      import(/* webpackChunkName: "todo" */ '@/views/tools/Metronome.vue'),
  },
  {
    // 节拍器
    path: '/k',
    name: 'k',
    component: () =>
      import(/* webpackChunkName: "todo" */ '@/views/tools/piano.vue'),
  },
  // {
  //   // TODO:增加一个视频曲谱内容
  //   path:"/musicscore",
  //   name:"musicscore",
  //   component:()=> import(/* webpackChunkName: "todo" */ '@/views/tools/musicscore/musicscore.vue'),
  // },
  {
    path:"/musicVideo",
    name:"musicVideo",
    component:()=> import(/* webpackChunkName: "todo" */ '@/views/tools/musicVideo.vue'),
  },
  {
    path:"/comment",
    name:"comment",
    component:()=> import('@/views/comment/index.vue'),
  },
  // 创编练习
  {
    path: '/spectrum',
    name: 'Spectrum',
    component: () => import('@/views/spectrum'),
  },
  {
    path: '/detailSpectrum',
    name: 'DetailSpectrum',
    component: () => import('@/views/detailSpectrum'),
    props: true,
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "@/views/About.vue"),
  // },
  // {
  //   path: "/todoList",
  //   name: "TodoList",
  //   component: () =>
  //     import(/* webpackChunkName: "todo" */ "@/views/TodoList.vue"),
  // },
  // {
  //   path: "/luckdraw",
  //   name: "LuckDraw",
  //   component: () =>
  //     import(/* webpackChunkName: "luckdraw" */ "@/views/LuckDraw.vue"),
  // },
  // {
  //   path: "/address/list",
  //   name: "Address",
  //   component: () =>
  //     import(/* webpackChunkName: "address" */ "@/views/address/AddressList"),
  // },
  // {
  //   path: "/address/edit",
  //   name: "AddressEdit",
  //   component: () =>
  //     import(/* webpackChunkName: "address" */ "@/views/address/AddressEdit"),
  // },
  // {
  //   path: "/chat/list",
  //   name: "Chat",
  //   component: () => import(/* webpackChunkName: "chatpage" */ "@/views/chat"),
  // },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

// 白名单，里面是路由对象的name
const WhiteList = ['Login','login','update']
// vue-router4的路由守卫不再是通过next放行，而是通过return返回true或false或者一个路由地址
router.beforeEach(async (to) => {
  // document.title = to.meta.title ?? '用户登录';
  // document.title = '西艺运营平台'
  // 获取token
  const tokenStr = getLocalStorage('teacher_Token')
  if (WhiteList.includes(to.name)) {
    return true
  }
  if (!tokenStr || tokenStr == 'null') {
    return {
      name: 'Login',
      query: {
        redirect: to.path, // redirect是指登录之后可以跳回到redirect指定的页面
        ...to.query,
      },
      replace: true,
    }
  } else {
    // 获取用户角色信息，根据角色判断权限
    let userinfo = store.state.account.userinfo
    if (!userinfo) {
      try {
        // 获取用户信息
        userinfo = await store.dispatch('account/getUserinfo')
        await store.dispatch('account/getSchollInfo')
        await store.dispatch('account/getWpsInfo');
      } catch (error) {
        return {
          name: 'Login',
          query: {
            redirect: to.path, // redirect是指登录之后可以跳回到redirect指定的页面
            ...to.query,
          },
          replace: true,
        }
      }
    }
  }
})
//

export default router
