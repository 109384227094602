<script>
import { computed, defineComponent, reactive, watch, toRefs, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";
// 接口
import {reqBindMusicDetail, reqBindOrUnbindList} from '@/api/spectrum'
// 引入钢琴
import Homepiano from "./piano.vue";
// 引入节拍器
import Metronome from "./Metronome.vue";
// 引入白板
import Whiteboard from "./whiteboard";
// 引入标准音
import Keysound from "./keysound.vue";
// 识谱
import Culture from '@/views/tools/readMusic/index.vue';
// 模唱
import Simulation from "@/views/tools/simulation.vue";
// 练耳
import EarTraining from '@/views/tools/earTraining';
// 乐器
import Intruments from '@/views/tools/instruments';
// 旋律线
import Melody from '@/views/tools/melody.vue';

export default defineComponent({
  components: {
    Homepiano,
    Metronome,
    Whiteboard,
    Keysound,
    Culture,
    Simulation,
    EarTraining,
    Intruments,
    Melody
},
  props: {
    opernStatus: {
      type: Number,
      default: 0,
    },
    bottom: {
      required: false,
      default: 0,
      type: Number
    },
    kpId: {
      type: Number,
      default: 0,
    }
  },
  emits: ['changeType', 'iframeExitScreen'],
  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      isOn: false,
      homepiano: false,
      metronome: false,
      whiteboard: false,
      whiteboardMarking: false,
      keysound: false,
      hasComment: false,
      tools: false,
      showCulture: false,
      showSimulation: false,
      toolIds: [],
      bindMusicSongList: {},
      bindSongFlag: false,
      earTraining: false,
      instruments: false,
      modelSinging: '',
      melody: false,
    });
    const showList = reactive(['showCulture', 'showSimulation']);

    const latestCourse = computed(() => store.state.home.latestCourse);
    const changeCourse = () => {
      if (latestCourse.value != "null" && latestCourse.value != undefined) {
        let now = new Date().getTime(),
          start = new Date(latestCourse.value.startTime).getTime();

        if (now >= start) {
          state.hasComment = true;
        } else {
          state.hasComment = false;
        }
      }
    };
    changeCourse();
    watch(latestCourse, () => {
      changeCourse();
    });

    watch(() => props.kpId, () => {
      getBindMusicSong();
    });

    const path = computed(() => route.path);
    const exitFullscreen = () => {
      if (document.exitFullscreen && document.fullscreenElement) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen && document.msFullscreenElement) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen && document.mozFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen && document.webkitFullscreenElement) {
        document.webkitExitFullscreen();
      }
      state.isfullScreen = false;
    };
    const toPre = () => {
      exitFullscreen()
      router.go(-1);
    };
    const toPage = (type, category, flag) => {
      if (category) {
        window._paq.push(['trackEvent', category, 'open', flag]);
      }
      if(type === 2) {
        if (state.bindMusicSongList[props.kpId]) {
          if(state.bindMusicSongList[props.kpId].length > 1) {
            state.bindSongFlag = !state.bindSongFlag;
            return;
          }else {
            emit("changeType", 2, state.whiteboard, state.bindMusicSongList[props.kpId][0]);
            return;
          }
        }
      }
      if (type === 6) {
        emit("changeType", 6, state.whiteboard, state.modelSinging);
        return;
      }
      if(type === 3) emit('iframeExitScreen');
      emit("changeType", type, state.whiteboard);
    };
    onMounted(() => {
      state.toolIds = JSON.parse(localStorage.getItem('toolIds')) || [];
      getBindMusicSong();
    })
    const handleTools = (val, flag) => {
      if(state.toolIds.indexOf(flag) === -1) {
        ElMessage({
          type: 'warning',
          message: '当前内容或工具不可以用，可联系管理员申请开通！',
        });
        return;
      }

      if (val === 'whiteboard' && state.whiteboardMarking) {
        state.whiteboardMarking = false;
      } else if (val === 'whiteboardMarking' && state.whiteboard) {
        state.whiteboard = false;
      }
      
      state[val] = !state[val];
      const category = val === 'whiteboard' ? 'Whiteboard' : 'Tools';
      window._paq.push(['trackEvent', category, 'open', flag]);
      if(val === 'whiteboard') {
        toPage(5);
      }
    }
    const switchCulture = (val, flag) => {
      if(flag && state.toolIds.indexOf(flag) === -1) {
        ElMessage({
          type: "warning",
          message: '当前内容或工具不可以用，可联系管理员申请开通！',
        });
        return;
      }
      showList.forEach(item => {
        if(item !== val) {
          state[item] = false;
        }
      })
      state[val] = !state[val];
      const category = val === 'whiteboard' ? 'Whiteboard' : 'Tools';
      window._paq.push(['trackEvent', category, 'open', flag]);
    }
    const getBindMusicSong = async() => {
      if (props.opernStatus == '0' || !props.kpId || state.bindMusicSongList[props.kpId]) {
        return;
      }
      const params = {
        kpId: props.kpId,
        name: '',
        pageSize: 10,
        pageNum: 1,
      }
      const result = await reqBindOrUnbindList(params, 'bind');
      if(+result.code === 200) {
        state.bindMusicSongList[props.kpId] = result.data.result;
        if (result.data.result.length === 1) {
          getScoreInfo(result.data.result[0].id)
        }
      }
    }
    const toDetailSong = item => {
      emit("changeType", 2, state.whiteboard, item);
    }

    const getHeight = () => {
      let height = 312;
      if (state.isOn) {
        if (props.opernStatus != 0) {
          height += 78;
        }
        if (state.hasComment) {
          height += 78;
        } 
        if (path.value === '/detail') {
          height += 78;
        }
        if (state.modelSinging) {
          height += 78
        }
      } else {
        height = 0;
      }
      return height;
    };

    function getScoreInfo(id) {
      reqBindMusicDetail(id).then(res => {
        if (res.code !== 200) {
          return;
        }

        if (!res.data.modelSingingId) return;
        state.modelSinging = {
          url: res.data.attInfos[res.data.modelSingingId].cloudUrl,
          name: res.data.name
        }
      })
    }

    return {
      state,
      toPre,
      toPage,
      path,
      switchCulture,
      ...toRefs(state),
      handleTools,
      getBindMusicSong,
      toDetailSong,
      getHeight
    };
  },
});
</script>

<template>
  <div class="toolbox" :class="{ active: state.isOn }"
    :style="`height:${getHeight()}px; bottom: ${Number(bottom) + 78}px`"
    v-if="path == '/detail' || path == '/musicVideo'">
    <div @click="toPage(1, 'Resource', 'RESOURCE')">
      <span class="iconfont icon-kejian"></span>
      <span>资源</span>
    </div>
    <div v-if="state.hasComment" @click="toPage(4)">
      <span class="iconfont icon-ziyuanku"></span>
      <span>点评</span>
    </div>
    <div class="bindSong" @click="toPage(2, 'VocalAccompaniment', 'VOCAL_ACCOMPANIMENT')" v-if="opernStatus != 0">
      <span class="iconfont icon-qupu"></span>
      <span>曲谱</span>
    </div>
    <div @click="
      handleTools('whiteboard', 'WHITE_BOARD')
    "
    :class="{disabled: toolIds.indexOf('WHITE_BOARD') === -1}"
    >
      <span class="iconfont icon-baiban"></span>
      <span>白板</span>
    </div>
    <div @click="
      handleTools('whiteboardMarking', 'WHITE_BOARD')
    "
    :class="{disabled: toolIds.indexOf('WHITE_BOARD') === -1}"
    v-if="path == '/detail'"
    >
      <span class="iconfont icon-whiteboard-marking"></span>
      <span>标注</span>
    </div>
    <div @click="toPage(3)">
      <span class="iconfont icon-hudong"></span>
      <span>答题</span>
    </div>
    <div class="audio" @click="toPage(6, 'ModelSinging')" v-if="modelSinging">
      <span class="iconfont icon-yinfu"></span>
      <span>音频</span>
    </div>
    <div @click="state.tools = !state.tools">
      <span class="iconfont icon-gongjuxiang"></span>
      <span>工具</span>
    </div>
  </div>
  <div v-if="bindMusicSongList[kpId] && bindMusicSongList[kpId].length > 1" class="bindSongSelect" :class="{active: bindSongFlag}">
    <span v-for="(item, index) in bindMusicSongList[kpId]" :key="index" @click="toDetailSong(item)">
      {{item.name}}
    </span>
  </div>
  <div class="menu" :style="`bottom: ${bottom}px ;`" :class="{ active: state.isOn }" @click="state.isOn = !state.isOn"
    v-if="path == '/detail' || path == '/musicVideo'">
    <span class="iconfont icon-caidan"></span>
  </div>
  <div class="home" :style="`bottom: ${bottom}px ;`" @click="toPre">
    <span class="iconfont icon-fanhui"></span>
    <span>返回</span>
  </div>
  <div class="toolsbox" v-show="state.tools == true && state.isOn">
    <div>
      <el-space :size="0">
        <div class="item" @click="handleTools('melody', 'MELODY')" :class="{disabled: toolIds.indexOf('MELODY') === -1}">
          <img src="@/assets/img/melody.svg" alt="旋律" />
          <span>旋律</span>
        </div>
        <div class="item" @click="handleTools('instruments', 'INSTRUMENTS')" :class="{disabled: toolIds.indexOf('INSTRUMENTS') === -1}">
          <img src="@/assets/img/instrument.svg" alt="乐器" />
          <span>乐器</span>
        </div>
        <div class="item" @click="handleTools('homepiano', 'PIANO')" :class="{disabled: toolIds.indexOf('PIANO') === -1}">
          <span class="iconfont icon-gangqin"></span>
          <span>钢琴</span>
        </div>
        <div class="item" @click="handleTools('metronome', 'METRONOME')" :class="{disabled: toolIds.indexOf('METRONOME') === -1}">
          <span class="iconfont icon-multimedia"></span>
          <span>节拍器</span>
        </div>
        <div class="item" @click="handleTools('keysound', 'STANDARD_SOUND')" :class="{disabled: toolIds.indexOf('STANDARD_SOUND') === -1}">
          <span class="iconfont icon-yinle"></span>
          <span>标准音</span>
        </div>
        <div class="item" @click="switchCulture('showCulture', 'READING_MUSIC')" :class="{disabled: toolIds.indexOf('READING_MUSIC') === -1}">
          <img src="@/assets/img/notes.png" alt="识谱" />
          <p>识谱</p>
        </div>
        <div class="item" @click="switchCulture('showSimulation', 'MO_CHANG')" :class="{disabled: toolIds.indexOf('MO_CHANG') === -1}">
          <img src="@/assets/img/microphone.png" alt="模唱" />
          <p>模唱</p>
        </div>
        <div class="item" @click="handleTools('earTraining', 'EAR_TRAINING')" :class="{disabled: toolIds.indexOf('MO_CHANG') === -1}">
          <img src="@/assets/img/ear-training.png" alt="练耳" />
          <p>练耳</p>
        </div>
      </el-space>
    </div>
  </div>
  <Homepiano :show="state.homepiano" />
  <Metronome :show="state.metronome" />
  <Whiteboard :show="state.whiteboard" @close="handleTools('whiteboard', 'WHITE_BOARD')"/>
  <Whiteboard :show="state.whiteboardMarking" @close="handleTools('whiteboardMarking', 'WHITE_BOARD')" model="marking"/>
  <Keysound :show="state.keysound" />
  <Culture v-if="showCulture" @switchCulture="switchCulture" />
  <Simulation v-if="showSimulation" @switchCulture="switchCulture" />
  <EarTraining v-if="state.earTraining" @close="state.earTraining=false" />
  <Intruments v-if="state.instruments" @close="state.instruments=false" />
  <Melody v-if="state.melody" @close="state.melody=false" />
</template>

<style lang="scss" scoped>
.toolsbox {
  // width: 404px;
  height: 68px;
  background: rgba(65, 76, 95, 0.75);
  position: fixed;
  right: 90px;
  bottom: 140px;
  border-radius: 12px;
  overflow: hidden;
  z-index: 9;
  & img {
    height: 34px;
    margin-top: 8px;
  }

  .title {
    color: #fff;
    font-size: 16px;
    line-height: 50px;
    padding: 0 20px;
    border-bottom: 1px solid #979797;
    margin-bottom: 5px;
  }

  .iconfont {
    font-size: 34px;
    width: 100%;
    margin-top: 8px;
  }

  span {
    width: 78px;
    display: inline-block;
  }

  .item {
    color: #fff;
    width: 78px;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
  }
}

.toolbox {
  position: fixed;
  right: 0;
  bottom: 78px;
  width: 78px;
  color: #fff;
  text-align: center;
  transition: all linear 0.2s;
  overflow: hidden;
  transition-delay: 0s;
  z-index: 9;

  &.active {
    transition-delay: 0.2s;
  }

  div {
    height: 78px;
    background: rgba(65, 76, 95, 0.75);
    cursor: pointer;

    &:first-child {
      border-radius: 12px 0 0 0;
    }

    &:hover {
      background: #3a82ff;
    }
  }

  span {
    width: 78px;
    display: block;
    font-size: 14px;
  }

  .iconfont {
    font-size: 34px;
    height: 42px;
    width: 100%;
    padding: 8px 0 0;
    display: block;
    box-sizing: content-box;
  }
}
.bindSongSelect {
  width: 200px;
  background-color: #ddd;
  position: fixed;
  right: 88px;
  top: 500px;
  z-index: 999;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, .5);
  display: none;
  & > span {
    display: block;
    color: #fff;
    font-size: 20px;
    padding-top: 7px;
    padding-left: 5px;
    text-align: center;
    border-bottom: 1px solid #fff;
    box-sizing: border-box;
    &:hover {
      background-color: rgba(0, 0, 0, .6);
      border-radius: 0 0 20px 20px;
    }
  }
  & > span:first-child {
    border-radius: 20px 20px 0 0;
  }
   & > span:last-child {
    border: none;
  }
}
.active {
  display: block;
}

.iconfont {
  cursor: pointer;
}

.home,
.menu {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 78px;
  height: 78px;
  color: #fff;
  text-align: center;
  background: rgba(65, 76, 95, 0.75);
  border-radius: 0px 12px 12px 0px;
  z-index: 9;

  span {
    width: 78px;
    display: inline-block;
    font-size: 14px;
  }

  .iconfont {
    font-size: 34px;
    width: 100%;
    margin-top: 8px;
  }

  &:hover {
    background: #3a82ff;
  }
}

.delay {
  transition-delay: 0.2s;
}
.disabled {
  background: rgba(0, 0, 0, .6);
  filter: contrast(50%);
  position: relative;
}
.menu {
  left: auto;
  right: 0;
  line-height: 78px;
  border-radius: 12px 0px 0px 12px;
  transition: all linear 0.3s;
  transition-delay: 0.2s;

  .iconfont {
    font-size: 34px;
    width: 100%;
    margin-top: 0px;
  }

  &.active {
    background: #3a82ff;
    border-radius: 0px 0px 0px 12px;
    transition-delay: 0s;
  }
}
</style>
