<template>
  <div class="song-image" ref="songSvg" v-if="musicType === 'staff'" @click="handlerPathActive">
    <svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"
      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 476.4 238.2" xml:space="preserve">

      <title>未命名</title>
      <desc>Generated by MuseScore 3.5.0</desc>
      <line class="st0" x1="33.2" y1="68.2" x2="427.4" y2="68.2" />
      <line class="st0" x1="33.2" y1="93.2" x2="427.4" y2="93.2" />
      <line class="st0" x1="33.2" y1="118.2" x2="427.4" y2="118.2" />
      <line class="st0" x1="33.2" y1="143.2" x2="427.4" y2="143.2" />
      <line class="st0" x1="33.2" y1="168.2" x2="427.4" y2="168.2" />
      <line class="st1" x1="402.9" y1="67.2" x2="402.9" y2="169.2" />
      <line class="st2" x1="421.2" y1="67.2" x2="421.2" y2="169.2" />
      <line v-if="pathmus1Transform === 0" class="st3" x1="205.6" y1="193.2" x2="270.1" y2="193.2" />
      <path :transform="`translate(0, ${pathmus1Transform})`" class="mus1" d="M234.6,181.9c-2.3,0-4,0.8-5.1,2.4c-1.2,1.6-1.8,3.7-1.8,6.4c0,3.1,0.8,5.7,2.4,7.9c1.6,2.1,3.4,3.6,5.4,4.5
	c1.8,0.8,3.6,1.3,5.6,1.3c2.3,0,4-0.8,5.2-2.4c1.1-1.6,1.7-3.8,1.7-6.4c0-3.1-0.8-5.7-2.4-7.9c-1.6-2.1-3.4-3.6-5.3-4.5
	C238.4,182.4,236.5,181.9,234.6,181.9 M237.9,179.4c7,0,12.9,1.4,17.6,4.1c4.8,2.7,7.1,6,7.1,9.7c0,4-2.4,7.2-7.3,9.8
	c-4.9,2.7-10.7,4-17.4,4c-6.9,0-12.8-1.4-17.6-4.1c-4.8-2.7-7.2-6-7.2-9.7c0-4,2.5-7.2,7.4-9.8C225.4,180.7,231.2,179.4,237.9,179.4
	" />
      <rect class="mus1" width="100" height="60" x="190.6" y="161.9" fill="red" opacity="0"
        :transform="`translate(0, ${pathmus1Transform})`">
      </rect>
      <path v-if="staffType === 'treble'" d="M89.9,117.6c0,0,0.2,0,0.6,0c0.3-0.1,0.5-0.1,0.7-0.1c7.3,0,13.3,2.6,18,7.7c4.7,5.1,7,11,7,17.8c0,8.4-3.5,15.2-10.4,20.5
	c-1.8,1.4-4.2,2.6-7.1,3.5c0.3,4.2,0.5,7.6,0.5,10.3c0,0.5-0.1,2.4-0.2,5.7c-0.3,6.1-2.3,11.3-6.3,15.7c-3.8,4.4-8.5,6.5-14.1,6.5
	c-5.3,0-9.8-1.9-13.5-5.6c-3.7-3.8-5.6-8.3-5.6-13.7c0-2.8,1.1-5.2,3.2-7.1c2.2-1.9,4.8-2.9,7.7-2.9c2.6,0,4.8,1,6.5,3
	c1.8,1.9,2.7,4.2,2.7,7c0,2.5-0.9,4.7-2.7,6.5s-4,2.7-6.5,2.7c-1,0-2-0.2-3-0.6c2.9,4.2,6.7,6.3,11.4,6.3c4.5,0,8.2-1.8,11-5.5
	c3-3.8,4.6-8.2,4.9-13.2c0.1-3.1,0.2-4.9,0.2-5.4c0-3.6-0.1-6.6-0.4-9c-3.3,0.5-6.1,0.8-8.7,0.8c-9.1,0-16.8-3.6-23.1-10.8
	c-6.3-7.2-9.4-15.8-9.4-25.8c0-2.1,0.2-4.2,0.6-6.3c0.4-1.8,0.9-3.7,1.5-5.8c0.3-1.2,1.2-3.1,2.6-5.7c0.5-0.8,1.1-1.8,1.8-2.9
	s1.1-2,1.4-2.3c0.6-1.3,1.9-3.1,3.8-5.3c0.5-0.6,1.1-1.2,1.6-1.9c0.6-0.7,1-1.3,1.4-1.8c0.4-0.5,0.7-0.8,0.9-1.1
	c0.2-0.3,1.7-2,4.4-5.2c0.6-0.7,1.3-1.4,1.9-2.1c0.6-0.7,1.1-1.3,1.5-1.8c0.4-0.4,0.7-0.7,0.9-0.9c-2.3-7.8-3.8-13.9-4.4-18.6
	c-0.4-3.7-0.7-8.8-0.7-15.2c0-5.7,1.3-11,3.9-15.9c2.5-5.1,6.1-9.3,10.6-12.6c0.3-0.2,0.6-0.3,1-0.3c0.5,0,0.8,0.1,1,0.3
	c3.5,4.2,6.5,9.7,9.1,16.5c2.6,6.8,3.9,12.9,3.9,18.5c0,6.3-1.5,12.1-4.5,17.6c-3.4,5.8-7.8,11.8-13.1,18
	C85.8,101.7,87.6,108.4,89.9,117.6 M98.2,163c3.4-1.2,6-3.4,7.9-6.3c2.1-2.9,3.1-6.1,3.1-9.7c0-4.6-1.6-8.7-4.7-12.3
	c-3.2-3.7-7.3-5.8-12.2-6.3C95.1,142.2,97.1,153.7,98.2,163 M60.4,140.4c0,6.4,2.6,12.1,7.8,17c5.3,4.8,11.3,7.1,18,7.1
	c2.7,0,5.4-0.2,8-0.6c-1-9.9-3.1-21.7-6.2-35.5c-3.7,0.4-6.7,1.7-8.9,3.9c-2.2,2.3-3.2,4.9-3.2,7.7c0,4.9,2.6,9,7.9,12
	c0.4,0.5,0.7,0.9,0.7,1.5c0,0.5-0.2,1-0.7,1.5c-0.4,0.5-0.9,0.7-1.5,0.7c-0.1,0-0.4-0.1-0.9-0.2c-3.7-2-6.6-4.6-8.6-7.8
	c-1.9-3.4-2.8-6.8-2.8-10.3c0-4.5,1.4-8.6,4.2-12.2c2.9-3.6,6.7-6,11.4-7c-1.8-7.3-3.3-13-4.5-17.1c-1,1.2-2.3,2.6-3.8,4.3
	c-1.5,1.7-2.3,2.7-2.6,3c-2.3,3.1-4.1,5.4-5.4,7.1c-2.2,3.1-3.8,5.7-4.9,7.9c-1.1,2.5-2.1,5.2-2.9,8.1
	C60.8,134.3,60.4,137.3,60.4,140.4 M93,41.1c-4.9,2.4-8.7,5.9-11.5,10.3c-2.9,4.6-4.3,9.6-4.3,14.9c0,4.7,1.2,11,3.5,19
	c4.7-5.7,8.2-10.9,10.5-15.7c2.5-5.1,3.7-10.4,3.7-15.9C95,49.6,94.3,45.4,93,41.1" />
      <path v-else
        d="m78.15509,69c-7.55,0 -13.59,4.36 -13.7,4.36c-5.75,4.26 -8.03,9.33 -8.93,12.8c-0.5,1.78 -0.68,3.56 -0.68,5.25c0,2.39 0.39,4.56 1.29,6.45c1.19,2.68 3.47,4.56 6.45,5.55c1.19,0.4 2.48,0.6 3.77,0.6c2.19,0 4.46,-0.6 6.36,-1.69c3.07,-1.69 4.96,-5.25 4.96,-9.13c0,-3.47 -1.8,-6.65 -4.77,-8.53c-2.08,-1.39 -4.06,-1.78 -5.75,-1.78c-1.19,0 -2.28,0.19 -3.18,0.5c-0.99,0.39 -2.67,1.38 -3.47,2.08c0.31,-1.89 1.49,-4.76 2.59,-6.36c1.09,-1.58 3.27,-3.56 4.96,-4.46c2.48,-1.38 5.66,-1.98 9.13,-1.98c10.12,0 15.08,8.14 15.08,24.8c0,9.24 -3.47,18.06 -6.45,23.92c-3.29,6.54 -7.64,12.8 -11.52,16.28c-10.32,9.42 -20.04,13.89 -20.13,13.89c0,0 -1.49,0.88 -1.1,2.37c0.31,0.99 1.19,1.19 1.8,1.19c0.09,0 0.39,-0.09 0.5,-0.09c0.09,0 11.41,-4.67 20.43,-11.72c5.07,-3.66 9.63,-7.63 13.59,-11.9c3.57,-3.86 6.65,-8.03 9.13,-12.2c4.36,-7.44 6.84,-15.17 6.84,-21.82c0,-8.23 -2.17,-15.08 -6.25,-19.93c-4.69,-5.58 -11.73,-8.45 -20.95,-8.45m31.14,12.59c0,2.79 2.19,4.96 4.77,4.96c2.57,0 4.76,-2.17 4.76,-4.96c0,-2.77 -2.19,-5.05 -4.76,-5.05c-2.58,-0.01 -4.77,2.27 -4.77,5.05m0,24.5c0,2.77 2.19,5.07 4.77,5.07c2.57,0 4.76,-2.29 4.76,-5.07c0,-2.77 -2.19,-5.05 -4.76,-5.05c-2.58,0 -4.77,2.28 -4.77,5.05" />
    </svg>
  </div>
  <div class="numbered-note" v-else>
    <div class="numbered-notes" :class="staffType">
      <span @click="handlerPathActive">{{ value }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent, toRefs, ref, onMounted, watch, reactive, nextTick } from 'vue';

const markPathY = [0, -10, -23, -36, -49, -62, -75, -88, -101, -112, -124, -136];

export default defineComponent({
  props: {
    musicType: {
      type: String,
      default: 'staff', // 五线谱 staff 简谱 numbered
    },

    staffType: { // 高音 treble 低音 bass
      type: String,
      default: 'treble',
    },

    value: {
      type: Number,
      default: 1,
    }
  },

  setup(props, { emit }) {
    const state = reactive({
      pathmus1Transform: 0,
    });

    const songSvg = ref(null);

    watch(() => props.musicType, () => {
      if (props.musicType === 'staff') {
        nextTick(refreshStaff);
      }
    });
    watch(() => props.staffType, () => {
      if (props.musicType === 'staff') {
        nextTick(refreshStaff);
      }
    });
    watch(() => props.value, () => {
      if (props.musicType === 'staff') {
        nextTick(refreshStaff);
      }
    });

    const refreshStaff = () => {
      const svg = songSvg.value.querySelector('svg');
      const pathList = svg.querySelectorAll('path[transform]');
      // const rectList = svg.querySelectorAll('rect[transform]');
      if (svg && pathList && pathList.length) {
        let indexOne = props.value - 1;
        pathList.forEach(item => {
          if (item.classList?.length && item.classList[0] === 'mus1') {
            if (props.staffType === 'bass') {
              indexOne += 5;
            }
            state.pathmus1Transform = markPathY[indexOne];
          }
        })
      }
    }

    function handlerPathActive() {
      emit('check', props.value);
    }

    onMounted(() => {
      if (props.musicType === 'staff') {
        nextTick(refreshStaff);
      }
    });

    return {
      ...toRefs(state),
      songSvg,
      handlerPathActive,
    }
  }
});
</script>

<style type="text/css">
.st0 {
  fill: none;
  stroke: #000000;
  stroke-width: 2;
  stroke-linejoin: bevel;
}

.st1 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linejoin: bevel;
}

.st2 {
  fill: none;
  stroke: #000000;
  stroke-width: 12.5;
  stroke-linejoin: bevel;
}

.st3 {
  fill: none;
  stroke: #000000;
  stroke-width: 4;
  stroke-linecap: square;
  stroke-linejoin: bevel;
}

.song-image {
  cursor: pointer;
}

.numbered-notes {
  font-size: 50px;
  text-align: center;

  span {
    padding: 0 40px;
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  &.bass {
    span {
      &::after {
        content: ' ';
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        overflow: hidden;
        background: #000;
        position: absolute;
        bottom: -5px;
        left: calc(50% - 3px);
      }
    }
  }
}
</style>