import request from './request';

// 认证服务
export const ossAuth = () => {
    // 公司接口文档上，关于ali-oss上传凭证地址
    return request.get(`/api-file/files/oss/cert`).then(({ code, data, msg }) => {
        if (+code === 200) {
            return data;
        }
        throw new Error(msg);
    }).catch((e) => {
        return new Error(e);
    });
};

export const yozoAuth = (params) => {
    const url = `/api-file/files/yz/sign`;
    return request.get(url).then(({ code, data, msg }) => {
        if (+code === 200) {
            return data;
        }
        throw new Error(msg);
    }).catch((e) => {
        return new Error(e);
    });
}

export const getYozoPreviewUrl = (params) => {
    let url = '/api-file/files/yz/view/url';
    if (params) {
        url += '?'
        const keys = Object.keys(params);
        keys.forEach((key, index) => {
            url += key + '=' + params[key];
            if (index !== keys.length - 1) {
                url += '&'
            }
        })
    }

    return request.get(url).then(({ code, data, msg }) => {
        if (+code === 200) {
            return data;
        }
        throw new Error(msg);
    }).catch((e) => {
        return new Error(e);
    });
}

export const getWpsAppId = () => {
    return request.get(`/api-file/wps/file/appid`);
}