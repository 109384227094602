import { request } from "./request";
import serverApi from "@/config/host";
import Qs from "qs";
// import Cookies from 'js-cookie'

const baseUrl = serverApi.baseUrl;
export default {
  // 获取token
  getToken(params) {
    return request
      .post(`${baseUrl}/api-auth/oauth/token`, params, {
        "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
      })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  // 获取报告列表
  getReportsList(params) {
    return request
      .get(`${baseUrl}/api-sch/exam/reports`, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  // 参与考试的班级和年级名称（下拉搜索用）
  getGrade(examId) {
    return request
      .get(`${baseUrl}/api-sch/exams/${examId}/grade`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  // 考生信息列表（考試進度、成绩单）
  getExaminess(params) {
    return request.get(`${baseUrl}/api-sch/examinees/page`, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  // 报告-题目统计 （list转map 根据question获取统计信息）
  getPaperQuestionInfo(params) {
    return request
      .get(`${baseUrl}/api-sch/exam/reports/examPaper`, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  // 报告-题目获取详细信息
  getPapersInfo(id) {
    return request
      .get(`${baseUrl}/api-sch/exam/papers/${id}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  // 自定义课件列表
  getCoursewareList(params) {
    return request
      .get(`/api-sch/teachings/programme/list`, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  // 教材下拉选项列表
  getKnowledgeTextbookList(params) {
    return request
      .get(`/api-base/cur/knowledge/textbook`, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  // 单元下拉选项列表
  getUnitList(params) {
    return request
      .get(`/api-base/cur/knowledge/unit`, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  // 精品课件列表
  getBoutiqueCoursewareList(params) {
    return request
      .get(`/api-base/cur/knowledge/section/single`, params)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },
  /**
 * 
 * @param {id} fileVersionId 
 * @returns 
 */
  getNewViewUrl(id, isShowBar) {
    return request.get('/api-file/files/yz/view/url?fileVersionId=' + id + '&isShowBar=' + isShowBar).then((res) => {
      return res;
    }).catch((error) => {
      return Promise.reject(error);
    })
  },
  // 课件详情信息
  getCoursewareDetailInfo(params) {
    return request
      .get(`/api-sch/teachings/content/kp/${params.kpId}/${params.contentType}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  },

  // 学校已有工具
  getExistingToolsList(id) {
    return request.get(`/api-base/schools/${id}/permission/tools`)
      .then(res => {
        return res;
      }).catch(error => {
        return Promise.reject(error);
      })
  },
  putTest({ id, params }) {
    return request.put(
      `${baseUrl}/api-sch/base/roles${id ? `/${id}` : ""}`,
      params
    );
  },
  getTest(params) {
    return request.get(`${baseUrl}/api/test`, params).then((res) => {
      return res;
    });
  },
  deleteTest(id) {
    return request.delete(`/api-sch/teachers/${id}`).then((res) => {
      return res;
    });
  },
  // 用户信息
  getUserInfo() {
    return request.get(`${baseUrl}/api-sch/teachers/current`);
  },
  // 修改密码
  changepw(params) {
    return request.put(
      `${baseUrl}/api-sch/teachers/password`,
      Qs.parse(params)
    );
  },
  getCommentScheduleDayLatest() {
    return request.get(`${baseUrl}/api-sch/comment/schedule/day/latest`);
  },
  getCommentDay(dayScheduleId) {
    return request.get(`${baseUrl}/api-sch/comments/${dayScheduleId}`);
  },
  getCommentPersonal(params) {
    return request.put(`${baseUrl}/api-sch/comments/personal`, params);
  },
  getInteractPage(params) {
    return request.get(`${baseUrl}/api-sch/interact/quiz/page`, params);
  },
  getInteractDetail(id, kpId) {
    return request.get(`${baseUrl}/api-sch/interact/quiz/${id}`, { kpId });
  },
  getSchoolDetail(id) {
    return request.get(`${baseUrl}/api-base/schools/${id}`);
  },
  getChannelDetail(schId) {
    return request.get(`/api-base/channels/${schId}`);
  },
  // 获取成绩
  getExaminessResult(examineeId) {
    return request.get(`${baseUrl}/api-sch/examinees/${examineeId}/results`)
  },
  // 获取视唱题答案
  getScoresRecordById(id) {
    return request.get(`${baseUrl}/api-base/scores/record/${id}`)
  },

  // 获取评价的小组及小组得分
  getGroupAppraiseList(dayScheduleId) {
    return request.get(`/api-sch/groups/appraise/list`, { dayScheduleId });
  },

  updateGroupAppraise(id, score) {
    return request.put(`/api-sch/groups/appraise/${id}`, { score });
  },

  /**
   * @getClass  全部试卷  修改获取详细信息
   */
  getExamsParticulars(id) {
    return request.get(`/api-sch/exams/${id}`)
  },

  getMelodyQuestion() {
    return request.get('/api-base/cur/questions/tool/melody');
  }
};