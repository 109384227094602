/**
 * @param {string} name audio name
 * @param {string} hash audio hash
 * @param {object} obj  current obj
 */

class SoundAudio {
  constructor() {
    this.audioList = [];
    this.audioUrl = "";
    this.handleEnded = this.handleEnded.bind(this);
    this.audio = null;
  }
  addAudio(name = "", hash = "", obj = {}) {
    if (obj.flag) {
      this.removeAudio();
    }
    const audio = document.createElement("audio");
    if (hash) {
      hash = "." + hash;
    }
    if (obj.audioUrl) {
      this.audioUrl = obj.audioUrl;
    } else {
      if (obj.flag) {
        this.audioUrl =
          "https://yw-tob-teacher.oss-cn-beijing.aliyuncs.com/assets/sounds/simulationSound/";
      } else {
        this.audioUrl =
          "https://yw-tob-teacher.oss-cn-beijing.aliyuncs.com/assets/sounds/ModeSound/";
      }
    }
    audio.src = this.audioUrl + name + hash + ".mp3";
    document.body.appendChild(audio);
    this.audioList.push(audio);
    audio.play();
    audio.addEventListener("ended", this.handleEnded(obj, audio));
    this.audio = audio;
  }

  play() {
    this.audio.play();
  }

  stop() {
    this.audio.pause();
    this.audio.currentTime = 0;
  }

  pause() {
    this.audio.pause();
  }

  handleEnded(obj, audio) {
    return () => {
      if (obj.flag) {
        // 取消动图播放
        obj.flag = 0;
      }
      // 播放结束，卸载dom节点
      document.body.removeChild(audio);
      const audioIndex = this.audioList.findIndex(
        (item) => item.dataset.hash === audio.dataset.hash
      );
      this.audioList.splice(audioIndex, 1);
    };
  }
  removeAudio() {
    this.audioList.forEach((item) => {
      item.pause();
      item.removeEventListener("ended", this.handleEnded);
      // 从页面卸载
      document.body.removeChild(item);
    });
    this.audioList = [];
  }
}
export default new SoundAudio();
