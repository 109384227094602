<template>
  <CultureDialog @switchCulture="handleSwitchCulture">
    <template v-slot:header>
      <div class="title-name">识谱训练</div>
    </template>
    <template v-slot:content>
      <Question v-if="mode === 'question'" />
      <Train v-if="mode === 'train'" />
      <div style="position: absolute; bottom: 50px; left: 30px;">
        <el-button type="primary" size="large" icon="Switch" @click="handleSwitchMode"> 
          {{ mode === 'train' ? '训练模式' : '识谱模式' }}
        </el-button>
      </div>
    </template>
  </CultureDialog>
</template>

<script>
import { defineComponent, reactive, toRefs } from 'vue';
import Question from './question.vue';
import Train from './train.vue';
import CultureDialog from "../cultureDialog.vue";

export default defineComponent({
  components: { CultureDialog, Question, Train },

  setup(_, { emit }) {
    const state = reactive({
      mode: 'question'
    });

    function handleSwitchCulture() {
      emit('switchCulture', 'showCulture');
    }

    function handleSwitchMode() {
      if (state.mode === 'train') {
        state.mode = 'question';
      } else {
        state.mode = 'train';
      }
    }

    return {
      ...toRefs(state),
      handleSwitchCulture,
      handleSwitchMode
    }
  }
})
</script>

<style scoped>
@import url("./style.scss");
</style>