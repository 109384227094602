<template>
  <div class="culture-container" :class="_.class">
    <div class="culture-header" id="header">
      <slot name="header"> </slot>
      <div class="close" @mousedown="hiddenCulture">X{{ _.class }}</div>
    </div>
    <div class="culture-content">
      <slot name="content"> </slot>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, onUnmounted } from "vue";
import throttle from 'lodash.throttle';

function getTouchPosition(e) {
   if (e.changedTouches && e.changedTouches[0]) {
    return {
      clientX: e.changedTouches[0].clientX,
      clientY: e.changedTouches[0].clientY,
    }
  } else if (e.touches && e.touches[0]) {
    return {
      clientX: e.touches[0].clientX,
      clientY: e.touches[0].clientY,
    }
  }
}

export default defineComponent({
  emits: ["switchCulture"],
  setup(_, { emit }) {
    const hiddenCulture = (e) => {
      e.stopPropagation();
      emit("switchCulture");
    };

    let offsetX = 0,
      offsetY = 0;
    let container = null,
      header = null;
    const enter = (e) => {
      if (e.type.indexOf('touch') !== -1) {
        const position = getTouchPosition(e);
        offsetX = position.clientX - header.offsetParent.offsetLeft;
        offsetY = position.clientY - header.offsetParent.offsetTop;
      } else {
        offsetX = e.offsetX;
        offsetY = e.offsetY;
      }
      
      document.onselectstart = () => {
        return false;
      }; // 解决选中文字问题
      document.addEventListener("mousemove", move);
      document.addEventListener("mouseup", mouseup);
      if (e.type.indexOf('touch') !== -1) {
        document.addEventListener("touchmove", move);
        document.addEventListener("touchend", mouseup);
      }
    };
    const move = throttle((e) => {
      e.preventDefault();
      let clientX, clientY;
      if (e.type.indexOf('touch') !== -1) {
        const position = getTouchPosition(e);
        clientX = position.clientX;
        clientY = position.clientY;
      } else {
        clientX = e.clientX;
        clientY = e.clientY;
      }
      
      container.style.top = clientY - offsetY + "px";
      container.style.left = clientX - offsetX + "px";
    }, 17);
    const mouseup = (e) => {
      document.removeEventListener("mousemove", move);
      document.removeEventListener("mouseup", mouseup);
      if (e.type.indexOf('touch') !== -1) {
        document.removeEventListener("touchmove", move);
        document.removeEventListener("touchend", mouseup);
      }
    };
    onMounted(() => {
      container = document.querySelector(".culture-container");
      header = document.getElementById("header");
      header.addEventListener("mousedown", enter);
      header.addEventListener("touchstart", enter);
    });
    onUnmounted(() => {
      header.removeEventListener("mousedown", enter);
      document.removeEventListener("mousemove", move);
      document.removeEventListener("mouseup", mouseup);

      header.removeEventListener("touchstart", enter);
      document.removeEventListener("touchmove", move);
      document.removeEventListener("touchend", mouseup);
    });
    return {
      hiddenCulture,
      _,
    };
  },
});
</script>
<style lang="stylus" scoped>
.culture-container {
  width: 60%;
  height: 80%;
  position: fixed;
  top: 200px;
  left: 200px;
  border-radius: 30px;

  .culture-header {
    width: 100%;
    height: 70px;
    background: yellow;
    border-radius: 30px 30px 0 0;
    display: flex;
    justify-content: space-between;
    line-height: 90px;
    padding: 0 20px 0 20px;
    background: #3387f4;
    color: #fff;

    .close {
      width: 70px;
      height: 100%;
      line-height: 70px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .culture-content {
    width: 100%;
    overflow-y: auto;
    height: calc(100% - 90px);
    background: #fff;
    border-radius: 0 0 30px 30px;
    padding: 1%;
    border: 1px solid #ccc;
    border-top: none;
  }
}
</style>