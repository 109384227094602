<template>
  <div v-if="state.question">
    <CultureDialog @switch-culture="handleSwitchCulture">
      <template v-slot:header>
        <div class="title-name">旋律训练</div>
      </template>
      <template v-slot:content>
        <div class="question-wrapper">
          <div class="header">
            <div class="stem">{{ state.question.stems }}</div>
            <div class="play-button" @click="handlePlayToneVoice">
              <el-icon v-if="!state.loading && !state.playing">
                <VideoPlay />
              </el-icon>
              <el-icon v-if="!state.loading && state.playing">
                <VideoPause />
              </el-icon>
              <el-icon class="is-loading" v-if="state.loading">
                <Loading />
              </el-icon>
            </div>
          </div>

          <!-- <div class="player">
            <audio controls>
              <source :src="state.question.voideAttInfo.cloudUrl" />
            </audio>
          </div> -->
          <div class="answers-options">
            <div class="item-option" :class="{ selected: state.selected === answer }"
              v-for="answer in state.question.answers" v-bind:key="answer.id" @click="handleSelect(answer)">
              <span class="res-icon">
                <template v-if="state.replied">
                  <el-icon v-if="answer.isRight" style="color: green">
                    <CircleCheck />
                  </el-icon>
                  <el-icon v-else style="color: red">
                    <CircleClose />
                  </el-icon>
                </template>
              </span>
              <span class="answer-option">{{ answer.answerOption }}. </span>
              <div class="answer-desc" v-html="answer.answerDesFullText"></div>
            </div>
          </div>

          <div class="analysis" v-show="state.replied">
            <p class="label">解析：</p>
            <div v-html="state.question.analysis"></div>
          </div>
        </div>
        <div class="footer-bar">
          <button @click="handleNextQuestion">再来一题</button>
        </div>
      </template>
    </CultureDialog>
  </div>
</template>

<script>
import { defineComponent, reactive, onMounted, onBeforeUnmount } from "vue";
import * as Tone from 'tone' // 导入包，必要
import CultureDialog from "./cultureDialog.vue";
import serivce from "@/api/serivce";
import { ElMessage } from "element-plus";

export default defineComponent({
  components: { CultureDialog },

  setup(_, { emit }) {
    let questions = [];
    let current = 0;

    const state = reactive({
      question: null,
      replied: false,
      selected: null,
      loaded: false,
      loading: false,
      playing: false,
    });

    onMounted(() => {
      requestMelodyQuestion();
    });

    onBeforeUnmount(() => {
      player.stop();
      player.dispose();
    });

    function requestMelodyQuestion() {
      serivce.getMelodyQuestion().then(res => {
        if (res.code !== 200) {
          return;
        }
        questions = res.data || [];
        current = 0;

        state.question = questions[current];
        state.question.analysis = state.question.analysis.replace(/<style>.+?<\/style>/, '');
      });
    }

    function handleSelect(option) {
      if (state.replied) return;

      state.replied = true;
      state.selected = option
    }

    function handleNextQuestion() {
      if (current >= questions.length - 1) {
        ElMessage({
          message: '没有更多了',
          type: 'info'
        })
        return;
      }
      state.replied = false;
      state.selected = null;
      current++;
      state.question = questions[current];

      state.playing = false;
      state.loading = false;
      player.stop();
    }

    function handleSwitchCulture() {
      emit('close')
    }

    const player = new Tone.Player().toDestination();
    player.onstop = () => {
      state.playing = false;
      state.loading = false;
    }

    function handlePlayToneVoice() {
      if (state.playing) {
        player.stop();
        state.playing = false;
        return;
      }

      state.loading = true;
      player.load(state.question.voideAttInfo.cloudUrl).then(() => {
        state.loading = false;
        state.playing = true;
        player.start();
      })
    }

    return {
      state,
      handleSelect,
      handleNextQuestion,
      handleSwitchCulture,
      handlePlayToneVoice
    }
  }
})
</script>

<style lang="less" scope>
.is-loading {
  animation: rotating 2s linear infinite;
}

@keyframes rotating {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.question-wrapper {
  padding: 20px;
  box-sizing:border-box;
  height: 100%;
  position: relative;
}

.header {
  display: flex;
  align-items: center;
}

.stem {
  font-size: 28px;
  margin: 0 30px 0 0;
}


.player {
  padding: 30px 0;
}

.answers-options {
  margin: 0 0 30px;
  height: calc(100% - 180px);
  overflow: auto;

  
}

.answers-options::-webkit-scrollbar {display: initial;width: 8px;}
.answers-options::-webkit-scrollbar-track {background-color: #f1f1f1;}
.answers-options::-webkit-scrollbar-thumb {background-color:#888;border-radius: 4px;}
.answers-options::-webkit-scrollbar-thumb:hover {background-color:#555;}


.item-option {
  padding: 20px 30px;
  font-size: 24px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &>span {
    display: inline-block;
    margin: 0 20px 0 0;
  }

  .res-icon {
    width: 24px;
    height: 24px;
    font-size: 24px;
  }

  .answer-desc {
    img {
      max-height: 200px;
      max-width: calc(100% - 100px);
    }
  }
}

.item-option.selected {
  background: #efefef;

}

.analysis {
  font-size: 24px;
  margin: 0 0 30px;

  .label {
    font-size: 28px;
    margin: 0 0 10px;
  }
}

.footer-bar {
  position: absolute;
  bottom: 50px;
  right: 30px;
  display: flex;
  justify-content: flex-end;

  button {
    border-radius: 10px;
    font-size: 17px;
    width: 170px;
    height: 50px;
    line-height: 50px;
    border: none;
    color: #fff;
    background: #3994f7;
    padding: 0;
    cursor: pointer;
  }
}

.play-button {
  width: 50px;
  height: 50px;
  overflow: hidden;
  font-size: 50px;
  color: orange;
}
</style>