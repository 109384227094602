import {request} from './request';

// 获取题目列表
export const reqQuestionList = (params) => request.get('/api-base/cur/questions', params);
// 获取题目详情
export const getQuestionDetail = id => request.get(`/api-base/cur/questions/${id}`);
// 更新题目
export const updateQuestion = (id, params) => request.put(`/api-base/cur/questions/${id}`, params);

// 新增题目
export const insertQuestion = (params) => request.post(`/api-base/cur/questions`, params);
// 更新曲谱信息
export const updateScore = (id, params) => request.put(`/api-base/scores/${id}`, params);
export const insertScore = (params) => request.post(`/api-base/scores`, params);

// 曲谱手法列表
export const reqSkillList = () => request.get('/api-base/scores/skills');
// 曲谱类型列表
export const reqTypeList = () => request.get('/api-base/scores/types');
// 获取绑定或未绑定列表
export const reqBindOrUnbindList = (params, bindStatus) => request.get(`/api-base/scores/list/${bindStatus}`, params);
// 获取曲谱详情信息
export const reqBindMusicDetail = id => request.get(`/api-base/scores/${id}`);

/**
 *
 * @param {课件上传的参数} formData
 * @returns
 */
export const uploadFile = async (formData, contentType = 'image/svg+xml') => {
 try {
   const res = await request.postFile('/api-file/files/upload', formData, {
     'content-Type': contentType,
   });
   return res;
 } catch (err) {
   return await Promise.reject(err);
 }
};

export function abc2xml(abcStr) {
    const params = {
        abcStr,
    };
    return request.post("/api-base/abc/convert/abc2xml", params);
}
