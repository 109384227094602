import serviceApi from '@/api/serivce'
import Cookies from 'js-cookie'
import { getteacherapi } from '@/api/login.js'
import { getWpsAppId } from '@/api/user'

export default {
  namespaced: true,
  state: {
    testData: 666,
    userinfo: null,
    schoolInfo: null,
    wpsInfo: {},
  },
  mutations: {
    // 保存用户信息
    setUserinfo(state, data) {
      state.userinfo = data
      window.sessionStorage.setItem('userInfo', JSON.stringify(state.userinfo))
    },
    setSchoolInfo(state, data) {
      state.schoolInfo = data;
    },
    // 清除用户信息
    clearUserinfo(state) {
      state.userinfo = null
    },
    settTestData(state, payload) {
      state.testData = payload
    },
    setWpsInfo(state, data) {
      state.wpsInfo = data
    }
  },
  actions: {
    // 获取用户信息
    async getUserinfo({ commit }) {
      const { code, data } = await getteacherapi()
      if (+code == 200) {
        commit('setUserinfo', data)
        return Promise.resolve(data)
      }
    },
    async getSchollInfo({ commit, state }) {
      const { code, data } = await serviceApi.getSchoolDetail(state.userinfo.schId);
      if (+code === 200) {
        commit('setSchoolInfo', data);
        return Promise.resolve(data);
      }
    },
    async getToken({ commit }, params) {
      const res = await serviceApi
        .getToken(params)
        .then((res) => {
          // expiresIn: 2591999
          // refreshToken: null
          // token: "4898af14-b8e1-406b-ad13-89c06dc1f35f"
          // tokenPrefix: "Bearer "
          const { token, tokenPrefix } = res
          Cookies.set('systemToken', `${tokenPrefix}${token}`)
          return res
        })
        .catch((err) => {
          return Promise.reject({ code: 404 })
        })
      commit('settTestData', res)
      return res
    },
    async getWpsInfo({ commit }) {
      const { code, data } = await getWpsAppId();
      if (+code === 200) {
        commit('setWpsInfo', {appId: data});
        return Promise.resolve(data);
      }
    },
  },
}
