<template>
  <div id="yyclock" class="yyclock" v-show="state.show" @mousedown="move" :style="`left:${state.left};top:${state.top};`" >
    <div class="clock-top">
      <ul>
        <template v-for="(e, i) in state.beat" :key="e">
          <li :class="`width${state.beat} ${state.cur === i ? 'active' : ''}`">
            <span :class="`width${state.beat}`"></span>
          </li>
        </template>
      </ul>
    </div>
    <div class="clock-center">
      <div class="beat" @click="state.layershow = !state.layershow">
        {{ state.beat }}/{{ state.section }}
      </div>
      <div class="bpm">
        <span
          class="icon"
          @click="() => (state.bmp > 1 ? state.bmp-- : state.bmp)"
          ><svg
            class="icon"
            width="60"
            height="60"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            data-v-fd76e4fe=""
          >
            <path
              fill="currentColor"
              d="M352 480h320a32 32 0 110 64H352a32 32 0 010-64z"
            ></path>
            <path
              fill="currentColor"
              d="M512 896a384 384 0 100-768 384 384 0 000 768zm0 64a448 448 0 110-896 448 448 0 010 896z"
            ></path></svg>
        </span>
        <!-- <el-input
          class="bpminput"
          size="small"
          v-model="state.bmp"
          @change="start(1)"
        /> -->
        <el-select
          class="bpminput"
          size="small"
          style="padding: 2px 3px"
          v-model="state.bmp"
          placeholder="选择节拍"
          @change="start(1)"
        >
          <el-option
            v-for="items in state.optionsBmp"
            :key="items.label"
            :label="items.label"
            :value="items.value"
          >
            {{ items.label }}
          </el-option>
        </el-select>
        <span
          class="icon"
          @click="() => (state.bmp < 400 ? state.bmp++ : state.bmp)"
        ><svg
            class="icon"
            width="60"
            height="60"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            data-v-fd76e4fe=""
          >
            <path
              fill="currentColor"
              d="M352 480h320a32 32 0 110 64H352a32 32 0 010-64z"
            ></path>
            <path
              fill="currentColor"
              d="M480 672V352a32 32 0 1164 0v320a32 32 0 01-64 0z"
            ></path>
            <path
              fill="currentColor"
              d="M512 896a384 384 0 100-768 384 384 0 000 768zm0 64a448 448 0 110-896 448 448 0 010 896z"
            ></path>
            </svg>
        </span>
      </div>
      <div class="tap" @click="changeMusic">音色{{state.curMusic}}</div>
    </div>
    <div class="clock-bottom">
      <span class="icon-start" v-if="state.ispause" @click="start(0)"
        ><svg
          class="icon"
          width="60"
          height="60"
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
          data-v-fd76e4fe=""
        >
          <path
            fill="currentColor"
            d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm-48-247.616L668.608 512 464 375.616v272.768zm10.624-342.656l249.472 166.336a48 48 0 010 79.872L474.624 718.272A48 48 0 01400 678.336V345.6a48 48 0 0174.624-39.936z"
          ></path></svg
      ></span>
      <span class="icon-pause" v-else @click="pause"
        ><svg
          class="icon"
          width="60"
          height="60"
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
          data-v-fd76e4fe=""
        >
          <path
            fill="currentColor"
            d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm-96-544q32 0 32 32v256q0 32-32 32t-32-32V384q0-32 32-32zm192 0q32 0 32 32v256q0 32-32 32t-32-32V384q0-32 32-32z"
          ></path></svg
      ></span>
    </div>
    <div class="layer" v-show="state.layershow">
      <div class="bpm">
        <span
          class="icon"
          @click="() => (state.beat > 1 ? state.beat-- : state.beat)"
          ><svg
            class="icon"
            width="60"
            height="60"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            data-v-fd76e4fe=""
          >
            <path
              fill="currentColor"
              d="M352 480h320a32 32 0 110 64H352a32 32 0 010-64z"
            ></path>
            <path
              fill="currentColor"
              d="M512 896a384 384 0 100-768 384 384 0 000 768zm0 64a448 448 0 110-896 448 448 0 010 896z"
            ></path></svg
        ></span>
        <el-input
          class="bpminput"
          size="small"
          v-model="state.beat"
          @change="start(1)"
        />
        <span
          class="icon"
          @click="() => (state.beat < 16 ? state.beat++ : state.beat)"
          ><svg
            class="icon"
            width="60"
            height="60"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            data-v-fd76e4fe=""
          >
            <path
              fill="currentColor"
              d="M352 480h320a32 32 0 110 64H352a32 32 0 010-64z"
            ></path>
            <path
              fill="currentColor"
              d="M480 672V352a32 32 0 1164 0v320a32 32 0 01-64 0z"
            ></path>
            <path
              fill="currentColor"
              d="M512 896a384 384 0 100-768 384 384 0 000 768zm0 64a448 448 0 110-896 448 448 0 010 896z"
            ></path></svg
        ></span>
      </div>
      <p></p>
      <div class="bpm">
        <span
          class="icon"
          @click="() => (state.section > 1 ? state.section-- : state.section)"
          ><svg
            class="icon"
            width="60"
            height="60"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            data-v-fd76e4fe=""
          >
            <path
              fill="currentColor"
              d="M352 480h320a32 32 0 110 64H352a32 32 0 010-64z"
            ></path>
            <path
              fill="currentColor"
              d="M512 896a384 384 0 100-768 384 384 0 000 768zm0 64a448 448 0 110-896 448 448 0 010 896z"
            ></path></svg
        ></span>
        <el-input
          class="bpminput"
          size="small"
          v-model="state.section"
          @change="start(1)"
        />
        <span
          class="icon"
          @click="() => (state.section < 8 ? state.section++ : state.section)"
          ><svg
            class="icon"
            width="60"
            height="60"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
            data-v-fd76e4fe=""
          >
            <path
              fill="currentColor"
              d="M352 480h320a32 32 0 110 64H352a32 32 0 010-64z"
            ></path>
            <path
              fill="currentColor"
              d="M480 672V352a32 32 0 1164 0v320a32 32 0 01-64 0z"
            ></path>
            <path
              fill="currentColor"
              d="M512 896a384 384 0 100-768 384 384 0 000 768zm0 64a448 448 0 110-896 448 448 0 010 896z"
            ></path></svg
        ></span>
      </div>
    </div>
    <span class="close" @click="close"
      ><svg
        width="30"
        height="30"
        viewBox="0 0 1024 1024"
        xmlns="http://www.w3.org/2000/svg"
        data-v-fd76e4fe=""
      >
        <path
          fill="currentColor"
          d="M466.752 512l-90.496-90.496a32 32 0 0145.248-45.248L512 466.752l90.496-90.496a32 32 0 1145.248 45.248L557.248 512l90.496 90.496a32 32 0 11-45.248 45.248L512 557.248l-90.496 90.496a32 32 0 01-45.248-45.248L466.752 512z"
        ></path>
        <path
          fill="currentColor"
          d="M512 896a384 384 0 100-768 384 384 0 000 768zm0 64a448 448 0 110-896 448 448 0 010 896z"
        ></path></svg
    ></span>
  </div>
  <audio class="clickaudio" preload="auto">
    <source src="@/assets/sounds/click1.mp3" type="audio/mp3" />
  </audio>
  <audio class="bellaudio" preload="auto">
    <source src="@/assets/sounds/bell1.mp3" type="audio/mp3" />
  </audio>
  <audio class="clickaudio" preload="auto">
    <source src="@/assets/sounds/click2.mp3" type="audio/mp3" />
  </audio>
  <audio class="bellaudio" preload="auto">
    <source src="@/assets/sounds/bell2.mp3" type="audio/mp3" />
  </audio>
  <!-- 以下为声音颠倒的效果 -->

  <audio class="clickaudio" preload="auto">
    <source src="@/assets/sounds/bell2.mp3" type="audio/mp3" />
  </audio>
  <audio class="bellaudio" preload="auto">
    <source src="@/assets/sounds/click2.mp3" type="audio/mp3" />
  </audio>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, watch } from 'vue'

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const state = reactive({
      bmp: 60,
      beat: 4,
      section: 4,
      timer: null,
      cur: null,
      ispause: true,
      layershow: false,
      show: false,
      top: "200px",
      left: "200px",
      curMusic: 1,
      // 节拍器默认常用
      optionsBmp: [
        {
          label: "46/largo/广板", //46,
          value: 46,
        },
        {
          value: 52, //52,
          label: "52/lento/慢板",
        },
        {
          value: 60, //60,
          label: "60/adagio/柔板",
        },
        {
          value: 72, //72,
          label: "72/andante/行板",
        },
        {
          value: 96,
          label: "96/moderato/中板",
        },
        {
          value: 132,
          label: "132/allegro/快板",
        },
        {
          value: 184,
          label: "184/presto/急板",
        },
      ],
    });

    // const clickaudio1 = ref(""),
    //   bellaudio1 = ref(""),
    //   clickaudio2 = ref(""),
    //   bellaudio2 = ref("");

    const close = () => {
      state.show = false;
      state.cur = "";
      state.ispause = true;
      clearInterval(state.timer);
    };

    watch(
      () => props.show,
      () => {
        state.show = !state.show;
        state.cur = "";
        state.ispause = true;
        clearInterval(state.timer);
      }
    );

    watch(
      () => state.cur,
      (val) => {
        let bellaudio = document.getElementsByClassName('bellaudio')
        let clickaudio = document.getElementsByClassName('clickaudio')
        if (val === 0) {
          bellaudio[state.curMusic-1].currentTime = 0;
          bellaudio[state.curMusic-1].play();
        } else if (val != "") {
          clickaudio[state.curMusic-1].currentTime = 0;
          clickaudio[state.curMusic-1].play();
        }
      }
    );


    const start = (val) => {
      if (val && state.ispause) {
        return;
      }
      const time = (60 / state.bmp) * 1000;
      clearInterval(state.timer);
      state.ispause = false;
      state.cur = 0;
      state.timer = setInterval(() => {
        state.cur = (state.cur + 1) % state.beat;
      }, time);
    };

    const pause = () => {
      state.ispause = true;
      state.cur = "";
      clearInterval(state.timer);
    };

    let move = (e) => {
      if (e.target.tagName.toLocaleLowerCase() == "input") {
        return;
      }
      let disX = e.clientX - parseFloat(state.left);
      let disY = e.clientY - parseFloat(state.top);
      document.onmousemove = (e) => {
        let left = e.clientX - disX;
        let top = e.clientY - disY;
        state.left = left + "px";
        state.top = top + "px";
      };
      document.onmouseup = (e) => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    };
    onMounted(()=>{
      let piano = document.getElementById('yyclock');
      piano.addEventListener("contextmenu",(event)=>{
        event.preventDefault()
      })

      let oL, oT;
      piano.addEventListener('touchstart',(e)=>{
        var ev = e || window.event;
        if(ev.target.tagName.toLocaleLowerCase()=='input'){
          return
        }
        var touch = ev.targetTouches[0];
        oL = touch.clientX - parseFloat(state.left);
        oT = touch.clientY - parseFloat(state.top);
        document.addEventListener("touchmove", defaultEvent, false);
      })
      piano.addEventListener('touchmove', function(e) {
        var ev = e || window.event;
        var touch = ev.targetTouches[0];
        var oLeft = touch.clientX - oL;
        var oTop = touch.clientY - oT;
        state.left = (oLeft<10?10:oLeft) + 'px';
        state.top = (oTop<10?10:oTop) + 'px';
      });
      piano.addEventListener('touchend', function() {
        document.removeEventListener("touchmove", defaultEvent);
      });
      const defaultEvent = (e)=> {
        e.preventDefault();
      }
    })

    const changeMusic = ()=>{
      if(state.curMusic < 3){
        state.curMusic++
      }else{
        state.curMusic = 1
      }
    }

    return {
      state,
      start,
      pause,
      move,
      close,
      changeMusic,
    };
  },
});
</script>


<style lang="scss" scoped>
.close {
  position: absolute;
  right: -25px;
  top: -25px;
  color: #000;
  width: 30px;
  height: 30px;
}
.layer {
  width: 500px;
  height: 410px;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #252525;
  box-sizing: border-box;
  padding: 100px 110px;
  p {
    margin: 40px 0;
    height: 2px;
    background-color: #ccc;
  }
}
.yyclock {
  position: fixed;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%,-50%);
  // margin-left: -250px;
  // margin-top: -350px;
  width: 500px;
  height: 700px;
  background: #fff;
  border-radius: 20px;
  // overflow: hidden;
  box-sizing: border-box;
  box-shadow: 0px 0px 40px rgba($color: #000000, $alpha: 0.3);
  z-index: 8;
}

.clock-top {
  width: 100%;
  height: 200px;
  background-color: rgba($color: #000000, $alpha: 0.8);
  text-align: center;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  ul {
    overflow: hidden;
    padding: 30px 10px;
    display: inline-block;
  }
  li {
    float: left;
    height: 140px;
    background-color: rgba($color: #000000, $alpha: 0.4);
    margin: 0 10px;
    border-radius: 8px;
    overflow: hidden;
    &.active {
      background-color: #fff;
    }
    &:first-child {
      &.active {
        background-color: red;
      }
      span {
        height: 0px;
      }
    }
  }
  span {
    display: block;
    height: 100px;
    background-color: rgba($color: #000000, $alpha: 0.5);
  }
}
.clock-center {
  height: 90px;
  text-align: center;
  display: flex;
  background-color: rgba($color: #000000, $alpha: 0.7);
  padding: 15px 10px;
  .tap,
  .beat {
    width: 110px;
    border-radius: 60px;
    border: 1px solid rgba($color: #000000, $alpha: 0.65);
    height: 60px;
    line-height: 60px;
    display: inline-block;
    background-color: rgba($color: #000000, $alpha: 0.6);
    color: #eee;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.6);
    cursor: pointer;
  }
}

.bpm {
  width: 280px;
  padding: 0 10px;
  display: flex;
  box-sizing: border-box;
  .icon {
    font-size: 20px;
    color: #eee;
    display: block;
    width: 60px;
    height: 60px;
    box-sizing: border-box;
    cursor: pointer;
  }
  .bpminput {
    width: 140px;
    text-align: center;
    margin: 0 10px;
  }
}

.clock-bottom {
  background-color: rgba($color: #000000, $alpha: 0.7);
  height: 410px;
  text-align: center;
  position: relative;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.icon-start,
.icon-pause {
  display: inline-block;
  color: rgba($color: #fff, $alpha: 0.65);
  width: 200px;
  height: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: -5px;
  border-radius: 50%;
  border: 1px solid rgba($color: #000000, $alpha: 1);
  box-shadow: 0px 0px 40px rgba($color: #000000, $alpha: 0.6) inset;
  padding-top: 50px;
  .icon {
    width: 100px;
    height: 100px;
    box-shadow: 0px 0px 20px rgba($color: #000000, $alpha: 0.6);
    border-radius: 50%;
  }
}

.width1,
.width2,
.width3,
.width4,
.width5,
.width6 {
  width: 60px;
}
.width7 {
  width: 55px;
}
.width8 {
  width: 50px;
}
li.width10,
li.width9,
li.width7,
li.width8 {
  margin: 0 5px;
}
.width9 {
  width: 42px;
}
.width10 {
  width: 36px;
}

li.width11,
li.width12,
li.width13,
li.width14,
li.width15,
li.width16 {
  margin: 0 3px;
}
.width11 {
  width: 36px;
}
.width12 {
  width: 34px;
}
.width13 {
  width: 30px;
}
.width14 {
  width: 28px;
}
.width15 {
  width: 26px;
}
.width16 {
  width: 24px;
}
</style>

<style>
.bpminput .el-input__inner {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(0, 0, 0, 0.65);
  color: #eee;
}
</style>