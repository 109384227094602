// 这个是 SessionStorage 存储
export function setSessionStorage(key, value) {
  const str = window.JSON.stringify(value)
  window.sessionStorage.setItem(key, str)
}

export function getSessionStorage(key) {
  let json = '' || null
  json = window.sessionStorage.getItem(key)
  if (json) json = window.JSON.parse(json)
  return json
}
// 这个是 LocalStorage 存储
export function setLocalStorage(key, value) {
  const str = window.JSON.stringify(value)
  window.localStorage.setItem(key, str)
}

export function getLocalStorage(key) {
  let json = '' || null
  json = window.localStorage.getItem(key)
  if (json) json = window.JSON.parse(json)
  return json
}

export const removeItem = (name) => {
  window.localStorage.removeItem(name)
}
