<script>
import { defineComponent, reactive, watch, onMounted } from "vue";

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const state = reactive({
      show: false,
      left: "200px",
      top: "200px",
      ispause: true,
    });
    watch(
      () => props.show,
      () => {
        state.show = !state.show;
      }
    );

    let move = (e) => {
      if (typeof e.target.className == "object") {
        document.onmousemove = null;
        document.onmouseup = null;
        return;
      }

      let thatLeft = parseFloat(state.left),
        thatTop = parseFloat(state.top);
      let disX = e.clientX - thatLeft;
      let disY = e.clientY - thatTop;
      document.onmousemove = (e) => {
        let left = e.clientX - disX;
        let top = e.clientY - disY;
        state.left = (left < 10 ? 10 : left) + "px";
        state.top = (top < 10 ? 10 : top) + "px";
      };
      document.onmouseup = (e) => {
        document.onmousemove = null;
        document.onmouseup = null;
      };
    };

    onMounted(() => {
      let piano = document.getElementById("sounds");
      piano.addEventListener("contextmenu", (event) => {
        event.preventDefault();
      });

      let oL, oT;
      piano.addEventListener("touchstart", (e) => {
        var ev = e || window.event;
        var touch = ev.targetTouches[0];
        oL = touch.clientX - parseFloat(state.left);
        oT = touch.clientY - parseFloat(state.top);
        document.addEventListener("touchmove", defaultEvent, false);
      });
      piano.addEventListener("touchmove", function (e) {
        var ev = e || window.event;
        var touch = ev.targetTouches[0];
        var oLeft = touch.clientX - oL;
        var oTop = touch.clientY - oT;
        state.left = (oLeft < 10 ? 10 : oLeft) + "px";
        state.top = (oTop < 10 ? 10 : oTop) + "px";
      });
      piano.addEventListener("touchend", function () {
        document.removeEventListener("touchmove", defaultEvent);
      });
      const defaultEvent = (e) => {
        e.preventDefault();
      };
    });

    const playmusic = () => {
      let audio = document.getElementById("playaudio");
      if (audio.paused) {
        state.ispause = false;
        let timers = setTimeout(() => {
          state.ispause = true;
          audio.load();
          clearTimeout(timers);
        }, 1000);
        audio.play();
      } else {
        state.ispause = true;
        audio.paused();
        audio.load();
      }
    };

    return {
      state,
      move,
      playmusic,
    };
  },
});
</script>

<template>
  <div
    class="sound"
    id="sounds"
    v-show="state.show"
    @mousedown="move"
    :style="`left:${state.left};top:${state.top};`"
  >
    <div class="close" @click="()=>state.show = false">
      <img src="@/assets/img/close1.png" alt />
    </div>
    <div class="text">标准音</div>
    <div class="clock-bottom">
      <span class="icon-start" v-if="state.ispause" @click="playmusic"
        ><svg
          class="icon"
          width="60"
          height="60"
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
          data-v-fd76e4fe=""
        >
          <path
            fill="currentColor"
            d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm-48-247.616L668.608 512 464 375.616v272.768zm10.624-342.656l249.472 166.336a48 48 0 010 79.872L474.624 718.272A48 48 0 01400 678.336V345.6a48 48 0 0174.624-39.936z"
          ></path></svg
      ></span>
      <span class="icon-pause" v-else @click="playmusic"
        ><svg
          class="icon"
          width="60"
          height="60"
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
          data-v-fd76e4fe=""
        >
          <path
            fill="currentColor"
            d="M512 64a448 448 0 110 896 448 448 0 010-896zm0 832a384 384 0 000-768 384 384 0 000 768zm-96-544q32 0 32 32v256q0 32-32 32t-32-32V384q0-32 32-32zm192 0q32 0 32 32v256q0 32-32 32t-32-32V384q0-32 32-32z"
          ></path></svg
      ></span>
    </div>
    <audio v-show="false" id="playaudio" preload controls class="sounda">
      <source src="@/assets/sounds/soundA.mp3" />
    </audio>
  </div>
</template>

<style lang="scss" scoped>
.sound {
  position: fixed;
  z-index: 8;
  text-align: center;
  color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
  .text {
    background: #000;
    line-height: 40px;
  }
  .close {
    width: 100%;
    img {
      position: absolute;
      right: 10px;
      top: 15px;
      cursor: pointer;
    }
  }
  .changesize {
    width: 50px;
    height: 50px;
    cursor: pointer;
    margin-top: 20px;
  }
  .sounda {
    width: 300px;
    height: 100px;
  }
}
.clock-bottom {
  background-color: rgba($color: #000000, $alpha: 0.7);
  height: 140px;
  width: 140px;
  text-align: center;
  position: relative;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.icon-start,
.icon-pause {
  display: inline-block;
  color: rgba($color: #fff, $alpha: 0.65);
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 1px solid rgba($color: #000000, $alpha: 1);
  box-shadow: 0px 0px 40px rgba($color: #000000, $alpha: 0.6) inset;
  padding-top: 25px;
  .icon {
    width: 50px;
    height: 50px;
    box-shadow: 0px 0px 20px rgba($color: #000000, $alpha: 0.6);
    border-radius: 50%;
  }
}
</style>
