import serviceApi from '@/api/serivce'
import { reqFileDetailList } from '../../api/home';
import { ElMessage } from 'element-plus'
export default {
  namespaced: true,
  state: {
    detailInfo: [],
  },
  mutations: {
    addDetailInfo(state, payload = {}) {
      payload.atts = payload.atts || [];
      payload.attIds = payload.attIds || payload.atts.map(att => att.id);
      state.detailInfo.push(payload);
    },
    removeDetailInfo(state, index) {
      state.detailInfo.splice(index, 1);
    },

    clearDetailInfo(state) {
      state.detailInfo.length = 0;
    },
    REVEICE_NEWVIEWURL(state,{data, id}) {
      const index = state.detailInfo.findIndex(item => item.id === id);
      if(index !== -1) {
        state.detailInfo[index].newViewUrl = data;
      }
    }
  },
  actions: {
    async getCoursewareDetailInfo({dispatch, commit }, params) {
      const res = await serviceApi
        .getCoursewareDetailInfo(params)
        .then(async (res) => {
          const { code, data = {}, msg } = res
          if (code === 200) {
            commit('addDetailInfo', data || {});
            dispatch('getNewUrl', data);
          } else {
            ElMessage({
              message: res.msg,
              type: 'warning',
            })
          }
          data.kpId = params.kpId;
          data.opernStatus = params.opernStatus;
          return res.data
        })
        .catch((err) => {
          Promise.resolve(err)
          return
        })
      return res
    },
    async getFileDetail({dispatch, commit}, params) {
      const result = await reqFileDetailList(params.resId, params.permission);
      if(+result.code === 200) {
        commit('addDetailInfo', result.data || {});
        commit('REVEICE_NEWVIEWURL',{ data: result.data.atts[0].viewUrl? result.data.atts[0].viewUrl : result.data.atts[0].cloudUrl  || {}, id: result.data.atts[0]?.id});
      }
    },
    async getNewUrl({commit}, data) {
      const isShowBar = 0;
      let result = null;
      if(data.baseAttachments? data?.baseAttachments[0]?.fileVersionId : data.atts[0]?.fileVersionId) {
        result = await serviceApi.getNewViewUrl(data.baseAttachments? data?.baseAttachments[0]?.fileVersionId : data.atts[0]?.fileVersionId, isShowBar);
        if(result.code === 200 && result.data) {
          commit('REVEICE_NEWVIEWURL', {data: result.data, id: data.id});
        }
      }else {
        commit('REVEICE_NEWVIEWURL', {data: data.baseAttachments? data?.baseAttachments[0]?.cloudUrl : data.atts[0]?.cloudUrl, id: data.id});
      }
    }
  },
}
