import SampleLibrary from '@/lib//Tongjs-Instruments';
import * as Tone from 'tone';

export const initTone = () => {
    return new Promise((resolve, reject) => {
        var piano = SampleLibrary.load({
            instruments: 'piano'
        });
        resolve(piano.toDestination())
        // Tone.Buffer.on('load', function () {
        //     // play instrument sound
        //     instruments['piano'].toDestination();
        //     resolve(instruments['piano']);
        // });
    });
}
