<template>
  <select name="123" id="abc">
    <option value="1">haha</option>
    <option value="a">aaa</option>
    <option value="b">bbb</option>
    <option value="c">ccc</option>
  </select>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "YuiSelect",
});
</script>
