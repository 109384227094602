import { createStore } from 'vuex'
import testModule from './modules/testModule'
import account from '@/store/modules/account'
import reports from '@/store/modules/reports'
import home from '@/store/modules/home'
import coursewareDetailInfo from '@/store/modules/coursewareDetailInfo'

export default createStore({
  state: {
    // TODO:新增当前的位置坐标的信息
    cursorPos:{},
    selectAddress: null,
    // 新增同步的曲谱内容
    curKpId: '', //当前的知识点id,通过知识点id查找曲谱
    showUploadVideos: [], //展示当前的video,更新打开弹窗的时候，
    // TODO:暂存每一次视频段落的上传内容，新建的时候的暂存
    newUploadVideos: [],
    // 曲谱的上传地址内容,每一次曲谱上传后的地址
    musicSvgSong: '',
    // TODO:设置当前的rect的视频的弹窗
    dialogRectFlag: false,
    // TODO:当前曲谱对象的全量信息,处于编辑上传时候的对象存储
    currentMusicId: '',
    // 范唱id
    modelAttId: '',
    // 伴唱id
    accompanimentAttId: '',
    // 当前的positons每一次画完才清空一次，如果true表示清空，false表示不清空
    currentOperatePosFlag: false,
    currentOperatePos: '', //存储没有保存的position,点击完成设置顺序后，清空这个数组
    // TODO:全量的位置信息获取和保存
    // 仅仅用于初始化的对比，初始化之后，每一次保存设置顺序，就是重新获取了最新的所有内容
    allOperations: '',
    initCurrentFullComModelSvgInfo: {},
    // TODO:这个全量的对象，相当于前台的步骤暂存器，每一步，必须对应找到相应的内容保存一下
    currentFullComModelSvgInfo: {
      id: '', //曲谱id
      modelAttId: '', //范唱id
      accompanimentAttId: '', //伴唱id
      accompanimentAttInfo: {}, //伴唱相关信息
      kpId: '', //当前的知识点，也就是树tree组件的选中kpId
      //标记列表，这个是一个数组，标记和视频最后会绑定.
      // 这里的外侧的文字标记，本次不做，可以功能随后cancel了。
      // 每一块展示一块弹窗，多个黄色块，展示多个。
      labels: [
        // TODO:开发的时候，写和接口一样的数据结构开发，
        // 开发完成后，可以删除之前定义好的数据结构，非常好的方法
        // {
        // TODO:注意曲谱标记id这个用来标记每一个色块，或是已经做了合并的操作色块
        //   id: '',
        //   // 曲谱id
        //   opernId: '',
        // TODO:多个视频公用合并标记，多个块，给一个标记manyRectFlag:true
        //   // TODO:前台存储的id内容
        //   _divId: '222',
        //   // TODO:位置
        //   positions: '',
        //   // 视频列表信息
        //   videoInfos: [
        //     {
        //       // 视频id
        //       videoAttId: '',
        //       // 视频顺序
        //       sort: '',
        //       // 当前视频
        //       originName: '',
        //     },
        //   ],
        // },
      ],
    },
    // 通过接口获取的信息
    interfaceCurrentFullComModelSvgInfo: null,
    // TODO:当前获取的操作_divId的色块信息
    currentDivId: '',
    // TODO:当前的色块对应的位置信息
    ActivityDiv: [],

    // TODO:当前的色块对应的弹窗是没有上传过视频的新的。默认都是新增
    // TODO:这个数据需要被监听起来
    currentDivDialogFlag: false,
    // 当前的数据fullRectVideos，展示当前的视频汇总
    // TODO:这个fullRectVideos是关于当前的操作内容，还是汇总所有的当前的视频？？？
    // TODO:这个是当前弹窗的视频汇总内容，展示当前的弹窗的
    fullRectVideos: [],
    // TODO:当前的所有的labels
    fullLables: [],
    // 上一次接口获取的
    // TODO:当前操作的曲谱标记的id
    labelId: '',
    // 全量的色块的labelId,初始化的时候的全量
    labelArrId: [],
    // 每一次确定完成后，新的全量数组
    newLabelArrId: [],
    // 当前需要合并的位置信息
    mergeCurPos: [],
    // TODO:当前的接口最新的所有位置信息合集
    allShowPos: [],

    // TODO:新的当前的新建设置视频的labelId的内容
    newCurrentLabelId: '',
    // TODO:暂存当前操作的所有的位置信息
    newLastDiffPostPos: [],
    // TODO:用三个状态判断当前的双击弹窗时候的操作，1是单个新建，2是合并新建，3是更新
    operateId: '',
    // TODO:当前是更新的时候，当前的展示层的视频信息
    // TODO:全局弹窗当前是更新还是新建，新建false,更新true
    globalStatusDialog: false,
    svgFullVideos: [],
    // TODO:当前上传完成曲谱的svgId
    svgAttId: '',
    // TODO:当前双击了区块的标记
    dbClickFlag:false,
  },
  mutations: {
    setDbClickFlag(state,data){
      state.dbClickFlag = data
    },
    // TODO:存储当前的坐标的位置信息
    setCursorPos(state,data){
      state.cursorPos = data
    },
    setSelectAddress(state, payload) {
      state.selectAddress = payload
    },
    // TODO:设置当前上传完成曲谱svg的svgAttId
    setSvgAttId(state, data) {
      state.svgAttId = data;
    },
    // TODO:设置当前的知识点id
    setCurKpId(state, data) {
      state.curKpId = data;
    },
    // TODO:全局视频的汇总数据内容
    setSvgFullVideos(state, data) {
      state.svgFullVideos = data;
    },
    // TODO:同步设置当时是新建还是编辑
    setGlobalStatusDialog(state, data) {
      state.globalStatusDialog = data;
    },
    // TODO:更新打开的时候，这里的视频是找到lables全量内容，直接替换
    setAllShowUploadVideos(state, data) {
      state.showUploadVideos = data;
    },
    setClearShowUploadVideos(state, data) {
      state.showUploadVideos = [];
    },
    // TODO:更新当前的内容，打开弹窗的时候，打开弹窗新建一个个的时候，这里的内容是push一个个
    setShowUploadVideos(state, data) {
      const index = state.showUploadVideos.findIndex(item => item.id === data.id);
        if(index === -1) {
          state.showUploadVideos.push(data);
        }
    },
    // TODO:设置当前的操作态
    setOperateId(state, data) {
      state.operateId = data;
    },
    // 暂存当前操作的所有的位置信息，当前弹出弹窗的时候，此时的位置信息
    setNewLastDiffPostPos(state, data) {
      state.newLastDiffPostPos = data;
    },
    // TODO:记录一下，每一次新建设置视频的labelId的内容
    setNewCurrentLabelId(state, data) {
      state.newCurrentLabelId = data;
    },
    // TODO:清空暂存当前的新建视频段落的内容
    setClearNewUploadVideos(state, data) {
      state.newUploadVideos = [];
    },
    // TODO:暂存当前的新建视频段落的内容
    setNewUploadVideos(state, data) {
      state.newUploadVideos.push(data);
    },
    // TODO:新建的时候，移除视频的时候，这里的newUploadVideos需要使用替换
    setRemoveNewUploadVideos(state, data) {
      state.newUploadVideos = data;
    },
    // 编辑的时候，移除视频，这里
    setRemoveShowUploadVideos(state, data) {
      state.showUploadVideos = data;
    },
    // 段落上传后，更新此时的全量的labels,此时是不是一个合并操作，如果是
    // 当前的新建下的状态操作，还是编辑下的状态操作
    // 第一种：新建，单个上传
    // 第二种：新建，多个合并
    // 第三种：更新，单个
    // 第四种：更新，多个合并上传

    // 此时判断TODO:有没有labelId，如果没有labelId都是新建，此时第一步，如果是新建当前的视频内容需要暂存一下，因为待会设置顺序会用，然后设置
    // 第二步，顺序后，这里生成了新的labelId,拼接一个新的labels数组，同时将顺序顺便放好。
    // TODO:如果有labelId,那么此时更新，直接在上传视频后，直接更新一下视频，然后设置顺序成功后，更新一下此时对应labelId中的视频顺序存储
    setConfirmFullLabels(state, data) {
      let { videoInfos, labelId, refreshFlag, positions } = data;
      let tempObj = [...state.fullLables];
      // 这里的内容是更新操作
      if (refreshFlag) {
        state.fullLables.forEach((item, index) => {
          // TODO:如果此时有这个labelId那么视频更新
          if (item.id == labelId) {
            tempObj[index].videoInfos = videoInfos; //彻底将整个节点的videoInfo更新成为最新的
            // TODO:设置当前的节点对应的位置信息
            tempObj[index].positions = positions;
            state.fullLables[index].positions.push(positions);
            // TODO:更新视频信息
            state.fullLables[index].videoInfos.push(videoInfos);
          }
        });
      } else {
        // TODO:此时是新建操作
        let tempObj2 = {
          id: labelId,
          videoInfos,
          positions,
        };
        state.fullLables.push(tempObj2);
      }
    },
    // 实时删除更新此时的全部的fullLabels信息
    setUpdateFullLables(state, data) {
      let temp = [];
      state.fullLables.forEach((item) => {
        if (item.id == data) {
        } else {
          temp.push(item);
        }
      });
      state.fullLables = temp;
    },
    // TODO:实时更新此时的全部的positions信息汇总
    setUpdateAllShowPos(state, data) {
      let tempObj = [...data];

      state.allShowPos = tempObj;
      console.log('删除完成后，更新数据', tempObj);
    },
    // TODO:设置当前的位置信息汇总的数据
    setAllShowPos(state, data) {
      state.allShowPos = data;
    },
    // TODO:初始化的时候initCurrentFullComModelSvgInfo，从接口获取的所有的数据内容
    // 当前的mergeLabels的所有的信息
    setInitCurrentFullComModelSvgInfo(state, data) {
      state.initCurrentFullComModelSvgInfo = data;
    },
    setMergeCurPos(state, data) {
      state.mergeCurPos = data;
    },
    // TODO:当前所有色块的位置信息获取，为了过滤新增的色块位置信息
    setAllOperations(state, data) {
      state.allOperations = data;
    },
    // TODO:更新全量数据中的所有labels，这个是初始化从接口返回设置一次，每一次点击确定顺序，再次更新一次
    setFullLables(state, data) {
      state.fullLables = data;
    },
    // TODO:每一次保存当前的设置顺序后，更新fullLables内容
    setRefreshLables(state, data) {
      state.fullLables.push(data);
    },
    setNewLabelArrId(state, data) {
      state.newLabelArrId = [...state.labelArrId, data];
    },
    // 所有的全量的色块的id内容汇总
    setLabelArrId(state, data) {
      state.labelArrId = data;
    },
    // 设置当前曲谱标记id的顺序内容，曲谱标记id，和多个色块是一对多。
    setLabelId(state, data) {
      state.labelId = data;
    },
    //设置当前的绘画数组存储的矩形，当前的positons每一次画完才清空一次，true表示清空
    setCurrentOperatePosFlag(state, data) {
      state.currentOperatePosFlag = data;
      if (data) {
        state.currentOperatePos = [];
      }
    },
    // TODO:设置当前操作的位置positions的内容.如果是operate是true,那么是新增设置数组，如果是false,那么是清空数组，此时设置了视频
    setCurrentOperatePos(state, data) {
      state.currentOperatePos.push(data);
    },
    // 设置当前的范唱id
    setModelAttId(state, data) {
      state.modelAttId = data;
    },
    // 设置当前的伴唱id
    setAccompanimentAttId(state, data) {
      state.accompanimentAttId = data;
    },
    // 设置当前的曲谱的id对应的currentMusicId
    setCurrentMusicId(state, data) {
      state.currentMusicId = data;
    },
    // 设置当前的视频的汇总内容
    setFullRectVideos(state, data) {
      state.fullRectVideos.push(data);
    },

    // TODO:新建的时候，直接弹窗弹出
    setNewDialogRectFlag(state, data) {
      state.dialogRectFlag = data;
    },
    // 当前的rect的视频的弹窗,部分逻辑控制
    setDialogRectFlag(state, { dialogFlag, divId, fullActiveRect }) {
      // 设置此时的加入id之前有没有，如果有，那么直接展示视频列表，如果没有，弹出新的弹窗
      state.dialogRectFlag = dialogFlag;

      // 下面的全量数据更新的部分，待删除内容
      // TODO:待删除内容
      // TODO:待删除内容
      // TODO:设置全量的位置信息
      if (divId && fullActiveRect) {
        // TODO:设置当前的操作色块divId
        state.currentDivId = divId;
        state.ActivityDiv = fullActiveRect;
        // TODO:其实每一次能获取已经获取的历史色块，然后最新的ActiveyDiv和接口的色块进行差量比较，获取当新增的色块了。
        // 每一次设置顺序，重新调用一下色块接口
        // TODO:判断此时有没有这个divId，如果有,这里没用，因为新增完成色块，这里已经有了，
        // TODO:此时有色块了，在已经存储好的divId对应的内容下面，上传视频内容,
        // 如果不在，那么push新增一个
        // 如果在
        if (state.currentFullComModelSvgInfo?.labels.length > 0) {
          // TODO:判断此时是否存在这个divId
          let divIdFlag = false;
          let fullTempLength = state.currentFullComModelSvgInfo.labels.length;

          for (let i = 0; i < fullTempLength; i++) {
            if (state.currentFullComModelSvgInfo.labels[i]._divId == divId) {
              // TODO:直接展示弹窗，不用新增一个弹窗出来了，
              // TODO:带增加逻辑,已经存在了，那么就是直接展示弹窗，这里内容需要考虑处理
              // 当前的数据如何展示和原来展示内容的对应关系绑定
              divIdFlag = true;
              // TODO:判断一下，当前有没有state.currentFullComModelSvgInfo.labels[i].id
              // TODO:如果有state.currentFullComModelSvgInfo.labels[i].id，那么表示更新，那么上传的时候，
              // 更新的时候，这里只需要处理
              if (state.currentFullComModelSvgInfo.labels[i].id) {
                // TODO:存在labelId，也就是这个labelId是更新，
                // 那么此时的数据
                // 这里的内容找到这个labelId，然后更新这个labelId对应的全量的视频内容。
                // TODO:这里循环这个;setNewUploadVideos
                // TODO:之前上传成功后，暂存了当前上传的视频，那么僵上传的新的视频做一个对应labelId下的videoInfo新增操作
                // TODO:再次增加一个videoInfo内容，给当前的全量的fullLabels，同时这里的全量currentFullComModelSvgInfo
                // TODO:对应的内容也需要补充增加
                state.currentFullComModelSvgInfo.labels[i].videoInfos.push(
                  state.newUploadVideos
                );
                // TODO:同时找到fulllabels中对应的位置，更新fulllabels
                for (let i = 0; i < state.fullLables.length; i++) {
                  if (
                    state.fullLables[i].id ==
                    state.currentFullComModelSvgInfo.labels[i].id
                  ) {
                    // TODO:更新fullLabls中的视频
                    state.fullLables[i].videoInfos.push(state.newUploadVideos);
                  }
                }
                // TODO:新增当前的汇总视频
              } else {
                // TODO:如果没有，那么此时全量的视频上传内容，需要增加
                state.labelId = state.currentFullComModelSvgInfo.labels[i].id;
                // 如果存在，那么将不清空此时的state.fullRectVideos，但是需要找对应的
                // 重新赋值给整个state.fullRectVideos
                // TODO:在这个已经存在的state.currentFullComModelSvgInfo.labels
                // TODO:找到这个divId对饮的Video对应内容，重新给赋值一次

                // TODO:这里的展示双击弹窗后，展示当前的已经保存过的内容
                state.fullRectVideos =
                  state.currentFullComModelSvgInfo.labels[i].videoInfos;
              }
            }
          }
          // TODO:不存在这个divId,那么此时是新建，新建的时候，使用这个mergeCUrPos所有的位置信息，因为是数组结构，
          // TODO:如果是一个元素，那么单独一个JSON.stringify,如果是多个，那么JSON.stringfify
          if (!divIdFlag) {
            // 如果不存在，那么将整个清空
            // TODO:这里不太理解为什么清空

            // state.fullRectVideos = [];
            if (state.currentFullComModelSvgInfo?.labels) {
              state.currentFullComModelSvgInfo.labels.push({
                _divId: divId,
                // TODO:同时需要生成一个视频数组存放视频全量信息
                id: '', //TODO:labels的曲谱标记id内容
                videoInfos: [],
                positions: JSON.stringify(state.mergeCurPos),
              });
            }
            // TODO:同时告诉弹窗，需要新增一个弹窗，清空原来的残留数据
            state.currentDivDialogFlag = false;
          }
        } else {
          // 如果不存在，那么将整个清空
          // TODO:这里不太理解为什么清空
          // state.fullRectVideos = [];
          // 当此时的长度length=0那么此时肯定push
          if (state.currentFullComModelSvgInfo?.labels) {
            state.currentFullComModelSvgInfo.labels.push({
              _divId: divId,
              id: '', //TODO:labels的曲谱标记id内容
              videoInfos: [],
            });
          }
          // TODO:同时告诉弹窗，需要新增一个弹窗，清空原来的残留数据
          state.currentDivDialogFlag = false;
        }
      }
    },
    // 更新视频和顺序上传后的，已经存在的divId对应的全量数据绑定的切换
    // 同步设置当前的曲谱的全量信息

    // TODO:这里调用有点问题，暂时不进行调用这个函数
    setCurrentFullComModelSvgInfo(state, data) {
      // 此时有色块divId,找到divId下面对应的视频信息更新即可
      let tempLength = Object.values(
        state.currentFullComModelSvgInfo.labels
      ).length;
      // 这里是更新时候，是单个更新，那么此时使用divId，但是如果是新建，这里需要判断此时打开的是不是merge合并标记的操作，如果是
      for (let i = 0; i < tempLength; i++) {
        if (
          state.currentFullComModelSvgInfo.labels[i]._divId ==
          state.currentDivId
        ) {
          state.currentFullComModelSvgInfo.labels[i].videoInfos.push(data);
        }
      }
    },

    /**
     * 同步存储上传的曲谱
     */
    setMusicSvgSong(state, data) {
      state.musicSvgSong = data;
    },
  },
  actions: {
  },
  modules: {
    testModule,
    account,
    reports,
    coursewareDetailInfo,
    home,
  }
})
