<template>
  <div class="instruments-wrapper">
    <div class="category">
      <a v-for="(ins, index) in instruments" :key="ins.id + '_' + ins.lv"
        :class="index === actived[1] ? 'active' : ''"
        @click="handleSwitchCategory(ins, index)"
      >{{ ins.name }}</a>
    </div>
    <div class="container">
      <div class="sub-menu" v-if="instruments[0]">
        <a :class="index1 === actived[2] && 0 === actived[1] ? 'active' : ''" 
          v-for="(menu1, index1) in instruments[0].children" :key="menu1.id"
          @click="handleSwitchCategory(menu1, index1)"
        >{{ menu1.name }}</a>
      </div>
      <div class="instruments-list" ref="scrollWrapper">
        <div class="content">
          <template v-for="one in list" :key="one.id + '_' + one.name">
            <div class="item" @click="handleShowDetail(one)">
              <img :src="one.img" :alt="one.name" :title="one.name" />
            </div>
          </template>
          
        </div>
      </div>
      <div class="sub-menu" v-if="
        instruments[1] && instruments[1].children
      ">
        <a :class="index2 === actived[2] && 1 === actived[1] ? 'active' : ''" 
          v-for="(menu2, index2) in instruments[1].children" :key="menu2.id"
          @click="handleSwitchCategory(menu2, index2)"
        >{{ menu2.name }}</a>
      </div>
    </div>
    <a class="close-btn" @click="handleClose"></a>

    <div class="instrument-detail" v-if="currentInstrument">
      <div class="detail-container">
        <div class="title">{{ currentInstrument.name }}</div>
        <div class="image" >
          <img :src="currentInstrument.img" :alt="currentInstrument.name" :title="currentInstrument.name" />
        </div>
        <div class="audio-tools">
          <SimpleAudio :source="currentInstrument.audioUrl"/>
        </div>
      </div>
      
      <a class="close-btn" @click="handleCloseDetail"></a>
    </div>
  </div>
</template>
<script>
import BetterScroll from '@better-scroll/core';
import ScrollBar from '@better-scroll/scroll-bar';
import MouseWheel from '@better-scroll/mouse-wheel'

import SimpleAudio from './simpleAudio.vue';

BetterScroll.use(ScrollBar);
BetterScroll.use(MouseWheel);

export default {
  components: {SimpleAudio},
  emits: ['close'],

  data() {
    return {
      // 总的树形结构
      instruments: [],
      list: [],
      
      bs: null,
      // 记录当前选中的
      actived: [],
      showDetail: false,
      currentInstrument: null,
    }
  },

  created() {
    fetch('https://yydl.oss-cn-beijing.aliyuncs.com/instrument/ir.json', {
      method: 'get',
      mode: 'cors'
    }).then(res => {
      return res.json();
    }).then(res => {
      this.instruments = this.formatData(res);
      this.activeMenu(this.instruments[0], 0);
    });
  },

  mounted() {
    this.bs = new BetterScroll(this.$refs.scrollWrapper, {
      mouseWheel: true,
      scrollY: true,
      scrollbar: true,
      bounce: false,
      click: true,
    });
  },

  methods: {
    handleClose() {
      this.$emit('close');
    },

    formatData(data) {
      const res = [];

      function findParent(item) {
        for (let i=0; i<data.length; i++) {
          const one = data[i];
          if (item.parentId === one.id){
            one.children = one.children || [];
            one.children.push(item);
            break;
          }
        }
      }

      data.forEach(one => {
        if (one.lv === 1) {
          res.push(one);
        } else {
          findParent(one);
        }
      });
      return res;
    },

    getAllImage(category) {
      let res = [];
      if (!category.children && !category.images) {
        return [];
      }
      if (category.children) {
        category.children.forEach((one) => {
          res = res.concat(this.getAllImage(one));
        });
      }
      if (category.images) {
        res.push({img: category.images[0], id: category.id, name: category.name, audioUrl: category.audioUrl});
      }
      return res;
    }, 

    activeMenu(category, index) {
      this.actived[category.lv] = index;
      this.list = this.getAllImage(category);

      setTimeout(() => {
        this.bs.refresh();
      }, 500);
    },

    findMenu(list, id) {
      let res;
      for (let i = 0; i < list.length; i++) {
        const one = list[i];
        if (id === one.id) {
          res = {item: one, index: i};
        } else if (one.children) {
          res = this.findMenu(one.children, id);
        }
        if (res) {
          break;
        }
        continue;
      };
      return res;
    },
    
    handleSwitchCategory(category, index) {
      if(category.parentId) {
        const res = this.findMenu(this.instruments, category.parentId);
        if (res) {
          this.actived[category.lv - 1] = res.index;
        }
      }
      this.actived.splice(category.lv, this.actived.length - category.lv); 
      this.activeMenu(category, index);
    },

    handleCloseDetail() {
      this.currentInstrument = null;
    },

    handleShowDetail(ins) {
      this.currentInstrument = ins;
    },
  }
}
</script>
<style lang="scss">
.instruments-wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  background: #fff;
  height: 50%;
  display: flex;
  font-size: 18px;
  border: 1px solid #aaa;
  box-sizing: border-box;
  z-index: 9;
  .category {
    width: 60px;
    border-right: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 60px;
    letter-spacing: 8px;

    a {
      display: block;
      height: 50%;
      width: 100%;
      text-align: center;
      writing-mode: vertical-lr;
      border-bottom: 1px solid #ccc;
      cursor: pointer;

      &:last-child {
        border-bottom: none;
      }

      &:hover,
      &.active {
        background: #efefef;
      }
    }
  }

  .container {
    width: 100%;

    .sub-menu {
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      border-bottom: 1px solid #ccc;
      box-sizing: border-box;
      padding: 0 5px;
      a {
        padding: 0 15px;
        cursor: pointer;
        line-height: 40px;
        display: inline-block;
      }
      a.active {
        background: #efefef;
      }

      &:last-child{
        border-top: 1px solid #ccc;
        border-bottom: none;
      }
    }

    .instruments-list {
      height: calc(100% - 100px);
      padding: 10px;
      overflow: hidden;

      .content {
        width: 100%;
        min-height: 101%;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
      }

      .item {
        width: 16.66%;
        height: 200px;
        text-align: center;
        line-height: 1;
        flex-grow: 0;
        flex-shrink: 0;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }

      img {
        max-width: 85%;
        max-height: 85%;
      }
    }
  }

  .close-btn {
    position: absolute;
    right: 9px;
    bottom: 9px;
    background: url(../../../assets/img/close.png) no-repeat;
    background-size: contain;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .instrument-detail {
    position: fixed;
    background: #fff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .image {
      width: calc(100% - 300px);
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        min-height: 80%;
        max-width: 80%;
        max-height: 90%;
        vertical-align: middle;
      }
    }

    .audio-tools {
      .el-button.is-circle {
        font-size: 40px;
      }
    }
    .detail-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .title {
        width: 150px;
        line-height: 150px;
        font-size: 30px;
        writing-mode: vertical-lr;
        letter-spacing: 10px;
        text-align: center;
      }
    }
  }
}</style>