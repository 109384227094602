// {
    //   name: 'select',
    //   cname: '选择',
    //   isTool: true,
    //   needMove: true,
    //   icon: 'icon-xuanzegongju'
    // },
    // ,{
    //   name: 'redo',
    //   cname: '重做',
    //   isTool: false,
    //   icon: 'icon-zhongzuo'
    // }
export default [{
    name: 'pen',
    cname: '画笔',
    isTool: true,
    icon: 'icon-icon-test'
  },{
    name: 'line',
    cname: '直线',
    isTool: true,
    needMove: true,
    icon: 'icon-zhixian_huaban1'
  },{
    name: 'arrow',
    cname: '箭头',
    isTool: true,
    needMove: true,
    icon: 'icon-jiantou'
  },{
    name: 'square',
    cname: '方形',
    isTool: true,
    needMove: true,
    icon: 'icon-fangkuangweixuan'
  },{
    name: 'circle',
    cname: '圆形',
    isTool: true,
    needMove: true,
    selectable: false,
    icon: 'icon-yuanxingweixuanzhong'
  },{
    name: 'stave',
    cname: '五线谱',
    isTool: true,
    needMove: true,
    icon: 'icon-wuxianpu5'
  },{
    name: 'note',
    cname: '音符',
    isTool: true,
    needMove: false,
    icon: 'icon-yinfu'
  },{
    name: 'text',
    cname: '文字',
    isTool: true,
    selectable: true,
    icon: 'icon-danhangwenben'
  },{
    name: 'palette',
    cname: '颜色',
    isTool: false,
    icon: 'icon-paletteseban'
  },{
    name: 'dotSize',
    cname: '画笔大小',
    isTool: false,
    icon: 'icon-daxiao'
  },{
    name: 'eraser',
    cname: '橡皮擦',
    isTool: true,
    needMove: true,
    icon: 'icon-xiangpi'
  },{
    name: 'revoke',
    cname: '撤销',
    isTool: false,
    icon: 'icon-chexiao'
  },{
    name: 'delete',
    cname: '清空',
    isTool: false,
    icon: 'icon-shanchu'
  },{
    name: 'background',
    cname: '背景',
    isTool: false,
    icon: 'icon-zhuti'
  },{
    name: 'export',
    cname: '导出',
    isTool: false,
    icon: 'icon-export'
  },{
    name: 'close',
    cname: '关闭',
    isTool: false,
    icon: 'icon-guanbi'
  }]