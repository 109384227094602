<template>
  <div class="ear-training-wrapper">
    <CultureDialog @switchCulture="switchCulture">
      <template v-slot:header>
        <div class="title-name">
          <span>练耳</span>
        </div>
      </template>
      <template v-slot:content>
        <div class="content-header">
          <div class="question-type">
            <span class="title">题型:</span>
            <el-radio-group v-model="questionType" @change="createQuestion">
              <el-radio :value="0">单音听辩</el-radio>
              <el-radio :value="1">音程听辩</el-radio>
              <el-radio :value="2">和弦听辩</el-radio>
            </el-radio-group>
          </div>
          <div class="pitch-interval" v-if="questionType === 1">
            <span class="title">题型:</span>
            <el-select v-model="pitchInterval" size="small" @change="createQuestion">
              <el-option label="综合" :value="-1" />
              <el-option label="二度" :value="2" />
              <el-option label="三度" :value="3" />
              <el-option label="四度" :value="4" />
              <el-option label="五度" :value="5" />
              <el-option label="六度" :value="6" />
              <el-option label="七度" :value="7" />
            </el-select>
          </div>
          <div class="music-type">
            <span class="title">曲谱:</span>
            <el-radio-group v-model="musicType">
              <el-radio :value="0">五线谱</el-radio>
              <el-radio :value="1">简谱</el-radio>
            </el-radio-group>
          </div>
          <div class="standard-tone">
            <button @click="playStandVoice">
              <el-icon>
                <VideoPlay />
              </el-icon>
              <span>标准音</span>
            </button>
          </div>
        </div>
        <div class="content-wrapper">
          <div class="play-tone">
            <h5>听音频，选出正确的选项</h5>
            <div class="play-button" @click="handlePlayToneVoice">
              <el-icon>
                <VideoPlay />
              </el-icon>
            </div>
          </div>
          <div class="seletion-options">
            <div class="option-item" :class="item.selected ? (item.anwser ? 'right' : 'error') : ''"
              v-for="(item, index) in optionsDom" @click="handleSelectAnswer(item)" :key="(item.label + '_' + index)">
              <div class="label">{{ item.label }}: </div>
              <template v-if="(musicType === 0)">
                <div class="svg" v-html="item.svg"></div>
              </template>
              <template v-else>
                <div class="numbered">
                  <div class="single" v-for="(note, index) in item.notes" :key="note[0] + index">
                    <span>{{ note[1] }}</span>
                    <em :class="(note[2] > 0 ? 'up' : 'down')" v-if="(note[2] !== undefined)">
                      <i v-for="(a, index) in ([].length = Math.abs(note[2]))" :key="(a + '__' + index)"></i>
                    </em>
                  </div>
                  <div class="group" v-if="(item.notes.length > 1)">
                    <span v-for="(note, index) in [].concat(item.notes).reverse()" :key="note[0] + index">
                      {{ note[1] }}
                      <em :class="(note[2] > 0 ? 'up' : 'down')" v-if="(note[2] !== undefined)">
                        <i v-for="(a, index) in ([].length = Math.abs(note[2]))" :key="(a + '__' + index)"></i>
                      </em>
                    </span>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="answer-result" v-if="showAnswer">
            正确答案：{{ rightAnswer.text }}
          </div>
          <div class="reset" @click="createQuestion">
            <span>再来一题</span>
          </div>
        </div>
      </template>
    </CultureDialog>
  </div>
</template>

<script>
import CultureDialog from '../cultureDialog.vue';
import { ref, onMounted } from 'vue';
import { Vex } from 'vexflow'
import { initTone } from '../tone';
import * as Tone from 'tone';

const getchildrenByRandom = (arr, length) => {
  if (length >= arr.length) {
    return arr.map((one, index) => index);
  }
  const res = [];
  // eslint-disable-next-line no-constant-condition
  while (true) {
    var random = parseInt(Math.random() * arr.length);
    if (!res.includes(random)) {
      res.push(random);
    }
    if (res.length === length) {
      break;
    }
  }
  return res;
}

const disruptArray = (array) => {
  for (let i=0; i < array.length; i++) {
    const randomIndex = Math.round(Math.random() * (array.length - 1 - i)) + i;
    let temp = array[i];
    array[i] = array[randomIndex];
    array[randomIndex] = temp;
  }
  return array;
}

// 音符、简谱、幅点
const notes = [['C/4', 1], ['D/4', 2], ['E/4', 3], ['F/4', 4], ['G/4', 5], ['A/4', 6], ['B/4', 7], ['C/5', 1, 1]];

const makeMusicSvg = (notes, signature = '4/4') => {
  const div = document.createElement('div');
  div.style.display = 'none';
  document.body.appendChild(div);
  const a = Vex.Flow;
  const { Stave, StaveNote, Formatter, Renderer } = a;
  // Create a VexFlow renderer attached to the DIV element with id="output".
  const renderer = new Renderer(div, 2);

  // Configure the rendering context.
  renderer.resize(notes.length * 100, 130);
  const context = renderer.getContext();

  // Create a 4/4 treble stave and add two parallel voices.
  notes.forEach((note, index) => {
    const stave = new Stave(index * 80, 0, (index + 1) * 80);
    const voices = [
      new StaveNote({ keys: note[0], duration: note[1] }),
    ];

    if (index === 0) {
      // stave.addClef('treble').addTimeSignature(signature);
      stave.addClef('treble');
      stave.setBegBarType(7);
    }
    if (index !== notes.length - 1) {
      stave.setBegBarType(7);
    }
    // 不显示结束的小节线
    stave.setEndBarType(0);
    stave.setContext(context).draw();
    Formatter.FormatAndDraw(context, stave, voices);
  });
  document.body.removeChild(div);
  return div.innerHTML;
};

export default {
  name: "earTraining",
  components: { CultureDialog },

  setup(_, { emit }) {
    // 默认是否显示line
    const switchCulture = () => {
      emit('close');
      // 关闭音乐
      // soundAudio.removeAudio();
    }

    const optionsDom = ref([]);

    const musicType = ref(0);
    const questionType = ref(0);
    const pitchInterval = ref(2);
    const showAnswer = ref(false); // 答案
    const rightAnswer = ref({});

    const createQuestion = () => {
      showAnswer.value = false;
      rightAnswer.value = {};
      optionsDom.value.length = 0;
      // 单音
      if (questionType.value === 0) {
        // 生成七个音符的谱子
        const tempArr = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
        // 生成一个种子
        const seed = parseInt(Math.random() * 1000) % tempArr.length;
        const distuptedNotes = disruptArray(notes);
        tempArr.forEach((one, index) => {
          const note = distuptedNotes[index];
          // const noteStr = note[0] + "/w";
          optionsDom.value[index] = {
            svg: makeMusicSvg([[[note[0]], 'w']]),
            label: one,
            anwser: seed === index,
            notes: [note]
          };
        });
        rightAnswer.value = { index: seed, text: tempArr[seed] };
      } else if (questionType.value === 1) { // 音程
        const tempArr = ['A', 'B', 'C', 'D'];
        let arr;
        // 综合音程
        if (pitchInterval.value === -1) {
          const intervalArr = [2, 3, 4, 5, 6, 7];
          const obj = {};
          tempArr.forEach(() => {
            // eslint-disable-next-line no-constant-condition
            while(true) {
              let interval = parseInt(Math.random() * 6);
              if (!obj[interval]) {
                obj[interval] = {
                  interval: intervalArr[interval],
                  index: getchildrenByRandom(notes.slice(0, 9 - intervalArr[interval]), 1)[0] // 每个音程取一个
                }
                break;
              }
            }
          });
          arr = Object.values(obj);
        } else {
          arr = getchildrenByRandom(notes.slice(0, 9 - pitchInterval.value), tempArr.length);
          arr = arr.map(one => {
            return {
              interval: pitchInterval.value,
              index: one
            }
          });
        }
        const seed = parseInt(Math.random() * 1000) % arr.length;
        arr.forEach((one, index) => {
          const firstNote = notes[one.index][0];
          const lastNote = notes[one.index + one.interval - 1][0];

          optionsDom.value[index] = {
            svg: makeMusicSvg([[[firstNote], 'w'], [[lastNote], 'w'], [[firstNote, lastNote], 'w']]),
            label: tempArr[index],
            anwser: seed === index,
            notes: [notes[one.index], notes[one.index + one.interval - 1]]
          };
        });
        rightAnswer.value = { index: seed, text: tempArr[seed] };
      } else if (questionType.value === 2) {
        const tempArr = ['A', 'B', 'C', 'D'];
        const seed = parseInt(Math.random() * 1000) % tempArr.length;
        // 和弦
        const arr = [[0, 2, 4], [1, 3, 5], [2, 4, 6], [3, 5, 7]];
        arr.forEach((one, index) => {
          const note = [];
          one.forEach(a => {
            note.push(notes[a][0]);
          });
          optionsDom.value[index] = {
            svg: makeMusicSvg([[[note[0]], 'w'], [[note[1]], 'w'], [[note[2]], 'w'], [note, 'w']]),
            label: tempArr[index],
            anwser: seed === index,
            notes: one.map(a => notes[a])
          };
        });
        rightAnswer.value = { index: seed, text: tempArr[seed] };
      }
    }

    const handleSelectAnswer = (item) => {
      if (showAnswer.value) {
        return;
      }
      item.selected = true;
      showAnswer.value = true;
    }

    let piano;
    onMounted(() => {
      createQuestion();

      initTone().then((res) => {
        piano = res;
      });
    });
    // 标准音
    const playStandVoice = () => {
      if (!piano) return;
      const now = Tone.now();
      piano.triggerAttackRelease('A4', '2n', now + 1);
    }

    const handlePlayToneVoice = () => {
      console.log(optionsDom.value[rightAnswer.value.index].notes);
      const notes = optionsDom.value[rightAnswer.value.index].notes;

      const tempArr = [];
      const now = Tone.now();
      notes.forEach((note, index) => {
        const noteName = note[0].replace('/', '');
        piano.triggerAttackRelease(noteName, "2n", now + index);
        if (notes.length > 1) {
          tempArr.push(() => {
            piano.triggerAttackRelease(noteName, "2n", now + notes.length);
          });
        }
      });
      tempArr.forEach(func => {
        func();
      });
    }

    return {
      switchCulture,
      musicType,
      pitchInterval,
      questionType,
      createQuestion,
      optionsDom,
      handleSelectAnswer,
      showAnswer,
      rightAnswer,
      playStandVoice,
      handlePlayToneVoice
    }
  }
}
</script>

<style scoped>
.ear-training-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
}

.title-name {
  font-size: 24px;
  display: flex;
  align-items: center;
}

.content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.05rem 0.03rem;
  border-bottom: 2px solid rgb(221, 221, 221);
  margin: 0 0 .05rem;
}

.content-header>div {
  display: inline-block;
  display: flex;
  align-items: center;
  font-size: 0.069rem;
}

:deep(.content-header .el-radio__label) {
  font-size: 0.06rem;
}

:deep(.content-header .el-radio) {
  margin-right: .05rem;
}

.pitch-interval {
  width: 140px;
}

.content-header .title {
  margin: 0 0.06rem 0 0;
  word-break: keep-all;
}

.standard-tone {}

.standard-tone button {
  font-size: .069rem;
  padding: 0.05rem;
  display: flex;
  align-items: center;
  border-radius: .03rem;
  border: none;
  cursor: pointer;
  background: #3994f7;
  color: #fff;
}

.standard-tone button:hover {
  box-shadow: 0 0 8px rgb(171, 171, 171);
}

.standard-tone button .el-icon {
  margin: 0 .015rem 0 0;
}

.standard-tone button span {
  line-height: 1;
  word-break: keep-all;
}

.content-wrapper {
  position: relative;
  height: calc(100% - 0.3rem);
}

.reset {
  position: absolute;
  bottom: 0.1rem;
  right: 0.1rem;

  width: 0.63654rem;
  height: 0.188605rem;
  background: #3994f7;
  color: #fff;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  line-height: 50px;
  margin: 30px 20px 0 0;
  cursor: pointer;
}

.reset:hover {
  box-shadow: 0 0 8px rgb(166, 166, 166);
}

.seletion-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0 .1rem
}

.option-item {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 25%;
  max-width: 50%;
  padding: 0 .01rem;
  border-radius: .04rem;
  box-sizing: border-box;
}

.option-item.right,
.option-item.right:hover {
  background: rgba(157, 255, 157, 0.5);
}

.option-item.error,
.option-item.error:hover {
  background: rgba(255, 0, 0, .5);
  ;
}

.option-item:hover {
  background: #fafafa;
}

.option-item .label {
  margin: 0 .1rem 0 0;
  font-size: 0.07rem;
}

.option-item .svg {
  /* min-width: 33%; */
  /* width: 300px;
  overflow: hidden; */
}

.option-item .numbered {
  display: flex;
  font-size: 0.09rem;
  align-items: center;
  padding: .2rem 0;
}

.option-item .numbered>div {
  width: 0.12rem;
}

.option-item .numbered .single {
  position: relative;
  display: flex;
  justify-content: center;
  /* padding: 0.1rem 0 0; */
}

.option-item .numbered .group {
  margin: 0 0 0 .1rem;
}

.option-item .numbered .group span {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  margin: .06rem 0;
}

.option-item .numbered em {
  position: absolute;
}

.option-item .numbered em.up {
  bottom: .09rem;
}

.option-item .numbered em.down {
  top: .09rem;
}

.option-item .numbered em i {
  display: block;
  width: 0.01rem;
  height: 0.01rem;
  background: #000;
  margin: 0.01rem 0 0;
}

.answer-result {
  padding: .10rem;
  width: 60%;
  background: rgba(255, 151, 53, 0.2);
  border-radius: .05rem;
  margin: 0 .2rem;
  font-size: .07rem;
}

.play-tone {
  padding: .08rem 0 0.03rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.play-tone h5 {
  font-size: .07rem;
  line-height: 1;
  margin: 0 0 .08rem;
}

.play-button {
  width: .25rem;
  height: .25rem;
}

:deep(.play-button .el-icon),
:deep(.play-button .el-icon svg) {
  width: 100%;
  height: 100%;
  color: #d90000;
}
</style>