import axios from 'axios'
import Qs from 'qs'
import Cookies from 'js-cookie'
import lget from 'lodash.get'
import { getLocalStorage,removeItem } from '@/utils/storage.js'
import { ElMessage } from 'element-plus'
import router from '@/router'
let instance = axios.create({
  timeout: 6000000,
  withCredentials: true,
})
// 添加请求拦截器
instance.interceptors.request.use(
  (config) => {
    if (window.systemToken != undefined) {
      config.headers.Authorization = decodeURIComponent(
        window.systemToken || Cookies.get('systemToken') || ''
      )
    } else {
      config.headers.Authorization = decodeURIComponent(
        getLocalStorage('teacher_Token') || ''
      )
    }

    config.headers['client_id'] = 'teacher'
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)
instance.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return response.data
    } else {
      ElMessage({
        message: '接口请求失败！',
        type: 'error',
      })
      return Promise.reject({
        code: -1,
        msg: '接口请求失败!',
      })
    }
  },
  (error) => {

    if (error.response && error.response.status === 401) {

      const redirect = encodeURIComponent(window.location.href)

      router.push(`/login?redirect=${redirect}`)

      // 清除token

      removeItem('teacher_Token')

      // 代码不要往后执行了

      return Promise.reject(error)

    }

    // const msg = lget(error, 'response.data.msg', '请求服务失败！')

    // const status = lget(error, 'response.status')

    if (error.response.status !== 200) {

      return Promise.resolve(JSON.parse(error.request.response))

    }



  }
)
export const request = {
  get(url, params) {
    return new Promise((resolve, reject) => {
      instance({
        method: 'get',
        url,
        params,
      })
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => {
          return reject(error)
        })
    })
  },
  post(url, params, headerConfig) {
    return new Promise((resolve, reject) => {
      const data = JSON.stringify(params)
      const config = {
        method: 'post',
        url,
        // data: JSON.stringify(params)
        data,
        headers: {
          'Content-Type': 'application/json',
          // 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
        },
      }
      if (headerConfig) {
        config.headers = headerConfig
      }
      instance(config)
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => {
          return reject(error)
        })
    })
  },
  put(url, params) {
    return new Promise((resolve, reject) => {
      instance({
        method: 'put',
        url,
        data: params,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => {
          return reject(error)
        })
    })
  },
  delete(url) {
    return new Promise((resolve, reject) => {
      instance({
        method: 'delete',
        url,
      })
        .then((response) => {
          return resolve(response)
        })
        .catch((error) => {
          return reject(error)
        })
    })
  },

    /**
   *
   * @param {传入url参数} url
   * @param {formData的传参} formData
   * @returns
   */
    postFile(url, formData) {
      return new Promise((resolve, reject) => {
        const config = {
          method: 'post',
          url,
          data: formData,
          headers: {
            'content-Type': 'multipart/form-data',
          },
        };
        // if (headerConfig) {
        //   config.headers = headerConfig;
        // }
        instance(config)
          .then((response) => {
            return resolve(response);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
}
export default instance
