// import { gettTestData } from '@/api/'
export default {
  namespaced: true,
  state: {
    testData: 666,
  },
  mutations: {
    settTestData(state, payload) {
      state.testData = payload
    }
  },
  actions: {
    async gettTestData({commit}, params) {
      // const { code, data } = await gettTestData()
      commit('settTestData', {
        test: 22223333
      })
    },
  },
}
